import React, { useState, useRef, useEffect } from "react";
import useLogout from '../hooks/useLogout';
import useAuth from "../hooks/useAuth";
import UserService from '../services/user.service';
import { Menu, MenuList, MenuItem, IconButton, ListItemText, ListItemIcon } from '@mui/material';
import { Link, useLocation, useNavigate } from "react-router-dom";

const MenuHeader = () => {
  const location = useLocation();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout();
  const { auth } = useAuth();
  const [user, setUser] = useState();
  const timeoutRef = useRef(null);

  const signOut = async () => {
    await logout();
    navigate('/login');
  }


  const getMenuItem = () => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');
    switch (location.pathname) {
      case '/':
        return 'Dashboard';
      // case '/tasks':
      //   return 'Bookings';
      case '/customer':
        return 'Customers';
      case '/flypro':
        return 'flypro';
      case '/calendar':
        return 'Calendar Management';
      case '/managers':
        return 'Managers';
      case '/products':
        return 'Domain & Products';
      case '/manager-products':
        return 'Products';
      case '/hotels':
        return 'Hotel Mgnt';
      case '/notification':
        return 'Notification';
      case '/promotion':
        return 'Promotion';
      case '/gift':
        return 'Gift';  
      case '/statistic':
        return 'Statistic';
      case '/Management':
        return 'Setting';
      case '/management?tab=unavailable-zones':
        return 'Unavailable Zones';
      case '/management?tab=zone-management':
        return 'Zone Management';
      case '/management':
        // Check if "tab=zone-management" is present in the query string
        if (tab === 'zone-management') {
          return 'Zone Management';
        }
        if (tab === 'unavailable-zones') {
          return 'Unavailable Zones';
        }
        return 'Profile Setting';
      default:
        return 'Home';
    }
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      isMounted && setUser(auth.user);
    };

    if (!auth || Object.keys(auth).length === 0) {
      return;
    }
    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleMenuClick = () => {
    console.log()
    setIsSubMenuOpen((prev) => !prev);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsSubMenuOpen(false);
    }, 250);
  };
  const unreadCount = user?.notifications?.filter(
    (notification) => notification.is_read !== true
  ).length || 0;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="menu-header">
      <div className="container">
        <div className="menu logo-container">
          <div className="menu-right">
            <ul>
              <li
                className="parent"
                onMouseLeave={handleMouseLeave}
              >
                <IconButton onClick={handleClick}>
                  <img src='assets/images/icon-burger.svg' alt='burger' width={16} height={20} />
                </IconButton>
                {auth?.roles?.some(role => role.name === 'ROLE_ADMIN') && (
                  <>
                    <Menu anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuList
                        style={{ minWidth: 200 }}
                      >
                        <Link to="/" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Dashboard</ListItemText>
                          </MenuItem>
                        </Link>
                        {/* <Link to="/tasks" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Booking</ListItemText>
                          </MenuItem>
                        </Link> */}
                        <Link to="/customer" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Customers</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/flypro" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>flypro</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/calendar" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Calendar Mgnt</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/managers" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Managers</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/products" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Domain & Products</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/hotels" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Hotel Mgnt</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/notification" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Notification</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/promotion" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Promotion</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/gift" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Gift</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/management?tab=unavailable-zones" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Unavailable Zones</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/management?tab=zone-management" style={{ color: '#393D44' }}><MenuItem onClick={handleClose}>
                          <ListItemText>Zone Management</ListItemText>
                        </MenuItem>
                        </Link>
                        <Link to="#" style={{ color: '#393D44' }}><MenuItem onClick={handleClose}>
                          <ListItemText>Statistic</ListItemText>
                        </MenuItem>
                        </Link>
                        <Link to="/Management" style={{ color: '#393D44' }}><MenuItem onClick={handleClose}>
                          <ListItemText>Setting</ListItemText>
                        </MenuItem>
                        </Link>
                        <MenuItem onClick={signOut}>
                          <ListItemText>Logout</ListItemText>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </>
                )}

                {auth?.roles?.some(role => role.name === 'ROLE_HOTEL_MANAGER') && (
                  <>
                    <Menu anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuList
                        style={{ minWidth: 200 }}
                      >
                        <Link to="/tasks" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Booking</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/manager-products" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Products</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/management?tab=profile" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>My profile</ListItemText>
                          </MenuItem>
                        </Link>
                        <Link to="/management?tab=hotel-availability" style={{ color: '#393D44' }}>
                          <MenuItem onClick={handleClose}>
                            <ListItemText>Hotel Availability</ListItemText>
                          </MenuItem>
                        </Link>
                        <MenuItem onClick={signOut}>
                          <ListItemText>Logout</ListItemText>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </>
                )}
              </li>
            </ul>
          </div>
          {auth?.roles?.some(role => role.name === 'ROLE_ADMIN') && (
            <a href="/" className="logo">Flyspa</a>
          )}
          {auth?.roles?.some(role => role.name === 'ROLE_HOTEL_MANAGER') && (
            <a href="/tasks" className="logo">Flyspa</a>
          )}
        </div>
        <div className="menu">
          <div className="menu-left">
            <ul>
              <li><a href="#">{getMenuItem()}</a></li>
            </ul>
          </div>
          <div className="menu-right">
            <ul>
              <li><a href="/notification-list" className="notif"><span>{unreadCount}</span></a></li>
              <li><a href="/management" className="account">{user?.extra?.initials}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuHeader;
