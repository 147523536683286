import React, { useEffect, useState, useRef } from "react";
import MenuHeader from "../MenuHeader";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Modal from "react-modal";
import Typography from "@mui/material/Typography";
import imageFile from "../../assets/images/upload-file.svg";
import { asTitle } from "../../shared/utils/string";
import { styled } from "@mui/material/styles";
import useAxiosMultiPart from "../../hooks/useAxiosMultiPart";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import ProductService from "../../services/product.service";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    boxshadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    overflowX: "hidden",
    scrollbarWidth: "none",
    height: "680px",
  },
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#8fa9b9",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const Product = () => {
  Modal.setAppElement("#root");

  const { auth } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [hoveredRow, setHoveredRow] = useState(null);
  const timeoutRef = useRef(null);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);

  const [filterData, setFilterData] = useState({
    search: "",
    domain: "",
    category: "",
    duration: "",
    is_active: "",
  });

  const [productFilters, setProductFilters] = useState({});
  const [formData, setFormData] = useState({
    id: "",
    product_id: "",
    name: "",
    title: "",
    duration: "",
    type: "product",
    is_active: 1,
    price: "",
    description: "",
    subproduct: "",
    hotel_id: auth?.user?.hotel?.[0]?.id || "",
  });

  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState(true);
  const axiosMultiPart = useAxiosMultiPart();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    fetchData(productFilters);
    if (auth?.user?.hotel?.length) {
      setFormData((prev) => ({
        ...prev,
        hotel_id: auth.user.hotel[0].id,
      }));
    }
  }, [currentPage, perPage]);

  const fetchData = async (filters = {}) => {
    console.log(auth.user)
    try {
      const updatedFilters = {
        ...filters,
        page: currentPage,
        per_page: perPage,
        with: "media,category.parent,domain,sub_products,parent,hotel",
        hotel_id: auth.user.hotel[0].id
      };



      const productResponse = await ProductService.index(updatedFilters, axiosPrivate);
      setProducts(productResponse.data);
      setTotal(productResponse.total);
      setLastPage(productResponse.last_page);

      if (productResponse.last_page < currentPage) {
        setCurrentPage(productResponse.last_page);
      }
    } catch (error) {

    }
  };

  const handleSwitchChange = (index, row) => async (event) => {
    try {
      await ProductService.update(row.id, { is_active: !row.is_active }, axiosPrivate);
      fetchData(productFilters);
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = async (productData) => {
    if (productData) {
      setFormData((prev) => ({
        ...prev,
        id: productData.id,
        name: productData.name,
        description: productData.description,
        price: productData.price,
        duration: productData.duration,
      }));

    } else {
      resetForm();
    }
    setIsOpen(true);
  };


  const closeModal = () => {
    resetForm();
    setIsOpen(false);
  };

  const resetForm = () => {
    setFormData({
      id: "",
      product_id: "",
      name: "",
      title: "",
      duration: "",
      type: "product",
      is_active: 1,
      price: "",
      description: "",
      hotel_id: auth?.user?.hotel?.[0]?.id || "",
    });
    setFileName("");
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChange = async (e) => {
    const { name, value, files } = e.target;

    if (name === "file") {
      const fileSelected = files[0];
      if (fileSelected) {
        setFileName(fileSelected.name);
        setFile(fileSelected);
      }
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

  };

  const handleFormSwitchChange = () => {
    setFormData((prev) => ({
      ...prev,
      is_active: !prev.is_active,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Build FormData for multi-part (file uploads)
    const formDataToSend = new FormData();

    for (const key in formData) {
      const value = formData[key];
      if (value) {
        if (Array.isArray(value) || typeof value === 'object') {
          formDataToSend.append(key, JSON.stringify(value));
        } else if (typeof value === 'boolean') {
          formDataToSend.append(key, Number(value)); // Convert boolean to string
        } else {
          formDataToSend.append(key, value);
        }
      }
    }
    if (file) {
      formDataToSend.append("imageFile", file);
    }

    formData.hotel_id = auth?.user?.hotel?.[0]?.id;

    try {
      if (formData.id) {
        file ? ProductService.updateWithImage(formData.id, formDataToSend, axiosMultiPart) :
          ProductService.update(formData.id, formData, axiosPrivate);
        fetchData(productFilters);
      } else {
        await ProductService.store(formDataToSend, axiosMultiPart);
      }
      closeModal();
      fetchData(productFilters);
    } catch (error) {

    }
    fetchData(productFilters);
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    const newProductFilters = {
      name: filterData.search.trim(),
      is_active: filterData.is_active,
      duration: filterData.duration,
    };
    setProductFilters(newProductFilters);
    setCurrentPage(1);
    fetchData(newProductFilters);
  };

  const handleFilterChange = async (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // If clearing the search => reload immediately
    if (name === "search" && value.trim() === "") {
      const newProductFilters = {
        name: "",
        is_active: filterData.is_active,
        duration: filterData.duration,
      };
      setProductFilters(newProductFilters);
      setCurrentPage(1);
      fetchData(newProductFilters);
    }

    // If changing is_active
    if (name === "is_active") {
      const newProductFilters = {
        ...productFilters,
        is_active: value,
        name: filterData.search.trim(),
        duration: filterData.duration,
      };
      setProductFilters(newProductFilters);
      setCurrentPage(1);
      fetchData(newProductFilters);
    }
  };

  const handleSelectedSort = async (event) => {
    const selected = document.querySelector(".sort-header.active");
    let direction = true;
    if (selected?.id !== event.target.id) {
      selected?.classList.remove("active");
      event.target.classList.add("active");
      setSortDirection(true);
    } else {
      setSortDirection(!sortDirection);
      direction = !sortDirection;
    }

    const localSortField = direction
      ? event.target.id
      : `-${event.target.id}`;

    setSortField(localSortField);
    const updatedFilters = { ...productFilters, sort: localSortField, page: 1 };
    setProductFilters(updatedFilters);
    await fetchData(updatedFilters);
  };

  const handleEditMouseEnter = (index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setHoveredRow(index);
  };
  const handleEditMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredRow(null);
    }, 250);
  };

  const handleNextPage = () => {
    if (currentPage < lastPage) setCurrentPage((p) => p + 1);
  };
  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((p) => p - 1);
  };
  const handleLastPage = () => {
    if (currentPage !== lastPage) setCurrentPage(lastPage);
  };
  const handleFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };
  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <>
      <MenuHeader />


      <div className="bloc-search no-background">
        <div className="container">
          <form onSubmit={handleFilterSubmit} className="search">
            <div className="text-search">
              <input
                type="text"
                name="search"
                value={filterData.search}
                onChange={handleFilterChange}
                placeholder="Search by Name"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <select onChange={handleFilterChange} name="duration" value={filterData.duration}>
              <option value="">Duration</option>
              <option value="30">30 min</option>
              <option value="60">60 min</option>
              <option value="90">90 min</option>
            </select>
            <select onChange={handleFilterChange} name="is_active" value={filterData.is_active}>
              <option value="">Status</option>
              <option value="1">Active</option>
              <option value="false">Disabled</option>
            </select>
          </form>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead className="no-background">
              <tr>
                <th
                  id="name"
                  onClick={handleSelectedSort}
                  className="sort-header"
                >
                  Product Name
                </th>
                <th>Image</th>
                <th
                  id="duration"
                  onClick={handleSelectedSort}
                  className="sort-header"
                >
                  Duration (min)
                </th>
                <th
                  id="price"
                  onClick={handleSelectedSort}
                  className="sort-header"
                >
                  Price (CHF)
                </th>
                <th
                  id="is_active"
                  onClick={handleSelectedSort}
                  className="sort-header"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {products?.map((row, index) => (
                <tr
                  key={row.id}
                  className={
                    !row?.is_active
                      ? "disabled-item"
                      : ""
                  }
                >
                  <td>{row.name}</td>
                  <td>
                    {row.media?.[0] && (
                      <img
                        src={row.media[0]?.original_url}
                        alt="Product Logo"
                        style={{ width: "50px", height: "50px" }}
                      />
                    )}
                  </td>
                  <td>{row.duration}</td>
                  <td>{row.price}</td>
                  <td className={row.is_active ? "active" : "disabled"}>
                    {row.is_active ? "Active" : "Disabled"}
                  </td>
                  <td></td>
                </tr>
              ))}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={10}>
                  <div className="paginator">
                    <select
                      value={perPage}
                      onChange={handlePerPageChange}
                      className="per-page"
                    >
                      <option value={1}>1</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={250}>250</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                    </select>
                    <div className="total">Total Records: {total}</div>
                    <div className="pagination">
                      <button
                        type="button"
                        disabled={currentPage === 1}
                        onClick={handleFirstPage}
                      >
                        {"<<"}
                      </button>
                      <button
                        type="button"
                        disabled={currentPage === 1}
                        onClick={handlePrevPage}
                      >
                        {"<"}
                      </button>
                      <span>
                        {currentPage} / {lastPage}
                      </span>
                      <button
                        type="button"
                        disabled={currentPage === lastPage}
                        onClick={handleNextPage}
                      >
                        {">"}
                      </button>
                      <button
                        type="button"
                        disabled={currentPage === lastPage}
                        onClick={handleLastPage}
                      >
                        {">>"}
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Manage Product Modal"
      >
        <button className="close-button" onClick={closeModal}>
          <span className="close-icon">✖</span>
        </button>

        <h2>
          {formData.id ? "Update" : "Add"} a {asTitle(formData.type)}
        </h2>
        <p>If you need more info, please check out</p>

        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-text">
              <label>Product Name*</label>
              <input
                required
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>

            <div className="form-text topMargArea">
              <label>Description*</label>
              <textarea
                required
                className="formTextArea"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </div>

            <div className="form-text">
              <label>Duration *</label>
              <input
                required
                type="number"
                id="duration"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                min="0"
                max="240"
                placeholder="Enter duration (in minutes)"
                step="1"
              />
            </div>

            <div className="form-text">
              <label>Price*</label>
              <input
                required
                type="number"
                name="price"
                min="0"
                max="9999"
                step="0.01"
                value={formData.price}
                onChange={handleChange}
                placeholder="Enter price (CHF)"
              />
            </div>

            <label className="fileLabel" htmlFor="file">
              Image to upload*
            </label>
            <div className="fileField">
              <label htmlFor="file">
                <img className="imgFile" src={imageFile} alt="File upload" />
                {fileName ? (
                  <div>Uploaded File: {fileName}</div>
                ) : (
                  <>
                    <div className="selectFileTo">Select file to upload</div>
                    <div className="orDragAnd">
                      or drag and drop, copy-paste files
                    </div>
                  </>
                )}
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>

            <div className="capability-input">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>Status</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Disabled</Typography>
                  <Switch
                    checked={!!formData.is_active}
                    onChange={handleFormSwitchChange}
                    inputProps={{ "aria-label": "status" }}
                  />
                  <Typography>Active</Typography>
                </Stack>
              </Stack>
            </div>

            <div className="actions">
              <button type="button" onClick={closeModal} className="btn-submit">
                Cancel
              </button>
              <button type="submit" className="btn-submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Product;
