import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { format, addDays } from 'date-fns';
import image from "../assets/images/profil-picto.png";
import arrowlineleft from "../assets/icons/ArrowLineLeft-s.svg";
import ArrowLineRight from "../assets/icons/ArrowLineRight-s.svg";
import MenuHeader from "./MenuHeader";
import Modal from "react-modal";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { endpoints } from '../utils/utils';
import useAxiosMultiPart from '../hooks/useAxiosMultiPart';
import CalendarManagementService from '../services/calendar_management.service';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Select from 'react-select';
import UserService from '../services/user.service';
import { useNotifications } from '../context/NotificationProvider';
import useAuth from "../hooks/useAuth";
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { CiClock2 } from "react-icons/ci";
import { asTitle } from '../shared/utils/string';
import { Menu, MenuList, MenuItem, IconButton, ListItemText, ListItemIcon, Autocomplete, TextField, Divider, Button, Typography, Box, Icon, Chip } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ZoneService from '../services/zone.service';
import { Close, Folder, CloudUploadRounded } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import CustomTimeInputField from './CustomTimeInputFields';
import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core';

const theme = createTheme({
  palette: {
    dark: {
      "main": "#393D44",
      "light": "#676C73",
      "dark": "#2A2E34",
      "contrastText": "#FAEF9B"
    },
    primary: {
      "main": "#393D44"
    },
    secondary: {
      "main": "rgba(0, 0, 0, 0.3)"
    }
  },
});
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const timerStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    overflowX: 'hidden',
    maxWidth: '350px',
    padding: '0px 0px 0px 0px',
  },
};

const Container = styled.div`
  display: flex;
  height: calc(100vh - 162px );
  font-family: Arial, sans-serif;
  margin-left: 30px;
`;

const Sidebar = styled.div`
    position: relative;
    width: 240px;
    min-width: 240px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const CalendarContainer = styled.div`
  flex: 1;
  padding: 0 20px;
  position: relative;
`;

const Header = styled.div`
    width: fit-content;
    margin-bottom: 12px;
    position: relative;

`;

const TimeSlots = styled.div`
  display: grid;
  grid-template-columns: repeat(900, 1fr);
  position: relative;

  width: 100%;
  background: rgba(0, 0, 0, 0.08);
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  background-color: #fff;
  height: 47px;
  padding: 0px 25px;
  white-space: nowrap;
`;

const TimeSlot = styled.div`
  background-color: ${({ $status }) => {
    if ($status === 'AVAILABLE') return '#96ca63';
    if ($status === 'OVER_TIME') return '#ADFF2F';
    if ($status === 'BOOKED') return '#FF0000';
    if ($status === 'BUFFER') return '#8A2BE2';
    if ($status === 'TRAVEL') return '#8A2BE2';
    if ($status === 'OFF') return '#000000';
    if ($status === 'HOLIDAY') return '#1E88E5';
    if ($status === 'BANK_HOLIDAY') return '#582900';
    if ($status === 'SICK_LEAVE') return '#808080';
    return 'transparent';
  }}; 
  margin-bottom: 5px;
  top: 25px !important;
  width: 100%;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  min-heigth:23px;
  padding: 12px 5px;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  color: #fff;
  height: 47px;
  font-family: Inter;
`;

const HourLabel = styled.div`
  width: 50px;
    position: relative;
    font-size: 11px;
    line-height: 16px;
    font-family: Inter;
    color: rgba(0, 0, 0, 0.4);
    text-align: left;
    display: inline-block;
    height: 17px; 
    top: 15px;
    left: 0px;
`;

const TimeText = styled.div`
  color: ${({ status }) => (status === 'OVER_TIME' ? 'black' : 'white')};
  font-size: 14px;
  overflow: hidden;
  margin-bottom: 5px;
`;

const DurationText = styled.div`
  width: 100%;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: 12px;
  color: ${({ status }) => (status === 'OVER_TIME' ? 'black' : 'white')};
  font-family: Inter;
  margin: 0;
  font-weight: 900;
  font-family: Lato;
`;

const CurrentTimeWrapper = styled.div`
  position: fixed;
  top: ${({ scrollposition }) => (scrollposition > 70 ? 163 : 230 - scrollposition)}px;
  left: calc(${({ $left }) => 100}% );
  height: 100vh;
  width: 10px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 11;
`;

const CurrentTimeIndicatorLine = styled.div`
  width: 2px;
  background-color: black;
  height: 100%;
`;

const TooltipCustom = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: -70px;
  background-color: black;
  color: white;
  padding: 5px 8px;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 12px;
  font-family: Inter, sans-serif;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  ${CurrentTimeWrapper}:hover & {
    display: block;
  }
`;
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#EEEEEE',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 249,
    fontSize: 12,
    border: '1px solid rgba(179, 190, 197, 1)',
    borderRadius: '#EEEEEE'
  },
}));
/* START Remove Comment if revert to Dots
const Dots = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
`;
END Remove Comment if revert to Dots */

const Legend = styled.div`
  width: 10px;
  height: 10px;
  border-radius: ${({ type }) => type === 'dot' ? '100%' : '0'};
  background-color: ${({ color }) => color};
`;


const legends = [
  { label: 'Normal Hours', type: 'square', color: '#96ca63' },
  { label: 'Extra Hours', type: 'square', color: '#ADFF2F' },
  { label: 'Booked', type: 'square', color: '#FF0000' },
  { label: 'Travel', type: 'square', color: '#8A2BE2' },
  { label: 'Day Off', type: 'square', color: '#000000' },
  { label: 'Holiday', type: 'square', color: '#1E88E5' },
  { label: 'Bank Holiday', type: 'square', color: '#582900' },
  { label: 'Sick Leave', type: 'square', color: '#808080' },
];


const statuses = new Map([
  ['AVAILABLE', 'Normal Hours'],
  ['OVER_TIME', 'Extra Hours'],
  ['BOOKED', 'Booked'],
  ['BUFFER', 'Travel'],
  ['TRAVEL', 'Travel'],
  ['OFF', 'Day Off'],
  ['HOLIDAY', 'Holiday'],
  ['BANK_HOLIDAY', 'Bank Holiday'],
  ['SICK_LEAVE', 'Sick Leave'],
  ['FREE_TIME', 'Free Time']
]);

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const generateHours = (startHour, endHour, interval) => {
  const hoursArray = [];
  for (let h = startHour; h <= endHour; h++) {
    for (let m = 0; m < 60; m += interval) {
      const formattedHour = `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}`;
      hoursArray.push(formattedHour);
    }
  }
  return hoursArray;
};

const getIndexOfHour = (startHour, targetHour, interval) => {
  const convertToMinutes = (timeString) => {
    const [hour, minute] = timeString.split(':').map(Number);
    return hour * 60 + minute;
  };

  const startTimeInMinutes = convertToMinutes(startHour);
  const targetTimeInMinutes = convertToMinutes(targetHour);

  const minutesDifference = targetTimeInMinutes - startTimeInMinutes;

  const index = minutesDifference / interval;

  return index;
};


const hours = generateHours(8, 22, 1);

const calculateDuration = (start, end) => {
  const parseTime = (timeStr) => {
    let [hour, minute] = timeStr.split(':').map(Number);
    return hour * 60 + minute;
  };

  const startTotalMinutes = parseTime(start);
  const endTotalMinutes = parseTime(end);
  const durationMinutes = endTotalMinutes - startTotalMinutes;
  const totalMinutes = durationMinutes >= 0 ? durationMinutes : (24 * 60) + durationMinutes;

  const durationHours = Math.floor(totalMinutes / 60);
  const durationRemMinutes = totalMinutes % 60;

  return `${durationHours}:${String(durationRemMinutes).padStart(2, '0')}`;
};

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [flypros, setFlypros] = useState([]);
  const [selectedFlyproOption, setSelectedFlyproOption] = useState([]);
  const [timePickerIsOpen, setTimePickerIsOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(new Date());

  const axiosPrivate = useAxiosPrivate();
  const axiosMultiPart = useAxiosMultiPart();

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const notify = useNotifications();
  const [formData, setFormData] = useState({
    users: [],
  });
  const { auth } = useAuth();
  const userRoles = auth?.roles;

  const isAdmin = userRoles?.some(role => role.name === "ROLE_ADMIN");

  const dateInputRef = useRef(null);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [minTime, setMinTime] = useState(new Date('1970-01-01T08:00:00'));
  const [maxTime, setMaxTime] = useState(new Date('1970-01-01T22:00:00'));
  const [therapists, setTherapists] = useState([]);
  const [zones, setZones] = useState([]);
  const [filteredZones, setFilteredZones] = useState([]);
  const [filteredTherapists, setFilteredTherapists] = useState([]);
  const [filters, setFilters] = useState({ therapists: [], types: [], zones: [], labels: [] });
  const customSelectStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '60px',
      padding: '0 15px',
      fontSize: '14px',
      lineHeight: '60px',
      color: '#393D44',
      border: '0',
      borderRadius: '20px',
      background: '#ffffff',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
  };
  const fileInputRef = useRef(null);

  const handleReset = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default behavior (to allow drop)
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setMessage("");
    setFile(null);
  };
  const handleImport = async () => {
    if (!file) {
      setMessage("Please select a file before importing.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axiosMultiPart.post(endpoints.importCalender, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setMessage(response.data.message || "File imported successfully.");
      updateCalendar();
    } catch (error) {
      setMessage(
        error.response?.data?.message || "An error occurred during import."
      );
    }
  };

  const handleDownload = async (e) => {
    try {
      e.preventDefault();
      const dates = generateDates();

      if (dates && dates.length) {
        if (formData.users && formData.users.length) {
          const response = await axiosPrivate.post(endpoints.getCalendarFile, {
            users: formData.users,
            dates: dates
          }, {
            responseType: "blob",
          });


          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "schedule_template.csv");
          document.body.appendChild(link);
          link.click();

          link.remove();
          window.URL.revokeObjectURL(url);
        }
      }
    } catch (error) {
    }
  };

  const fetchCalendarData = async (date) => {
    try {
      const therapist_ids = filteredTherapists.length ? filteredTherapists.map(therapist => therapist.id).join(',') : -1;
      const params = { date: `${new Date(date).toLocaleDateString('en-CA').split('T')[0]}`, with: 'therapist.media,therapist.zones,calendars_managements_slots.slot', therapist_id: `in:${therapist_ids}` };

      const response = await CalendarManagementService.index(params, axiosPrivate);
      return response;

    } catch (error) {
      console.error('Erreur lors de la récupération des données du calendrier :', error);
      throw error;
    }
  };


  const handleFilterChange = (value, type) => {
    let changes = filters;
    switch (type) {
      case 'therapists':
        changes = { ...filters, therapists: value };
        break;
      case 'zones':
        changes = { ...filters, zones: value };
        break;
      case 'types':
        changes = { ...filters, types: value };
        break;
      case 'labels':
        changes = { ...filters, labels: value };
        break;

    }
    setFilters(changes);
    if (!changes.therapists.length && !changes.types.length && !changes.zones.length && !changes.labels.length) {
      setFilteredTherapists(therapists);
      setFilteredZones(zones);
    }
  }
  const resetFilters = () => {
    setFilteredTherapists(therapists);
    setFilteredZones(zones);
    setFilters({ therapists: [], types: [], zones: [], labels: [] });
  }

  const canFilter = filters.therapists.length > 0
    || filters.types.length > 0
    || filters.zones.length > 0
    || filters.labels.length > 0;

  const applyFilters = () => {
    const filteredData = therapists.filter((therapist) => {
      // Filter by therapist IDs
      if (filters.therapists.length) {
        const therapistIds = new Set(filters.therapists.map(therapist => therapist.id));
        if (!therapistIds.has(therapist.id)) {
          return false;
        }
      }

      // Filter by zones
      if (filters.zones.length) {
        setFilteredZones(filters.zones);
        const therapistZoneIds = new Set(therapist.zones.map(zone => zone.id));
        if (!filters.zones.some(zone => therapistZoneIds.has(zone.id))) {
          return false;
        }
      }

      // Filter by types
      if (filters.types.length) {
        const typeSet = new Set(filters.types); // Optimization: use a Set for faster lookup
        if (!typeSet.has(therapist.agent_type)) {
          return false;
        }
      }

      // Filter by labels (ASCA and RME)
      if (filters.labels.length) {
        const labelSet = new Set(filters.labels); // Optimization: use a Set for faster lookup
        if ((labelSet.has('ASCA') && !therapist.is_asca) ||
          (labelSet.has('RME') && !therapist.is_rme)) {
          return false;
        }
      }

      return true;
    });
    setFilteredTherapists(filteredData);
    handleFilterMenuClose();
  }

  const initFilterData = async () => {
    try {
      const therapistResponse = await UserService.indexByRole({ columns: ['id', 'firstname', 'lastname', 'email', 'status', 'is_asca', 'is_rme', 'agent_type'], status: '!eq:BLOCKED', with: 'zones' }, 'ROLE_FLYPRO', axiosPrivate);
      setTherapists(therapistResponse);
      setFilteredTherapists(therapistResponse);
      const zoneResponse = await ZoneService.index({ columns: ['id', 'name', 'is_active'], is_active: 1 }, axiosPrivate);
      setZones(zoneResponse);
      setFilteredZones(zoneResponse);

    } catch (error) {
      console.error('Erreur lors de la récupération des données du calendrier :', error);
      throw error;
    }
  };


  const updateCalendar = async () => {
    try {
      const calendarData = await fetchCalendarData(currentDate);

      const groupedData = calendarData.reduce((acc, item) => {
        const therapistEmail = item.therapist.email;
        const therapistFirstName = item.therapist.firstname;
        const therapistLastName = item.therapist.lastname;
        const therapistCity = item.therapist.primary_address?.city;
        const therapistzip_code = item.therapist.primary_address?.zip_code;

        if (!acc[therapistEmail]) {
          acc[therapistEmail] = {
            ...item.therapist,
            slots: [],
          };
        }
        item.calendars_managements_slots.forEach(calendar_management_slot => {
          acc[therapistEmail].slots.push([
            calendar_management_slot.slot.start_time,
            calendar_management_slot.slot.end_time,
            calendar_management_slot.status,
          ]);
        });

        return acc;
      }, {});
      const therapistEmails = Object.keys(groupedData);


      const adjustAndSplitTimeSlots = (slots) => {
        const minHour = "08:00";
        const maxHour = "18:00";

        // Convert time string to Date object
        const parseTime = (time) => {
          const [hour, minute] = time.split(":").map(Number);
          return new Date(1970, 0, 1, hour, minute);
        };

        // Convert Date object to time string
        const formatTime = (date) => {
          return date.toTimeString().slice(0, 5);
        };

        // Sort slots by start time
        const sortedSlots = slots.sort((a, b) => parseTime(a[0]) - parseTime(b[0]));

        const result = [];
        let previousEndTime = parseTime(minHour);

        for (const [startTime, endTime, status] of sortedSlots) {
          const currentStartTime = parseTime(startTime);

          // Add a FREE_TIME slot if there's a gap
          if (currentStartTime > previousEndTime) {
            result.push([formatTime(previousEndTime), formatTime(currentStartTime), "FREE_TIME"]);
          }

          // Add the current slot
          result.push([startTime, endTime, status]);

          // Update the previous end time
          previousEndTime = parseTime(endTime);
        }

        // Add a final FREE_TIME slot if there's a gap to the maxHour
        if (previousEndTime < parseTime(maxHour)) {
          result.push([formatTime(previousEndTime), maxHour, "FREE_TIME"]);
        }
        return result;
      };


      Object.keys(groupedData).forEach(email => {
        groupedData[email].slots = adjustAndSplitTimeSlots(groupedData[email].slots);
      });

      const formattedData = Object.values(groupedData);
      setTimeSlots(formattedData);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du calendrier :', error);
    }
  };


  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(':');
    const hours12 = (hours % 12) || 12; // Convert 24-hour to 12-hour format
    const period = hours < 12 ? 'AM' : 'PM'; // Determine AM/PM
    return `${hours12}:${minutes} ${period}`;
  }

  useEffect(() => {
    const dateString = format(currentDate, 'yyyy-MM-dd');
    updateCalendar();
  }, [currentDate, filteredTherapists]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    setCurrentTime(new Date());

    return () => clearInterval(interval);
  }, []);

  const calculateCurrentTimeLeft = () => {
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentTotalMinutes = currentHours * 60 + currentMinutes;

    const startTime = 8 * 60;
    const endTime = 21 * 60 + 59;


    if (currentTotalMinutes < startTime || currentTotalMinutes > endTime) {
      return null;
    }

    const totalDisplayedMinutes = 900;
    const elapsedMinutes = currentTotalMinutes - startTime;
    const leftPercentage = (elapsedMinutes / totalDisplayedMinutes) * 100;
    return leftPercentage;
  };

  const currentTimeLeft = calculateCurrentTimeLeft();

  const changeDate = (days) => {
    setCurrentDate((prevDate) => addDays(prevDate, days));
  };

  const renderHourLabels = () => {
    return hours.map((hour, index) => {
      const isHourLabel = index % 60 === 0;
      if (index < 900) {
        if (isHourLabel) {
          const displayHour = `${hour}`;

          return (
            <HourLabel key={index}>{displayHour}</HourLabel>
          );
        } else {

          return (
            <div key={index} style={{ height: '40px', width: '0px' }}></div>
          );
        }
      }

    });
  };

  function convertTimeToMinutes(time) {
    const [hours, minutes] = time
      .match(/(\d+):(\d+)/)
      .slice(1)
      .map(Number);
    return (hours % 24) * 60 + minutes;
  }
  const formatTimeTo24Hour = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hour, minute] = time.split(':').map(Number);

    if (modifier === 'PM' && hour < 12) hour += 12;
    if (modifier === 'AM' && hour === 12) hour = 0;

    return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
  };
  const handleDatePicker = () => {
    dateInputRef.current.showPicker();
  }
  const renderTimeSlots = () => {
    return timeSlots.flatMap((therapist, tIndex) => {
      return therapist.slots.map(([start, end, status], slotIndex) => {
        const startIndex = hours.indexOf(start) > 0 ? hours.indexOf(start) : 0;
        const endIndex = hours.indexOf(end) ?? getIndexOfHour('08:00', end, 1);
        const gridStart = startIndex + 1;
        const gridEnd = endIndex + 1;
        /* START Remove Comment if revert to Dots 
        const dots = [];

        switch (status) {
          case 'FREE_TIME':
            for (let i = gridStart; i < gridEnd; i += 15) {
              dots.push(<Dot color='#8FA9B9'></Dot>)
            }
            break;
          case 'OFF':
            for (let i = gridStart; i < gridEnd; i += 15) {
              dots.push(<Dot color='#000000'></Dot>)
            }
            break;
          case 'HOLIDAY':
            for (let i = gridStart; i < gridEnd; i += 15) {
              dots.push(<Dot color='#1E88E5'></Dot>)
            }
            break;
          case 'BANK_HOLIDAY':
            for (let i = gridStart; i < gridEnd; i += 15) {
              dots.push(<Dot color='#582900'></Dot>)
            }
            break;
          case 'SICK_LEAVE':
            for (let i = gridStart; i < gridEnd; i += 15) {
              dots.push(<Dot color='#808080' key={i}></Dot>)
            }
            break;
          case 'TRAVEL':
            for (let i = gridStart; i < gridEnd; i += 15) {
              dots.push(<Dot color='#8A2BE2' key={i}></Dot>)
            }
            break;
        }
        END Remove Comment if revert to Dots */
        return (
          <Tooltip title={`${statuses.get(status) ?? ''} ${start} - ${end} (${calculateDuration(start, end)})`}>
            <TimeSlot
              key={`${tIndex}-${slotIndex}`}
              $status={status}
              style={{ gridColumn: `${gridStart} / ${gridEnd}` }}
            >
              <TimeText status={status}>
                {status !== 'FREE_TIME'
                  ? `${statuses.get(status) ?? ''} ${start} - ${end} (${calculateDuration(start, end)})` : ''}
              </TimeText>
            </TimeSlot>
          </Tooltip>
        );
      })
    }
    );
  };

  const closeExportModal = () => {
    setExportModalIsOpen(false);
  }

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  }

  const openExportModal = async () => {
    setSelectedFlyproOption([]);
    setFormData({ users: [] });
    setStartDate(new Date());
    setEndDate(new Date());
    const response = await UserService.indexByRole({ status: '!eq:BLOCKED' }, 'ROLE_FLYPRO', axiosPrivate);
    setFlypros(response);
    setExportModalIsOpen(true);
  }

  const openCreateModal = async () => {
    setSelectedFlyproOption([]);
    setFormData({ users: [] });
    setStartDate(new Date());
    setEndDate(new Date());
    setSlots([{ start_time: '08:00', end_time: '12:00', status: 'AVAILABLE' }])
    const response = await UserService.indexByRole({ status: '!eq:BLOCKED' }, 'ROLE_FLYPRO', axiosPrivate);
    setFlypros(response);
    setCreateModalIsOpen(true);
  }

  const handleCancelClick = () => {
    setTimePickerIsOpen(false);
  };

  const handleTimeChange = (newValue) => {
    setSelectedTime(newValue);
  };

  const handleOkClick = () => {
    const timeString = selectedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const newSlots = slots.map((slot, index) => index !== selectedSlot.index ? slot :
      {
        ...slot,
        start_time: selectedSlot.is_start ? formatTimeTo24Hour(timeString) : slot.start_time,
        end_time: selectedSlot.is_end ? formatTimeTo24Hour(timeString) : slot.end_time,
      });
    setSlots(newSlots)
    setTimePickerIsOpen(false);
  };

  const generateDates = () => {
    if (!startDate || !endDate || startDate > endDate) {
      notify.warn('Please select a valid date range');
      return;
    }

    const dates = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      // Exclude Sundays (0 = Sunday)
      // if (currentDate.getDay() !== 0) {
      dates.push(new Date(currentDate).toLocaleDateString('en-CA').split('T')[0]);
      // }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const isSundayOrPast = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to midnight for accurate comparison
    return date < today; //|| date.getDay() === 0 Disable past dates or Sundays
  };

  // Function to disable Sundays, dates in the past, and dates before the start date
  const isSundayOrBeforeStartDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    startDate?.setHours(0, 0, 0, 0);
    if (!startDate) return isSundayOrPast(date); // No start date selected
    return date < today || date < startDate; //|| date.getDay() === 0 Disable past, before start date, and Sundays
  };

  const flyproOptions = flypros?.length > 0 ? flypros.map(row => ({ value: row.email, label: row.extra?.full_name })) : [];
  const flyproIdOptions = flypros?.length > 0 ? flypros.map(row => ({ value: row.id, label: row.extra?.full_name })) : [];

  const handleFlyproChange = (selected) => {
    if (selected?.length) {
      setSelectedFlyproOption(selected);
      setFormData((prev) => ({
        ...prev,
        users: selected.map((therapist) => therapist.value)
      }));
    } else {
      setSelectedFlyproOption([]);

      setFormData((prev) => ({
        ...prev,
        users: []
      }));
    }
  };

  const handleCreateSlots = async (e) => {
    e.preventDefault();
    const dates = generateDates();
    const therapists = selectedFlyproOption.map(flypro => flypro.value)
    const response = await CalendarManagementService.createSlots({
      slots,
      therapists,
      dates
    }, axiosPrivate);
    updateCalendar();
    setCreateModalIsOpen(false)
  }

  const [scrollposition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const actionMenuOpen = Boolean(actionMenuAnchor);

  const handleActionMenuClick = (event) => {
    setActionMenuAnchor(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setActionMenuAnchor(null);
  };

  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null);
  const filterMenuOpen = Boolean(filterMenuAnchor);

  const handleFilterMenuClick = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    initFilterData();
  }, []);

  const [viewType, setViewType] = useState('list');

  const toggleViewType = (viewType) => {
    setViewType(viewType);
  }

  const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
      id: 'population',
      label: 'Population',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'size',
      label: 'Size\u00a0(km\u00b2)',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'density',
      label: 'Density',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
  ];

  const [currentDateForGrid, setCurrentDateForGrid] = useState(new Date());
  const [gridCalendars, setGridCalendars] = useState([]);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (viewType === 'grid') {
      const firstDay = new Date(
        currentDateForGrid.getFullYear(),
        currentDateForGrid.getMonth(),
        1
      );
      const lastDay = new Date(
        currentDateForGrid.getFullYear(),
        currentDateForGrid.getMonth() + 1,
        0
      );

      const dates = [];
      for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
        dates.push(new Date(day));
      }

      fetchGridData(dates);
    }
  }, [currentDateForGrid, viewType, filteredTherapists, filteredZones])

  const [gridEdit, setGridEdit] = useState(false);
  const [gridTherapists, setGridTherapists] = useState([]);

  const fetchGridData = async (dates) => {
    const formatter = new Intl.DateTimeFormat('en-CA', { timeZone: 'Europe/Zurich' })
    try {
      const therapist_ids = filteredTherapists.length ? filteredTherapists.map(therapist => therapist.id).join(',') : -1;
      const params = { date: `between:${formatter.format(new Date(dates[0]))},${formatter.format(new Date(dates[dates.length - 1]))}`, with: 'therapist.media,therapist.zones,calendars_managements_slots.slot', therapist_id: `in:${therapist_ids}` };

      const response = await CalendarManagementService.index(params, axiosPrivate);
      setGridCalendars(response);
      const therapists = filteredTherapists.map(therapist => {
        const calendar = [];
        response.filter(item => item.therapist_id == therapist.id).forEach(item => {
          const free = item.calendars_managements_slots.find((calendar_management_slot) => ['OFF', 'HOLIDAY', 'BANK_HOLIDAY', 'SICK_LEAVE'].includes(calendar_management_slot.status));
          if (free) {
            switch (free.status) {
              case 'OFF':
                calendar[formatter.format(new Date(item.date))] = { value: 'Day Off', availability: { start: '08:00', end: '18:00', changed: false } };
                break;
              default:
                calendar[formatter.format(new Date(item.date))] = { value: asTitle(free.status), availability: { start: '08:00', end: '18:00', changed: false } };
                break;
            }
          } else {
            const availability = item.calendars_managements_slots.reduce((acc, item) => {
              const slot = item.slot;

              if (!acc.end || strToTime(slot.end_time) > strToTime(acc.end)) {
                acc.end = slot.end_time;
              }

              if (!acc.start || strToTime(slot.start_time) < strToTime(acc.start)) {
                acc.start = slot.start_time;
              }

              return acc;
            }, { end: null, start: null });
            calendar[formatter.format(new Date(item.date))] = { availability, value: `${availability.start} - ${availability.end}`, changed: false };
          }
        });
        return { ...therapist, calendar };
      });
      setGridTherapists(therapists);
      const rows = new Map();
      dates.map((date) => {
        const current = formatter.format(new Date(date));
        rows.set(current, new Map());
        filteredZones.map(zone => {
          rows.get(current).set(zone.id, new Map());
          therapists.map(therapist => {
            if (therapist.zones.findIndex(z => z.id === zone.id) !== -1) {
              rows.get(current).get(zone.id).set(therapist.id, therapist.calendar[formatter.format(new Date(date))]);
            }
          });
        });
      });

      setRows(rows);
      return response;

    } catch (error) {
      console.error('Erreur lors de la récupération des données du calendrier :', error);
      throw error;
    }
  };

  const strToTime = (timeStr) => {
    const [hours, minutes] = timeStr.split(':');
    return new Date(0, 0, 0, hours, minutes);  // Use Date with dummy date to compare time only
  }

  const [draggedValue, setDraggedValue] = useState(null);

  const handleDragStart = (event) => {
    setDraggedValue(event.active.data.current);
  };

  const handleDragEnd = (event) => {
    if (event.over) {
      const target = event.over.id; // Target cell id
      // Here we would update the target cell with the dragged value (copying it)
      // You will need to adjust this based on how you manage your data
      // For example: updateTargetCell(target, draggedValue);
    }
    setDraggedValue(null); // Reset dragged value after drop
  };

  const DateRow = (date, zone) => {
    return (<>
      {
        [...zones].map(
          ([index, therapist]) => {
            return (
              <TableCell
                key={index}
                align={"left"}
                style={{ top: 57, minWidth: "40px" }}
              >
                {gridEdit === false ?
                  (
                    <Chip label={therapist?.value ?? 'N/A'} />
                  ) : (
                    <CustomTimeInputField onChange={(returnedTherapist, returnedIndex) => {
                      // const therapists = [...gridTherapists];
                      // const current = therapists.find(item => item.id === returnedTherapist.id);
                      // if (current && current.calendar[returnedIndex]?.value && current.calendar[returnedIndex]?.value !== returnedTherapist.calendar[returnedIndex]) { }
                    }} data={{ therapist, date }} ></CustomTimeInputField>)}
              </TableCell>
            )
          }
        )
      }
      <TableCell
        align={"left"}
        style={{ top: 57, minWidth: "40px", backgroundColor: '#96ca63' }}
      >
      </TableCell>
    </>)
  };
  return (
    <>
      <MenuHeader />
      {isAdmin && (
        <div className="bloc-calendar">
          <div className="container" >
            <ThemeProvider theme={theme}>
              <Box>
                <IconButton color={viewType === 'list' ? 'primary' : 'secondary'} id='list' onClick={() => toggleViewType('list')}>
                  <ViewListIcon />
                </IconButton>
                <IconButton color={viewType === 'grid' ? 'primary' : 'secondary'} id='grid' onClick={() => toggleViewType('grid')}>
                  <GridViewIcon />
                </IconButton>
              </Box>
            </ThemeProvider>

            {
              <div className='legend-list'>
                {legends.map(((legend, index) => (
                  <div className='legend' key={index}>
                    <Legend color={legend.color} type={legend.type}></Legend>
                    <span>{legend.label}</span>
                  </div>
                )))}
              </div>
            }
            <div className="search" />

            <div className='actions'>
              <IconButton onClick={handleActionMenuClick}>
                <img src='assets/images/icon-commandes.svg' alt='actions' />
              </IconButton>
              <Menu anchorEl={actionMenuAnchor}
                open={actionMenuOpen}
                onClose={handleActionMenuClose}
              >
                <MenuList
                  style={{ minWidth: 250 }}
                >
                  <MenuItem style={{ gap: 15 }} onClick={() => { handleActionMenuClose(); openExportModal(); }}>
                    <ListItemIcon>
                      <img src='assets/images/icon-excel.svg' alt='actions' />

                    </ListItemIcon>
                    <ListItemText>Download template</ListItemText>
                  </MenuItem>
                  <MenuItem style={{ gap: 15 }} onClick={() => { handleActionMenuClose(); openModal(); }}>
                    <ListItemIcon>
                      <img src='assets/images/icon-excel.svg' alt='actions' />
                    </ListItemIcon>
                    <ListItemText>Import</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => { openCreateModal(); }}>
                    <ListItemIcon>
                      <img src='assets/images/icon-commandes.svg' alt='actions' />
                    </ListItemIcon>
                    <ListItemText>Create</ListItemText>
                  </MenuItem>
                </MenuList>
              </Menu>
              <IconButton onClick={handleFilterMenuClick}>
                <img src='assets/images/filter.png' alt='actions' height={20} />
              </IconButton>
              <Menu anchorEl={filterMenuAnchor}
                open={filterMenuOpen}
                onClose={handleFilterMenuClose}
              >
                <MenuList
                  style={{ padding: 0 }}
                >
                  <MenuItem onClick={handleFilterMenuClose} disableRipple>
                    <ListItemIcon>
                      <img src='assets/images/filter.png' alt='actions' height={20} />

                    </ListItemIcon>
                    <ListItemText>Filters</ListItemText>
                  </MenuItem>
                  <Divider></Divider>
                  <MenuItem disableRipple>

                    <Autocomplete
                      multiple
                      id="therapists"
                      options={therapists}
                      getOptionLabel={(option) => option.extra.full_name}
                      onChange={($event, value) => handleFilterChange(value, 'therapists')}
                      value={filters.therapists}
                      renderInput={(params) => (
                        <TextField {...params} label="Filter Therapists" placeholder="Search" />
                      )}
                      sx={{ width: '500px' }}
                    />
                  </MenuItem>
                  <MenuItem disableRipple>

                    <Autocomplete
                      multiple
                      id="zones"
                      options={zones}
                      getOptionLabel={(option) => option.name}
                      onChange={($event, value) => handleFilterChange(value, 'zones')}
                      value={filters.zones}
                      renderInput={(params) => (
                        <TextField {...params} label="Filter Zones" placeholder="Search" />
                      )}
                      sx={{ width: '500px' }}
                    />
                  </MenuItem>

                  <MenuItem disableRipple unselectable='off'>

                    <Autocomplete
                      multiple
                      id="types"
                      options={['EMPLOYEE', 'FREELANCER', 'EMPLOYEE_EXTRA']}
                      getOptionLabel={(option) => asTitle(option)}
                      onChange={($event, value) => handleFilterChange(value, 'types')}
                      value={filters.types}
                      renderInput={(params) => (
                        <TextField {...params} label="Filter Types" placeholder="Search" />
                      )}
                      sx={{ width: '250px' }}
                    />
                    <Autocomplete
                      multiple
                      id="labels"
                      options={['ASCA', 'RME']}
                      getOptionLabel={(option) => option}
                      onChange={($event, value) => handleFilterChange(value, 'labels')}
                      value={filters.labels}
                      renderInput={(params) => (
                        <TextField {...params} label="Filter Quality Labels" placeholder="Search" />
                      )}
                      sx={{ width: '250px' }}
                    />
                  </MenuItem>
                  {(canFilter === true) && (<MenuItem disableRipple unselectable='off' style={{ gap: 10 }}>
                    <ThemeProvider theme={theme}>
                      <Button variant="contained" color='dark' onClick={applyFilters}>Apply</Button>
                      <Button variant="text" color='dark' onClick={resetFilters}>Reset Filters</Button>
                    </ThemeProvider>
                  </MenuItem>)}
                </MenuList>
              </Menu>
            </div>

          </div>
        </div>
      )}

      {viewType === 'list' && (
        <div id="calendar">

          <Container>
            <Sidebar>
              <Header>
                <div className="group-cal">
                  <div className="button-cal">
                    <div className="iconset-cal">
                      <img
                        onClick={() => changeDate(-1)}
                        className="arrowlineleft-s-icon-cal"
                        alt="Previous Day"
                        src={arrowlineleft}
                      />
                    </div>
                  </div>
                  <div className="text-cal">
                    <div className="text1-cal" onClick={handleDatePicker}>
                      {currentDate.toLocaleDateString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' })}
                    </div>
                    <input
                      ref={dateInputRef}
                      type="date"
                      name="current_date"
                      value={currentDate}
                      onChange={(e) => { setCurrentDate(new Date(e.target.value)) }}
                      hidden
                    />
                  </div>
                  <div className="button-cal">
                    <div className="iconset-cal">
                      <img
                        onClick={() => changeDate(1)}
                        className="arrowlineleft-s-icon-cal"
                        alt="Next Day"
                        src={ArrowLineRight}
                      />
                    </div>
                  </div>
                  <div className="button2-cal">
                    <div className="text-cal">
                      <div className="text1-cal" onClick={() => setCurrentDate(new Date())}>
                        Today
                      </div>
                    </div>
                  </div>
                </div>

              </Header>
              {timeSlots.map((therapist, index) => {

                const totalHours = therapist?.slots
                  ?.reduce((total, [start, end]) => {
                    const startMinutes = convertTimeToMinutes(start);
                    const endMinutes = convertTimeToMinutes(end);

                    const durationInHours = (endMinutes - startMinutes) / 60;

                    return total + durationInHours;
                  }, 0)
                  .toFixed(2);
                const totalAvailableHours = therapist?.slots
                  ?.filter(([start, end, status]) => status === "AVAILABLE" || status === "OVER_TIME")
                  ?.reduce((total, [start, end]) => {

                    const startMinutes = convertTimeToMinutes(start);
                    const endMinutes = convertTimeToMinutes(end);


                    const durationInHours = (endMinutes - startMinutes) / 60;

                    return total + durationInHours;
                  }, 0)
                  .toFixed(2);

                const totalNonAvailableHours = therapist?.slots
                  ?.filter(([start, end, status]) => status !== "AVAILABLE" && status !== "OVER_TIME")
                  ?.reduce((total, [start, end]) => {

                    const startMinutes = convertTimeToMinutes(start);
                    const endMinutes = convertTimeToMinutes(end);


                    const durationInHours = (endMinutes - startMinutes) / 60;

                    return total + durationInHours;
                  }, 0)
                  .toFixed(2);

                const totalBookedHours = therapist?.slots
                  ?.filter(([start, end, status]) => status === "BOOKED")
                  ?.reduce((total, [start, end]) => {
                    const startMinutes = convertTimeToMinutes(start);
                    const endMinutes = convertTimeToMinutes(end);

                    const durationInHours = (endMinutes - startMinutes) / 60;


                    return total + durationInHours;
                  }, 0)
                  .toFixed(2);

                return (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div className="calendar-therapist-item">
                          <div className="calendar-therapist-item-header">

                            <img src={therapist.media?.[0]?.original_url ?? image} alt="avatar" className="avatar-img-60" />
                            <div className="therapist-item-title">
                              <div className="calendar-therapist-name">
                                {therapist.firstname} {therapist.lastname}
                              </div>
                              <div className="calendar-therapist-zone">
                                Type: {therapist.agent_type ? asTitle(therapist.agent_type) : '-'}
                              </div>
                              <div className="calendar-therapist-zone">
                                {therapist.zones.length ? therapist.zones?.map(zone => zone.name).join(', ') : '-'}
                              </div>
                            </div>
                          </div>
                          <div className="calendar-therapist-availibility">
                            <div className="details-items">
                              <p className="availability-therapist">Available: {totalAvailableHours} Hrs</p>
                              <p className="availability-therapist">Booked Time: {totalBookedHours} Hrs</p>
                              <p className="availability-therapist">
                                Availability: {(((parseFloat(totalAvailableHours)) / (parseFloat(totalHours))) * 100).toFixed(2)}
                                %
                              </p>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    }
                  >
                    <div key={index} className="calendar-therapist">
                      <div className="calendar-therapist-item">
                        <div className="calendar-therapist-item-header">

                          <img src={therapist.media?.[0]?.original_url ?? image} alt="avatar" className="avatar-img" />
                          <div className="therapist-item-title">
                            <div className="calendar-therapist-name">
                              {therapist.firstname} {therapist.lastname}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </HtmlTooltip>
                );
              })}

            </Sidebar>

            <CalendarContainer>
              <TimeSlots>
                {renderHourLabels()}
                {renderTimeSlots()}

                {currentTimeLeft !== null && (
                  <CurrentTimeWrapper $left={currentTimeLeft} scrollposition={scrollposition}>
                    <CurrentTimeIndicatorLine />
                    <TooltipCustom>
                      {format(currentTime, 'hh:mm')}
                    </TooltipCustom>
                  </CurrentTimeWrapper>
                )}
              </TimeSlots>
            </CalendarContainer>
          </Container>
        </div>
      )}

      {viewType === 'grid' && (
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: 'calc(100vh - 206px)' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" colSpan={1} rowSpan={2}>
                    {new Date().toDateString()}
                  </TableCell>
                  {
                    filteredZones.map((zone) => (
                      <TableCell key={zone.id} align="center" style={{ textAlign: 'center !important' }} colSpan={filteredTherapists.filter((therapist) => therapist.zones.findIndex(z => z.id === zone.id) !== -1).length + 1}>
                        {zone.name}
                      </TableCell>
                    ))
                  }
                </TableRow>
                <TableRow>
                  {
                    filteredZones.map((zone) => {
                      return (<>
                        {
                          filteredTherapists.filter((therapist) => therapist.zones.findIndex(z => z.id === zone.id) !== -1).map(
                            (therapist) => {
                              return (
                                <TableCell
                                  key={therapist.id}
                                  align={"left"}
                                  style={{ top: 57, minWidth: "40px" }}
                                >
                                  {therapist.extra['full_name']}
                                </TableCell>
                              )
                            }
                          )
                        }
                        <TableCell
                          align={"left"}
                          style={{ top: 57, minWidth: "40px" }}
                        >
                          Coverage
                        </TableCell>
                      </>)
                    })
                  }
                </TableRow>
              </TableHead>
              <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                <TableBody>
                  {
                    [...rows].map(([date, zones]) => {
                      return (
                        <TableRow hover tabIndex={-1} key={date}>
                          <TableCell align={'left'}>
                            {(new Date(date).toDateString())}
                          </TableCell>
                          {
                            [...zones].map(([zoneIndex, zone]) => (<DateRow key={zoneIndex} date={date} zone={zone}></DateRow>))
                          }
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </DndContext>
            </Table>
          </TableContainer>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: "row-reverse", alignItems: 'center', gap: 1 }}>
            <ThemeProvider theme={theme} >
              {gridEdit === false ?
                (
                  <Button color='dark' variant='contained' onClick={() => setGridEdit(true)} endIcon={<EditIcon sx={{ height: 15 }} />}>
                    Edit
                  </Button>
                ) :
                (
                  <>
                    <Button color='dark' variant='contained' onClick={() => setGridEdit(false)} endIcon={<EditIcon sx={{ height: 15 }} />}>
                      Edit
                    </Button>
                    <Button color='dark' variant='outlined' onClick={() => setGridEdit(false)} endIcon={<EditIcon sx={{ height: 15 }} />}>
                      Cancel
                    </Button>
                  </>
                )
              }
            </ThemeProvider>

          </Box>
        </Paper>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Import Modal"
        ariaHideApp={false}
        style={customStyles}
      >

        <h2>Import File</h2>
        <div className="modal-content">
          {/* <input type="file" onChange={handleFileChange} /> */}
          <ThemeProvider theme={theme}>

            <Button
              component="label"
              color='dark'
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<Folder />}
              sx={{ width: '100%', height: '200px' }}
              onClick={() => { handleReset(); setFile(null) }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {!file ? (
                "Drag & Drop your file here or click to select a file"
              ) : (
                <>
                  <Typography variant="body2" sx={{ flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                    {file.name}
                  </Typography>
                  <IconButton onClick={() => { handleReset(); setFile(null) }} size="small" sx={{ color: "red" }}>
                    <Close fontSize="small" />
                  </IconButton>
                </>
              )}
              <VisuallyHiddenInput
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                accept=".csv"
              />
            </Button>
          </ThemeProvider>

        </div>
        <div className='modal-footer'>
          <ThemeProvider theme={theme}>
            <Button
              component="label"
              color='dark'
              role={undefined}
              variant="contained"
              startIcon={<CloudUploadRounded />}
              onClick={handleImport}
            >
              Upload
            </Button>
            <Button
              component="label"
              color='dark'
              role={undefined}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </ThemeProvider>
        </div>
        {message && <div className="message">{message}</div>}
      </Modal>
      <Modal
        isOpen={exportModalIsOpen}
        onRequestClose={closeExportModal}
        contentLabel="Import Modal"
        ariaHideApp={false}
        style={customStyles}
      >
        <h2>Export Template File</h2>
        <div className="form-inscription">
          <form onSubmit={handleDownload}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className='flex-row align-center space-around'>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  shouldDisableDate={(date) => isSundayOrPast(date)} // Disable Sundays for start date
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  shouldDisableDate={(date) => isSundayOrBeforeStartDate(date)} // Disable dates before start date and Sundays
                />
              </div>
            </LocalizationProvider>
            <div className='flex-row'>
              <div className="form-text">
                <label>Therapists*</label>
                <Select
                  value={selectedFlyproOption}
                  onChange={handleFlyproChange}
                  options={flyproOptions}
                  styles={customSelectStyles}
                  isClearable
                  required
                  isSearchable
                  isMulti
                  placeholder="Select Therapists"
                  noOptionsMessage={() => "No therapist available"}
                />
              </div>
            </div>
            <button type='submit' className="btn-submit">Download</button>
          </form>
        </div>
        {message && <div className="message">{message}</div>}
      </Modal>

      <Modal
        isOpen={createModalIsOpen}
        onRequestClose={closeCreateModal}
        contentLabel="Create Slots"
        ariaHideApp={false}
        style={customStyles}
      >
        <h2>Create Slots</h2>
        <div className="form-inscription">
          <form onSubmit={handleCreateSlots}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className='flex-row align-center space-around'>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  shouldDisableDate={(date) => isSundayOrPast(date)} // Disable Sundays for start date
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  shouldDisableDate={(date) => isSundayOrBeforeStartDate(date)} // Disable dates before start date and Sundays
                />
              </div>
            </LocalizationProvider>
            <div className='flex-row'>
              <div className="form-text">
                <label>Therapists*</label>
                <Select
                  value={selectedFlyproOption}
                  onChange={handleFlyproChange}
                  options={flyproIdOptions}
                  styles={customSelectStyles}
                  isClearable
                  required
                  isSearchable
                  isMulti
                  placeholder="Select Therapists"
                  noOptionsMessage={() => "No therapist available"}
                />
              </div>
            </div>
            <h3>Slots *</h3>

            {slots.map((slot, index) => (<div className='flex-row align-center' key={index}>
              <div className="form-text timeOfTheBookingParent topMarg">
                <label>Start Time</label>
                <input
                  type="text"
                  name="timeBooking"
                  value={slot.start_time}
                  onClick={() => {
                    setSelectedSlot({ index, is_start: true, is_end: false });
                    const min = index === 0 ? new Date('1970-01-01T08:00:00') : new Date(`1970-01-01T${slots[index - 1].end_time}:00`)
                    const max = slots[index].end_time ? new Date(`1970-01-01T${slots[index].end_time}:00`) : new Date('1970-01-01T22:00:00');
                    max.setMinutes(max.getMinutes() - 15);
                    setMinTime(min);
                    setMaxTime(max);
                    setTimePickerIsOpen(true);
                  }}
                  readOnly
                />
                <CiClock2 className="time-icon" onClick={() => { setTimePickerIsOpen(true) }} />
              </div>
              <div className="form-text timeOfTheBookingParent topMarg">
                <label>End Time</label>
                <input
                  type="text"
                  name="timeBooking"
                  value={slot.end_time}
                  onClick={() => {
                    setSelectedSlot({ index, is_end: true, is_start: false });
                    const min = slots[index].start_time ? new Date(`1970-01-01T${slots[index].start_time}:00`) : new Date('1970-01-01T08:00:00');
                    min.setMinutes(min.getMinutes() + 15);
                    const max = new Date('1970-01-01T22:00:00');
                    setMinTime(min);
                    setMaxTime(max);
                    setTimePickerIsOpen(true);
                  }}
                  readOnly
                />
                <CiClock2 className="time-icon" onClick={() => setTimePickerIsOpen(true)} />
              </div>
              <div className="form-text">
                <label>Status*</label>
                <Select
                  value={{ label: asTitle(slot.status), value: slot.status }}
                  onChange={(status) => {
                    const newSlots = slots.map((slot, i) => index !== i ? slot :
                      { ...slot, status: status.value }
                    );
                    setSlots(newSlots);
                  }}
                  options={[{ label: 'Available', value: 'AVAILABLE' }, { label: 'Free Time', value: 'FREE_TIME' }, { label: 'Overtime', value: 'OVER_TIME' }, { label: 'Holiday', value: 'HOLIDAY' }, { label: 'Day Off', value: 'OFF' }]}
                  styles={customSelectStyles}
                  isClearable
                  required
                  isSearchable
                  placeholder="Select Status"
                  noOptionsMessage={() => "No Status available"}
                />
              </div>
              <button type="button" className="close-button" style={{ border: 'none', height: 'fit-content' }} onClick={() => {
                setSlots(slots.filter((slot, i) => index !== i));
              }}>
                <span className="close-icon">✖</span>
              </button>
            </div>))}
            <button type='button' className="btn-submit" onClick={() => {
              if (slots[slots.length - 1].end_time == '22:00') {
                notify.warn('You can\'t add a slot after 22:00')
              } else {
                setSlots([...slots, { start_time: slots[slots.length - 1].end_time, end_time: '22:00', status: 'AVAILABLE' }])
              }
            }}>Add Slot</button>

            <button type='submit' className="btn-submit">Create Slots</button>
          </form>
        </div>
        {message && <div className="message">{message}</div>}
      </Modal>
      <Modal
        isOpen={timePickerIsOpen}
        onRequestClose={handleCancelClick}
        style={timerStyles}
        contentLabel="Time Picker Modal"
      >
        <div className="time-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticTimePicker
              displayStaticWrapperAs="mobile"
              value={selectedTime}
              ampm={false}
              minTime={minTime}
              maxTime={maxTime}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <div className="pickerActions">
              <button className="action-timer" onClick={handleCancelClick}>Cancel</button>
              <button className="action-timer" onClick={handleOkClick}>OK</button>
            </div>
          </LocalizationProvider>
        </div>
      </Modal>
    </>
  );
};

export default Calendar;