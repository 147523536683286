import "../Breadcumb/Breadcrumb.css";
import React, { useState } from "react";
import Modal from 'react-modal';
import CreateTaskManagerModal from "../CreateBookingManagerModal";

const Breadcrumb = ({ onViewChange, selected, axiosPrivate }) => {
  Modal.setAppElement('#root');

  const handleButtonClick = (view) => {
    onViewChange(view);
  };

  return (
    <>
      <div className="bloc-search background-breadcrumb">
        <div className="container">
          <div className="search">
            <div className='breadcrumb-navigation'>
              <button
                className={`breadcrumb-map-button ${selected === 'map' ? 'active' : ''}`}
                onClick={() => handleButtonClick('map')}
              >
                <div className={`icon ${selected === 'map' ? 'active-icon' : ''}`} />
                <span className='breadcrumb-label'>Map</span>
              </button>

              <button
                className={`breadcrumb-list-button ${selected === 'list' ? 'active' : ''}`}
                onClick={() => handleButtonClick('list')}
              >
                <div className={`icon ${selected === 'list' ? 'active-icon' : ''}`} />
                <span className='breadcrumb-label-list'>List</span>
              </button>
            </div>
          </div>
          {selected === "map" && <CreateTaskManagerModal axiosPrivate={axiosPrivate} />}
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
