import React, { useEffect, useState } from "react";
import MenuHeader from "./MenuHeader";
import Modal from 'react-modal';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { formatDistanceToNow } from 'date-fns';
import NotificationService from "../services/notification.service";
import UserService from "../services/user.service";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    height: '680px',
  },
};

const Notification = () => {
  Modal.setAppElement('#root');

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [notifications, setNotifications] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [hasMore, setHasMore] = useState(true);
  const limit = 100; // Nombre de clients par page
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const [totalPages, setTotalPages] = useState(1);   // Total des pages
  const [suggestions, setSuggestions] = useState([]);
  const { auth } = useAuth();
  const navigation = useNavigate();
  const goBack = () => navigation(-1);
  const [formData, setFormData] = useState({
    group_user: "",
    civility: "",
    type: "admin",
    message: "",
    titre: "",
    user_id: "",
    date: new Date().toISOString().split('T')[0]

  });

  const handleDelete = async (id) => {

    try {
      console.log(formData)

      await NotificationService.delete(id, axiosPrivate)
      setCurrentPage(1)
      await fetchNotifications(true);
      closeModal();
    } catch (error) {
      console.error("Erreur lors du chargement des notification:", error);
      // 
    }

    console.log(`Delete ${id}`);


  };
  const handleSubmit = async (e) => {

    e.preventDefault();


    try {
      console.log(auth)

      const updatedFormData = {
        ...formData,
        date: new Date().toISOString().replace('T', ' ').substring(0, 19),
        type: "admin",
        user_id: formData.group_user == "allClients" || formData.group_user == "allFlypro" ? auth?.user?.id : formData.user_id
      };
      await NotificationService.store(updatedFormData, axiosPrivate)
      fetchNotifications(true);
      closeModal();
    } catch (error) {
      console.error("Erreur lors du chargement des notification:", error);
      // 
    }
  };

  const TimeAgo = ({ date }) => {
    return (
      <span>
        {formatDistanceToNow(new Date(date), { addSuffix: true })}
      </span>
    );
  };

  const fetchNotifications = async (refresh = null) => {
    try {
      const response = await NotificationService.index({
        with: `type`,
        'type': `in:admin`, 'page': `${refresh ? 1 : currentPage}`, 'per_page': 5, 'sort': '-id'
      }, axiosPrivate)


      setHasMore(response?.last_page > response?.current_page)
      refresh ?
        setNotifications(response.data)
        :
        setNotifications((prev) => {
          const newNotifications = response.data.filter(
            (newNotif) => !prev.some((existingNotif) => existingNotif.id === newNotif.id)
          );

          return [...prev, ...newNotifications];
        });
    } catch (error) {
      console.error("Erreur lors du chargement des notification:", error);

    }
  };

  useEffect(() => {


    fetchNotifications();
  }, [currentPage]);
  const handleChange = async (e) => {
    console.log(e.target.name, e.target.value)
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "group_user") {
      setSelectedOption(e.target.value);
    }
    console.log("selectedOption", formData.group)
    if (e.target.name == "group_user" || e.target.name === "email") {
      const value = e.target.value;
      console.log(value?.length)
      if (value?.length > 2) {
        console.log("started", e.target.name)
        try {

          const response = await UserService.indexByRole({
            with: `email`,
            'email': `contains:${value}`
          }, formData.group_user == "oneSpecificClient" ? "ROLE_CLIENT" : 'ROLE_FLYPRO', axiosPrivate);
          setSuggestions(response); // Assurez-vous que le format des données est correct
          console.log(response)

        } catch (error) {
          console.error('Erreur lors de la récupération des suggestions', error);
        }
      } else {
        setSuggestions([]); // Réinitialiser si moins de 3 caractères
      }

    }

  };

  const handleSuggestionClick = (suggestion, email) => {
    console.log(email)
    setFormData({ ...formData, user_id: suggestion, email });
    setSuggestions([]); // Effacer les suggestions après sélection
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {

  }

  const loadMoreOnClick = () => {

    setCurrentPage((prev) => prev + 1);
  };

  function closeModal() {
    setFormData({
      group_user: "",
      civility: "",
      type: "admin",
      message: "",
      titre: "",
      user_id: "",
      date: new Date().toISOString().split('T')[0]

    })
    setIsOpen(false);
  }


  return (
    <>
      <MenuHeader />
      <div className="notification-breadcumb">
        <div className="turn-back">
          <div className="frame">
            <div className="div" onClick={goBack} >{"<"} Retour</div>
          </div>
        </div>
        <div className="container">
          <button className="btn-send-notif" onClick={openModal}>
            Send notification
          </button>
        </div>
      </div>
      <div className="container">
        {notifications?.map((notification, index) => (
          <div className="notification" key={index}>
            <div onClick={() => handleDelete(notification.id)} className="notification-close-btn">×</div>
            {notification?.user?.image &&
              <div className="profile-picture">
                <img src={notification?.user?.image} alt="FlySpa" className="avatar-img" />
              </div>
            }
            <div className="notification-card-column">
              <div className="notification-card-header">
                <div className="notification-card-title">
                  <div className="notification-card-title-text">{notification.titre}</div>
                </div>
                <div className="notification-card-time">
                  <div className="notification-card-title-time-text"><TimeAgo date={notification.date} /></div>
                </div>
              </div>
              <div className="notification-card-content-wrapper">
                <div className="notification-card-content">
                  <div className="notification-card-content-text">{notification.message}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {hasMore && (
          <div className="btn-bottom">
            <button className="loading-more-btn btn btn-primary" onClick={loadMoreOnClick}>
              Load more
            </button>
          </div>
        )}

      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Send notification"
      >
        <button class="close-button" onClick={closeModal}>
          <span class="close-icon">✖</span>
        </button>

        <h2>Envoyer une notification</h2>
        <p>If you need more info, please check out </p>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-text">
              <label>Send it to</label>
              <select name="group_user" onChange={handleChange} value={formData.group}>
                <option value="">Select...</option>
                <option value="allFlypro">All flyPROs</option>
                <option value="allClients">All clients</option>
                <option value="oneSpecificFlyPRO">One specific flyPRO</option>
                <option value="oneSpecificClient">One specific client</option>
              </select>
            </div>
            {(selectedOption === "allClients") && (
              <div className="form-choix flex-start">
                <div className="form-radio">
                  <input type="radio" name="civility" value="mr" id="hommes" onChange={handleChange} />
                  <label htmlFor="hommes">Hommes</label>
                </div>
                <div className="form-radio">
                  <input type="radio" name="civility" value="mrs" id="femmes" onChange={handleChange} />
                  <label htmlFor="femmes">Femmes</label>
                </div>
              </div>
            )}
            {selectedOption === "oneSpecificFlyPRO" && (
              <div className="form-text">
                <label>FlyPRO email</label>
                <input type="text" name="email" onChange={handleChange} value={formData.email} />
              </div>
            )}
            {selectedOption === "oneSpecificClient" && (
              <div className="form-text">
                <label>Client email</label>
                <input type="text" name="email" onChange={handleChange} value={formData.email} />
              </div>
            )}
            {suggestions?.length > 0 && (
              <ul className="suggestions-list">
                {suggestions.map((suggestion, index) => (
                  <li key={index} onClick={() => handleSuggestionClick(suggestion.id, suggestion.email)}>
                    {suggestion.email} {/* Ajustez selon la structure de vos données */}
                  </li>
                ))}
              </ul>
            )}
            <div className="form-text">
              <label>Title</label>
              <input type="text" name="titre" onChange={handleChange} value={formData.titre} />
            </div>
            <div className="form-text">
              <label>Your message</label>
              <textarea
                className="formTextArea"
                name="message"
                onChange={handleChange}
                value={formData.message}
              />
            </div>
            <button type="submit" className="btn-submit">
              Send Notification
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Notification;