import React, { useEffect, useState, useRef } from "react";
import MenuHeader from "./MenuHeader";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Modal from "react-modal";
import Typography from "@mui/material/Typography";
import imageFile from "../assets/images/upload-file.svg";
import useAxiosMultiPart from "../hooks/useAxiosMultiPart";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import ProductService from "../services/product.service";
import DomainService from "../services/domain.service";
import CategoryService from "../services/category.service";
import ZoneService from "../services/zone.service";
import { asTitle } from "../shared/utils/string";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuList, MenuItem, IconButton, ListItemText, ListItemIcon } from '@mui/material';


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    boxshadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    overflowX: "hidden",
    scrollbarWidth: "none",
    height: "680px",
  },
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#8fa9b9",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const Product = () => {
  Modal.setAppElement("#root");

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [domain, setDomain] = useState([]);
  const [category, setCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productList, setProductList] = useState([]);
  const axiosMultiPart = useAxiosMultiPart();
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [hoveredRow, setHoveredRow] = useState(null);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [filterData, setFilterData] = useState({
    search: "",
    domain: "",
    category: "",
    duration: "",
    is_active: "",
    is_hidden: "",
  });

  const [productFilters, setProductFilters] = useState({});
  const [filtersProduct, setFiltersProducts] = useState({});
  const [domainFilters, setDomainFilters] = useState({});
  const [categoryFilters, setCategoryFilters] = useState({});

  const [formData, setFormData] = useState({
    id: "",
    category_id: "",
    domain_id: "",
    subcategory_id: "",
    product_id: "",
    parent_id: "",
    parent_category_id: "",
    name: "",
    title: "",
    duration: "",
    type: "product",
    is_active: true,
    is_hidden: true,
    price: "",
    description: "",
    subproduct: "",
  });

  // Added state to track view type
  const [viewType, setViewType] = useState("product");
  const [domainsData, setDomainsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [sortField, setSortField] = useState(""); // Active sort field
  const [sortDirection, setSortDirection] = useState(true);
  const timeoutRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [availableZones, setAvailableZones] = useState([]);
  const [assignedZones, setAssignedZones] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [zoneModalIsOpen, setZoneModalIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorElDomain, setAnchorElDomain] = useState(null);
  const [anchorElCategory, setAnchorElCategory] = useState(null);
  const [selectedDomainIndex, setSelectedDomainIndex] = useState(null);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [allZones, setAllZones] = useState([]);


  const handleViewTypeChange = async (value) => {
    setViewType(value);
    setFilterData({
      search: "",
      domain: "",
      category: "",
      duration: "",
      is_active: "",
      is_domain_active: "",
      is_category_active: "",
    });
    await fetchData(value); // Fetch data with relevant filters
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // SUBCATEGORY creation
    if (formData.type === "subcategory" && name === "domain_id") {
      if (value) {
        try {
          const topLevelCategories = await CategoryService.index({ domain_id: value, parent_id: null, status: 'ACTIVE' }, axiosPrivate);
          setCategories(topLevelCategories);
        } catch (error) {

        }
      } else {
        setCategories([]);
      }
    }

    // PRODUCT or SUBPRODUCT creation
    if ((formData.type === "product" || formData.type === "subproduct") && name === "domain_id") {
      if (value) {
        try {
          const topLevelCategories = await CategoryService.index({ domain_id: value, parent_id: null, status: 'ACTIVE' }, axiosPrivate);
          setCategories(topLevelCategories);
        } catch (error) {

        }
      } else {
        setCategories([]);
      }
    }

    // CATEGORY creation
    if (formData.type === "category" && name === "domain_id") {
      if (!value) {
        // No domain selected, just reset domain_id
        setFormData({ ...formData, domain_id: "" });
      }
    }

    // SUBCATEGORY creation
    if (formData.type === "subcategory" && name === "parent_category_id") {
      if (value) {
        try {
          const response = await CategoryService.index(
            { parent_id: value, status: 'ACTIVE' },
            axiosPrivate
          );
          setSubCategories(response);
        } catch (error) {
          console.error(error);
        }
      } else {
        setSubCategories([]);
        setFormData({ ...formData, parent_category_id: "" });
      }
    }

    // PRODUCT or SUBPRODUCT creation
    if ((formData.type === "product" || formData.type === "subproduct") && name === "parent_category_id") {
      if (value) {
        try {
          const response = await CategoryService.index(
            { parent_id: value, status: 'ACTIVE' },
            axiosPrivate
          );
          setSubCategories(response);
        } catch (error) {
          console.error("Failed fetching subcategories for product:", error);
        }
      } else {
        setSubCategories([]);
        setFormData({ ...formData, parent_category_id: "" });
      }
    }

    // Handle file input
    if (name === "file") {
      console.log({ e });
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
        setFile(file);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (name === "type") {
      resetForm(value)
    }
  };

  const resetForm = (type = 'product') => {
    setFormData({
      id: "",
      category_id: "",
      domain_id: "",
      subcategory_id: "",
      product_id: "",
      parent_id: "",
      parent_category_id: "",
      name: "",
      title: "",
      duration: "",
      type: type,
      is_active: true,
      is_hidden: true,
      price: "",
      description: "",
      subproduct: "",
    });

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    setFileName("");
    setCategories([]);
    setSubCategories([]);
    setProductList([]);
  };

  const fetchData = async (type = viewType, filters = {}) => {
    try {
      const updatedFilters = {
        ...filters,
        page: currentPage,
        per_page: perPage,
      };

      if (type === "product") {
        const [response, domaineResponse, categoryResponse] = await Promise.all([
          ProductService.index({ with: "media,category.parent,domain,sub_products,parent,hotel", ...updatedFilters }, axiosPrivate),
          DomainService.index(null, axiosPrivate),
          CategoryService.index({ with: "parent" }, axiosPrivate),
        ]);
        setProducts(response.data);
        setDomain(domaineResponse);
        setCategory(categoryResponse);
        setTotal(response.total);
        setLastPage(response.last_page);
        if (response.last_page < currentPage) {
          setProductFilters({ ...filters, page: response.last_page })
          setFiltersProducts({ ...filters, page: response.last_page })
          setCurrentPage(response.last_page);
        }
      } else if (type === "domain") {
        const domainResponse = await DomainService.index({ ...updatedFilters, with: "media,zones" }, axiosPrivate);
        setDomainsData(domainResponse.data);
        setDomain(domainResponse.data);
        setTotal(domainResponse.total);
        setLastPage(domainResponse.last_page);
        if (domainResponse.last_page < currentPage) {
          setDomainFilters({ ...filters, page: domainResponse.last_page })
          setCurrentPage(domainResponse.last_page);
        }
      } else if (type === "category") {
        const categoriesResponse = await CategoryService.index({ with: "media,parent,domain", ...updatedFilters, parent_id: null }, axiosPrivate);
        setCategoriesData(categoriesResponse.data);
        console.log(categoriesResponse);
        setCategory(categoriesResponse.data);
        setTotal(categoriesResponse.total);
        setLastPage(categoriesResponse.last_page);
        if (categoriesResponse.last_page < currentPage) {
          setCategoryFilters({ ...filters, page: categoriesResponse.last_page })
          setCurrentPage(categoriesResponse.last_page);
        }
      }

      else if (type === "subcategory") {
        const categoriesResponse = await CategoryService.index({ with: "media,parent,domain", ...updatedFilters, parent_id: '!eq:null' }, axiosPrivate);
        setCategoriesData(categoriesResponse.data);
        console.log(categoriesResponse.data);
        setCategory(categoriesResponse.data);
        setTotal(categoriesResponse.total);
        setLastPage(categoriesResponse.last_page);
        if (categoriesResponse.last_page < currentPage) {
          setCategoryFilters({ ...filters, page: categoriesResponse.last_page })
          setCurrentPage(categoriesResponse.last_page);
        }
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchData(viewType, filtersProduct);
  }, [currentPage, perPage, viewType, filtersProduct]);

  const handleSwitchChange = (index, row) => async (event) => {
    await ProductService.update(row.id, { is_active: !row.is_active }, axiosPrivate);
    fetchData("product");
  };

  const handleHiddenSwitchChange = (index, row) => async (event) => {
    await ProductService.update(row.id, { is_hidden: !row.is_hidden }, axiosPrivate);
    fetchData("product");
  };

  const handleHiddenCatSwitchChange = (index, row) => async (event) => {
    await CategoryService.update(row.id, { is_hidden: !row.is_hidden }, axiosPrivate);
    fetchData(viewType);
  };

  const handleFormSwitchChange = (e) => {
    setFormData({ ...formData, is_active: !formData.is_active })
  }

  const handleHiddenFormSwitchChange = (e) => {
    setFormData({ ...formData, is_hidden: !formData.is_hidden })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ((formData.type === 'product' || formData.type === 'subproduct') && !formData.category_id) {
      formData.category_id = formData.parent_category_id;
    }
    console.log((formData.type))
    if (formData.type === 'domain' && formData.zones) {
      delete formData.zones;
    }
    // Créer une nouvelle instance de FormData
    const formDataToSend = new FormData();
    // Ajouter les données de formData (y compris les fichiers) à formDataToSend
    for (const key in formData) {
      const value = formData[key];
      if (value) {
        if (Array.isArray(value) || typeof value === 'object') {
          formDataToSend.append(key, JSON.stringify(value));
        } else if (typeof value === 'boolean') {
          formDataToSend.append(key, Number(value)); // Convert boolean to string
        } else {
          formDataToSend.append(key, value);
        }
      }
    }
    console.log({ file })
    // Si vous avez un fichier dans un champ 'file', vous devez l'ajouter explicitement
    if (file) {
      formDataToSend.append("imageFile", file);
      // } else {
      //   if (!formData.id && formData.type !== "product") {
      //     Swal.fire({
      //       icon: "error",
      //       title: "Oops...",
      //       text: "Merci de selectionner un logo ",
      //     });
      //     return;
      //   }

    }
    for (const pair of formDataToSend.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
    console.log(file ? 'axiosMultiPart' : 'axiosPrivate')
    if (formData.id) {
      switch (formData.type) {
        case 'product':
          ProductService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate)
          break;
        case 'category':
          CategoryService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate)
          break;
        case 'subcategory':
          CategoryService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate)
          break;
        case 'subproduct':
          ProductService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate)
          break;
        default:
          DomainService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate)
          break;
      }
      closeModal();
      setTimeout(() => fetchData(viewType), 100);
      resetForm();

    } else {
      try {
        switch (formData.type) {
          case 'product':
            await ProductService.store(formDataToSend, axiosMultiPart);
            break;
          case 'subproduct':
            await ProductService.store(formDataToSend, axiosMultiPart);
            break;
          case 'category':
            await CategoryService.store(formDataToSend, axiosMultiPart);
            break;
          case 'subcategory':
            await CategoryService.store(formDataToSend, axiosMultiPart);
            break;
          default:
            await DomainService.store(formDataToSend, axiosMultiPart);
            break;
        }
        closeModal();
        fetchData(viewType);
      } catch { }
    }
  };

  async function openModal(data, type, editType) {
    resetForm();
    const domain_id = editType !== 'domain' ? data?.domain_id || "" : "";
    const category_id = editType === 'product' || editType === 'subproduct' ? data?.category?.parent_id || "" : editType === 'subcategory' ? data?.parent_id || "" : "";
    const subcategory_id = editType === 'product' || editType === 'subproduct' ? data?.category_id || "" : "";
    const product_id = editType === 'subproduct' ? data?.parent_id || "" : "";

    if (domain_id && domain_id != '') {
      try {
        const response = await CategoryService.index({ domain_id: domain_id, status: 'ACTIVE' }, axiosPrivate);
        setCategories(response);
      } catch (error) {

      }
    }

    if (category_id && category_id != '') {
      try {
        const response = await CategoryService.index({ parent_id: category_id, status: 'ACTIVE' }, axiosPrivate);
        setSubCategories(response);
      } catch (error) {

      }
    }

    if (subcategory_id && subcategory_id != '') {
      try {
        const response = await ProductService.index({ category_id: subcategory_id, status: 'ACTIVE' }, axiosPrivate);
        setProductList(response);
      } catch (error) {

      }
    }

    // Open the modal after resetting the form
    setIsOpen(true);
    switch (type) {
      case 'product': {
        let newParentId = "";
        let newSubcatId = "";

        if (data?.category?.parent_id) {
          newParentId = data.category.parent_id;
          newSubcatId = data.category_id;
        } else {
          newParentId = data.category_id;
          newSubcatId = "";
        }

        editType === 'product'
          ? setFormData({
            ...formData,
            ...data,
            type,
            parent_category_id: newParentId,
            category_id: newSubcatId
          })
          : setFormData({
            ...formData,
            ...data.parent,
            type,
            parent_category_id: newParentId
          });
        break;
      }
      case 'subproduct':
        setFormData({ ...formData, ...data, parent_category_id: category_id, parent_id: product_id, type });
        break;
      case 'domain':
        setFormData({ ...formData, ...data.domain, type });
        break;
      case 'subcategory':
        editType === 'product' || editType === 'subproduct' ? setFormData({ ...formData, ...data.category, type }) : setFormData({ ...formData, ...data, type });
        break;
      case 'category':
        editType === 'subcategory' ? setFormData({ ...formData, ...data.parent, type }) : editType === 'category' ? setFormData({ ...formData, ...data, type }) : setFormData({ ...formData, ...data.category.parent, type });
        break;
    }
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    resetForm();
    setIsOpen(false);
  }

  const handleDelete = async (id) => {
    if (viewType === "domain") {
      try {
        const response = await DomainService.delete(id, axiosPrivate);
        fetchData('domain');
      } catch (error) {

      }
    }
    else if (viewType === "category") {
      try {
        const response = await CategoryService.delete(id, axiosPrivate);
        fetchData('category');
      } catch (error) {

      }
    }
    else if (viewType === "product") {
      try {
        const response = await ProductService.delete(id, axiosPrivate);
        fetchData('product');
      } catch (error) {

      }
    }
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();

    if (viewType === "domain") {
      const newDomainFilters = {
        title: filterData.search.trim(),
        is_active: filterData.is_domain_active,
      };
      setDomainFilters(newDomainFilters);
      await fetchData("domain", newDomainFilters);
    } else if (viewType === "product") {
      const newProductFilters = {
        name: filterData.search.trim(),
        is_active: filterData.is_active,
        domain_id: filterData.domain,
        category_id: filterData.category,
        duration: filterData.duration,
      };
      setProductFilters(newProductFilters);
      await fetchData("product", newProductFilters);
    } else if (viewType === "category") {
      const newCategoryFilters = {
        title: filterData.search.trim(),
        is_active: filterData.is_category_active,
      };
      setCategoryFilters(newCategoryFilters);
      await fetchData("category", newCategoryFilters);
    }
  };

  const handleFilterChange = async (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
    if (name === "search" && value === "") {
      if (viewType === "domain") {
        await fetchData("domain", filterData);
      } else if (viewType === "product") {
        delete filterData.name;
        await fetchData("product", filterData);
      } else if (viewType === "category") {
        await fetchData("category", filterData);
      }
    }

    if (name === "is_active" || name === "is_domain_active" || name === "is_category_active") {
      if (viewType === "product") {
        const newProductFilters = {
          ...productFilters,
          is_active: value,
          name: filterData.search.trim(),
          domain_id: filterData.domain,
          category_id: filterData.category,
          duration: filterData.duration,
        };
        setProductFilters(newProductFilters);
        await fetchData("product", newProductFilters);
      } else if (viewType === "domain") {
        const newDomainFilters = {
          ...domainFilters,
          is_active: value,
          title: filterData.search.trim(),
        };
        setDomainFilters(newDomainFilters);
        await fetchData("domain", newDomainFilters);
      } else if (viewType === "category") {
        const newCategoryFilters = {
          ...categoryFilters,
          is_active: value,
          title: filterData.search.trim(),
        };
        setCategoryFilters(newCategoryFilters);
        await fetchData("category", newCategoryFilters);
      }
    }
  };

  const handleNextPage = () => {
    if (currentPage < lastPage) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleLastPage = () => {
    if (currentPage !== lastPage) setCurrentPage(lastPage);
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handleSelectedSort = async (event) => {
    const selected = document.querySelector('.sort-header.active');
    let direction = true;
    if (selected?.id !== event.target.id) {
      selected?.classList.remove('active');
      event.target.classList.add('active');
      setSortDirection(true);
    } else {
      setSortDirection(!sortDirection);
      direction = !sortDirection;
    }

    const sortField = event.target.id === 'name' ? `${direction ? '' : '-'}name` : `${direction ? '' : '-'}${event.target.id}`;


    // Apply sorting to the appropriate view
    const updatedFilters = { ...filtersProduct, sort: sortField, page: 1 };

    // Update the filters and fetch data for the selected view type
    switch (viewType) {
      case "product":
        setProductFilters(updatedFilters);
        await fetchData("product", updatedFilters);
        break;
      case "domain":
        setDomainFilters(updatedFilters);
        await fetchData("domain", updatedFilters);
        break;
      case "category":
        setCategoryFilters(updatedFilters);
        await fetchData("category", updatedFilters);
        break;
      default:
        break;
    }
  };

  const handleEditMouseEnter = (index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setHoveredRow(index);
  };

  const handleEditMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredRow(null);
    }, 250);
  };

  const handleClick = (event, index) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  // ---- Manage Zone ------

  const openZoneModal = async (domain) => {
    setSelectedDomain(domain);

    const assigned = domain.zones || [];
    setAssignedZones(assigned);

    try {
      const response = await ZoneService.index(null, axiosPrivate);
      setAllZones(response);

      const assignedIds = assigned.map(z => z.id);
      const unassigned = response.filter(z => !assignedIds.includes(z.id));

      setAvailableZones(unassigned);
    } catch (error) {

    }

    setZoneModalIsOpen(true);
  };

  const closeZoneModal = () => {
    setZoneModalIsOpen(false);
    setSelectedDomain(null);
    setAssignedZones([]);
    setAvailableZones([]);
  };

  const handleAssignZone = (zone) => {
    setAssignedZones(prev => [...prev, zone]);
    setAvailableZones(prev => prev.filter(z => z.id !== zone.id));
  };

  const handleUnassignZone = (zone) => {
    setAvailableZones((prev) => [...prev, zone]);
    setAssignedZones((prev) => prev.filter((z) => z.id !== zone.id));
  };

  const handleSaveZones = async () => {
    if (!selectedDomain) return;

    try {
      const zoneIds = assignedZones.map((z) => z.id);
      await DomainService.update(selectedDomain.id, { zones: zoneIds }, axiosPrivate);
      fetchData("domain");
      closeZoneModal();
    } catch (error) {

    }
  };

  // Handle search for zones
  const handleSearch = () => {
    const filteredZones = availableZones.filter((zone) =>
      zone.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setAvailableZones(filteredZones);
  };

  const handleDomainMenuClick = (event, index) => {
    event.stopPropagation();
    setAnchorElDomain(event.currentTarget);
    setSelectedDomainIndex(index);
  };

  const handleDomainMenuClose = () => {
    setAnchorElDomain(null);
    setSelectedDomainIndex(null);
  };

  const handleCategoryMenuClick = (event, index) => {
    event.stopPropagation();
    setAnchorElCategory(event.currentTarget);
    setSelectedCategoryIndex(index);
  };

  const handleCategoryMenuClose = () => {
    setAnchorElCategory(null);
    setSelectedCategoryIndex(null);
  };

  return (
    <>
      <MenuHeader />
      <div className="product-breadcumb">
        <div className="container">
          <div className="search">
            <select onChange={(e) => handleViewTypeChange(e.target.value)} value={viewType}>
              <option value="domain">All Domains</option>
              <option value="category">All Categories</option>
              <option value="subcategory">All Sub Categories</option>
              <option value="product">All Products</option>
            </select>
          </div>
          <button onClick={() => openModal(null, null, null)} className="btn-add-product">
            Add
          </button>
        </div>
      </div>

      {viewType === "product" && (
        <div className="bloc-search no-background">
          <div className="container">
            <form onSubmit={handleFilterSubmit} className="search">
              <div className="text-search">
                <input
                  type="text"
                  name="search"
                  value={filterData.search}
                  onChange={handleFilterChange}
                  placeholder="Search by Name"
                  className="form-text"
                />
                <input type="submit" className="form-submit" value="Search" />
              </div>
              <select onChange={handleFilterChange} name="domain" value={filterData.domain}>
                <option value="">Domains</option>
                {domain.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.title}
                  </option>
                ))}
              </select>
              <select onChange={handleFilterChange} name="category" value={filterData.category}>
                <option value="">Categories</option>
                {category.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.title}
                  </option>
                ))}
              </select>
              <select onChange={handleFilterChange} name="duration" value={filterData.duration}>
                <option value="">Duration</option>
                <option value="30">30 min</option>
                <option value="60">60 min</option>
                <option value="90">90 min</option>
              </select>
              <select onChange={handleFilterChange} name="is_active" value={filterData.is_active}>
                <option value="">Status</option>
                <option value={true}>Active</option>
                <option value={false}>Disabled</option>
              </select>
            </form>
          </div>
        </div>
      )}

      {viewType === "domain" && (
        <div className="bloc-search no-background">
          <div className="container">
            <form onSubmit={handleFilterSubmit} className="search">
              <div className="text-search">
                <input
                  type="text"
                  name="search"
                  value={filterData.search}
                  onChange={handleFilterChange}
                  placeholder="Search by Title"
                  className="form-text"
                />
                <input type="submit" className="form-submit" value="Search" />
              </div>
              <select onChange={handleFilterChange} name="is_domain_active" value={filterData.is_domain_active}>
                <option value="">Status</option>
                <option value={true}>Active</option>
                <option value={false}>Disabled</option>
              </select>
            </form>
          </div>
        </div>
      )}

      {(viewType === "category" || viewType === "subcategory") && (
        <div className="bloc-search no-background">
          <div className="container">
            <form onSubmit={handleFilterSubmit} className="search">
              <div className="text-search">
                <input
                  type="text"
                  name="search"
                  value={filterData.search}
                  onChange={handleFilterChange}
                  placeholder="Search by Title"
                  className="form-text"
                />
                <input type="submit" className="form-submit" value="Search" />
              </div>
              <select onChange={handleFilterChange} name="is_category_active" value={filterData.is_category_active}>
                <option value="">Status</option>
                <option value={true}>Active</option>
                <option value={false}>Disabled</option>
              </select>
            </form>
          </div>
        </div>
      )}

      <div className="tableau-list">
        <div className="container">
          {viewType === "product" && (
            <table>
              <thead className="no-background">
                <tr>
                  <th id="domain_id" onClick={handleSelectedSort} className="sort-header text-align-center">Domain</th>
                  <th>Image</th>
                  <th id="category_id" onClick={handleSelectedSort} className="sort-header text-align-center">Category</th>
                  <th id="subcategory_id" onClick={handleSelectedSort} className="sort-header text-align-center">Subcategory</th>
                  <th id="name" onClick={handleSelectedSort} className="sort-header text-align-center">Product</th>
                  <th id="subproduct" onClick={handleSelectedSort} className="sort-header text-align-center">Subproduct</th>
                  <th id="duration" onClick={handleSelectedSort} className="sort-header text-align-center">Duration (min)</th>
                  <th id="price" onClick={handleSelectedSort} className="sort-header text-align-center">Price (CHF)</th>
                  <th id="is_hotel_product" className="text-align-center">Is Hotel Product</th>
                  <th id="hotel_name" className="text-align-center">Hotel Name</th>
                  <th id="is_active" onClick={handleSelectedSort} className="sort-header text-align-center">Status</th>
                  <th className="text-align-center">Enable/Disable</th>
                  <th className="text-align-center">Hide/Unhide</th>
                  <th className="text-align-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {products.map((row, index) => (
                  <tr
                    key={index}
                    className={
                      !row?.is_active
                        ? "disabled-item"
                        : ""
                    }
                  >
                    <td className="domain-txt-color text-align-center">{row?.domain?.title ? row?.domain?.title : '-'}</td>
                    <td>
                      <img
                        src={`${row.media[0]?.original_url}`}
                        alt="Product Logo"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td className="text-align-center">
                      {row.category?.parent ? row.category.parent.title : row.category?.title || '-'}
                    </td>
                    <td className="text-align-center">
                      {row.category?.parent ? row.category.title : '-'}
                    </td>
                    <td className="text-align-center">{row.name}</td>
                    <td className="text-align-center">
                      {row?.sub_products?.map((product, index) => `${product.name}${index === row.sub_products?.length - 1 ? '' : ', '}`)}
                    </td>
                    <td className="text-align-center">{row.duration}</td>
                    <td className="text-align-center">{row.price}</td>
                    <td className="text-align-center">
                      {row.hotel_id ? "Yes" : "No"}
                    </td>
                    <td className="text-align-center">
                      {row?.hotel?.name ? row.hotel.name : '-'}
                    </td>
                    <td className={row.is_active ? "active text-align-center" : "disabled text-align-center"}>
                      {row.is_active ? "Active" : "Disabled"}
                    </td>
                    <td>
                      <Stack
                        direction="row"
                        spacing={0}
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                      >
                        {/* Toggle Switch */}
                        <AntSwitch
                          checked={row.is_active}
                          onChange={handleSwitchChange(index, row)}
                          inputProps={{ "aria-label": "status-toggle" }}
                        />
                      </Stack>
                    </td>
                    <td>
                      <Stack
                        direction="row"
                        spacing={0}
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                      >
                        {/* Toggle Switch */}
                        <AntSwitch
                          checked={row.is_hidden}
                          onChange={handleHiddenSwitchChange(index, row)}
                          inputProps={{ "aria-label": "status-toggle" }}
                        />
                      </Stack>
                    </td>
                    <td className="text-align-center">

                      {/* More Options Button */}
                      <IconButton onClick={(event) => handleClick(event, index)}>
                        <MoreVertIcon />
                      </IconButton>

                      {/* Material-UI Menu */}
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedIndex === index} // Open only for selected row
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                      >
                        <MenuList style={{ minWidth: 200 }}>
                          {row.parent_id ? (
                            <>
                              <MenuItem onClick={() => { handleClose(); openModal(row, "domain", "subproduct"); }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Domain</ListItemText>
                              </MenuItem>
                              <MenuItem onClick={() => { handleClose(); openModal(row, "product", "subproduct"); }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Parent Product</ListItemText>
                              </MenuItem>
                              <MenuItem onClick={() => { handleClose(); openModal(row, "subcategory", "subproduct"); }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Category</ListItemText>
                              </MenuItem>
                              <MenuItem onClick={() => { handleClose(); openModal(row, "category", "subproduct"); }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Parent Category</ListItemText>
                              </MenuItem>
                            </>
                          ) : (
                            <>
                              <MenuItem onClick={() => { handleClose(); openModal(row, "domain", "product"); }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Domain</ListItemText>
                              </MenuItem>
                              <MenuItem onClick={() => { handleClose(); openModal(row, "product", "product"); }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Product</ListItemText>
                              </MenuItem>
                              <MenuItem onClick={() => { handleClose(); openModal(row, "subcategory", "product"); }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Category</ListItemText>
                              </MenuItem>
                              <MenuItem onClick={() => { handleClose(); openModal(row, "category", "product"); }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Parent Category</ListItemText>
                              </MenuItem>
                            </>
                          )}

                          {/* Delete Option */}
                          <MenuItem style={{ color: 'red' }} onClick={() => { handleClose(); handleDelete(row.id); }}>
                            <ListItemIcon><DeleteIcon style={{ color: "red" }} /></ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>

              <tfoot >
                <tr>
                  <td colSpan={14}>
                    <div className="paginator">
                      <select value={perPage} onChange={handlePerPageChange} className="per-page">
                        <option value={1}>1</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                      </select>
                      <div className="total">Total Records: {total}</div>
                      <div className="pagination">
                        <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                        <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                        <span>{currentPage} / {lastPage}</span>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          )}

          {viewType === "domain" && (
            <table style={{ width: '100%', textAlign: 'center' }}>
              <thead className="no-background">
                <tr>
                  <th id="ordering" onClick={handleSelectedSort} className="sort-header text-align-center">Ordering</th>
                  <th id="title" onClick={handleSelectedSort} className="sort-header text-align-center">Domain Title</th>
                  <th id="description" onClick={handleSelectedSort} className="sort-header text-align-center">Description</th>
                  <th className="text-align-center">Image</th>
                  <th id="is_active" onClick={handleSelectedSort} className="sort-header text-align-center">Status</th>
                  <th className="text-align-center">Enable/Disable</th>
                  <th className="text-align-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {domainsData.map((row, index) => (
                  <tr key={index} className={!row.is_active ? "disabled-item" : ""}>
                    <td className="domain-txt-color text-align-center">
                      <input
                        type="number"
                        value={row.ordering}
                        onChange={(e) => {
                          const newOrdering = e.target.value;
                          const updatedDomains = [...domainsData];
                          updatedDomains[index].ordering = newOrdering;
                          setDomainsData(updatedDomains);
                        }}
                        onBlur={async (e) => {
                          const newOrdering = Number(e.target.value);
                          const isDuplicate = domainsData.some(
                            (domain) => domain.ordering === newOrdering && domain.id !== row.id
                          );

                          if (isDuplicate) {
                            toast.error("This ordering number is already assigned to another domain.");
                            fetchData("domain");
                            return;
                          }

                          try {
                            await DomainService.update(row.id, { ordering: newOrdering }, axiosPrivate);
                            fetchData("domain");
                          } catch (error) {
                            console.error("Failed to update ordering:", error);
                          }
                        }}
                        onKeyDown={async (e) => {
                          if (e.key === "Enter") {
                            e.target.blur();
                          }
                        }}
                        style={{ width: "60px", textAlign: "center", border: 0, background: "none" }}
                      />
                    </td>
                    <td className="domain-txt-color text-align-center">{row.title}</td>
                    <td className="text-align-center">{row.description}</td>
                    <td className="text-align-center">
                      <img
                        src={`${row.media[0]?.original_url}`}
                        alt="Domain Logo"
                        style={{ width: "50px", height: "50px", display: "inline" }}
                      />
                    </td>
                    <td className={row.is_active ? "active text-align-center" : "disabled text-align-center"}>
                      {row.is_active ? "Active" : "Disabled"}
                    </td>
                    <td className="text-align-center">
                      <Stack
                        direction="row"
                        spacing={0}
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                      >
                        <AntSwitch
                          defaultChecked={row.is_active}
                          onChange={async () => {
                            await DomainService.update(row.id, { is_active: !row.is_active }, axiosPrivate);
                            fetchData("domain");
                          }}
                          inputProps={{ "aria-label": "ant design" }}
                        />
                      </Stack>
                    </td>
                    <td className="text-align-center">
                      <IconButton onClick={(event) => handleDomainMenuClick(event, index)}>
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        anchorEl={anchorElDomain}
                        open={Boolean(anchorElDomain) && selectedDomainIndex === index}
                        onClose={handleDomainMenuClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                      >
                        <MenuList style={{ minWidth: 180 }}>
                          <MenuItem onClick={() => {
                            handleDomainMenuClose();
                            openModal({ domain: row }, "domain", "domain");
                          }}>
                            <ListItemIcon>
                              <EditIcon style={{ color: "#1976d2" }} />
                            </ListItemIcon>
                            <ListItemText>Edit Domain</ListItemText>
                          </MenuItem>

                          <MenuItem onClick={() => {
                            handleDomainMenuClose();
                            openZoneModal(row);
                          }}>
                            <ListItemIcon>
                              <EditIcon style={{ color: "#1976d2" }} />
                            </ListItemIcon>
                            <ListItemText>Manage Zones</ListItemText>
                          </MenuItem>

                          <MenuItem
                            style={{ color: "red" }}
                            onClick={() => {
                              handleDomainMenuClose();
                              handleDelete(row.id);
                            }}
                          >
                            <ListItemIcon>
                              <DeleteIcon style={{ color: "red" }} />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot >
                <tr>
                  <td colSpan={7}>
                    <div className="paginator">
                      <select value={perPage} onChange={handlePerPageChange} className="per-page">
                        <option value={1}>1</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                      </select>
                      <div className="total">Total Records: {total}</div>
                      <div className="pagination">
                        <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                        <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                        <span>{currentPage} / {lastPage}</span>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          )}

          {(viewType === "category" || viewType === "subcategory") && (
            <table>
              <thead className="no-background">
                <tr>
                  <th id="title" onClick={handleSelectedSort} className="sort-header text-align-center">{viewType === "category" ? "Category Title" : "Subcategory Title"}</th>
                  <th className="text-align-center">Image</th>
                  <th id="domain_id" onClick={handleSelectedSort} className="sort-header text-align-center">Domain</th>
                  <th id="parent.title" onClick={handleSelectedSort} className="sort-header text-align-center">Parent ID</th>
                  <th id="is_active" onClick={handleSelectedSort} className="sort-header text-align-center">Status</th>
                  <th className="text-align-center">Enable/Disable</th>
                  <th className="text-align-center">Hide/Unhide</th>
                  <th className="text-align-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {categoriesData.map((row, index) => (
                  <tr key={index} className={!row.is_active ? "disabled-item" : ""}>
                    <td className="text-align-center">{row.title}</td>
                    <td className="text-align-center">
                      <img
                        src={`${row.media[0]?.original_url}`}
                        alt="Category Logo"
                        style={{ width: "50px", height: "50px", display: "inline" }}
                      />
                    </td>
                    <td className="text-align-center">
                      {row?.domain ? row?.domain?.title : '-'}
                    </td>
                    <td className="text-align-center">{row.parent ? row.parent.title : "No parent"}</td>
                    <td className={row.is_active ? "active text-align-center" : "disabled text-align-center"}>
                      {row.is_active ? "Active" : "Disabled"}
                    </td>
                    <td>
                      <Stack
                        direction="row"
                        spacing={0}
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                      >
                        <AntSwitch
                          defaultChecked={row.is_active}
                          onChange={async () => {
                            await CategoryService.update(row.id, { is_active: !row.is_active }, axiosPrivate);
                            if (viewType === "subcategory") {
                              fetchData("subcategory");
                            } else {
                              fetchData("category");
                            }
                          }}
                          inputProps={{ "aria-label": "ant design" }}
                        />
                      </Stack>
                    </td>
                    <td>
                      <Stack
                        direction="row"
                        spacing={0}
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                      >
                        {/* Toggle Switch */}
                        <AntSwitch
                          checked={row.is_hidden}
                          onChange={handleHiddenCatSwitchChange(index, row)}
                          inputProps={{ "aria-label": "status-toggle" }}
                        />
                      </Stack>
                    </td>
                    <td className="text-align-center">
                      <IconButton onClick={(event) => handleCategoryMenuClick(event, index)}>
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        anchorEl={anchorElCategory}
                        open={Boolean(anchorElCategory) && selectedCategoryIndex === index}
                        onClose={handleCategoryMenuClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                      >
                        <MenuList style={{ minWidth: 180 }}>
                          {row.parent ? (
                            <>
                              <MenuItem onClick={() => {
                                handleCategoryMenuClose();
                                openModal(row, "category", "subcategory");
                              }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Parent Category</ListItemText>
                              </MenuItem>

                              <MenuItem onClick={() => {
                                handleCategoryMenuClose();
                                openModal(row, "subcategory", "subcategory");
                              }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Category</ListItemText>
                              </MenuItem>

                              <MenuItem onClick={() => {
                                handleCategoryMenuClose();
                                openModal(row, "domain", "subcategory");
                              }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Domain</ListItemText>
                              </MenuItem>
                            </>
                          ) : (
                            <>
                              <MenuItem onClick={() => {
                                handleCategoryMenuClose();
                                openModal(row, "category", "category");
                              }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Category</ListItemText>
                              </MenuItem>

                              <MenuItem onClick={() => {
                                handleCategoryMenuClose();
                                openModal(row, "domain", "category");
                              }}>
                                <ListItemIcon><EditIcon style={{ color: "#1976d2" }} /></ListItemIcon>
                                <ListItemText>Edit Domain</ListItemText>
                              </MenuItem>
                            </>
                          )}

                          <MenuItem
                            style={{ color: "red" }}
                            onClick={() => {
                              handleCategoryMenuClose();
                              handleDelete(row.id);
                            }}
                          >
                            <ListItemIcon><DeleteIcon style={{ color: "red" }} /></ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Menu>

                    </td>
                  </tr>
                ))}
              </tbody>

              <tfoot >
                <tr>
                  <td colSpan={8}>
                    <div className="paginator">
                      <select value={perPage} onChange={handlePerPageChange} className="per-page">
                        <option value={1}>1</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                      </select>
                      <div className="total">Total Records: {total}</div>
                      <div className="pagination">
                        <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                        <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                        <span>{currentPage} / {lastPage}</span>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="close-button" onClick={closeModal}>
          <span className="close-icon">✖</span>
        </button>

        <h2>{formData.id ? 'Update' : 'Add'} a {asTitle(formData.type)}</h2>
        <p>If you need more info, please check out</p>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            {(!formData.id) && (
              <div className="form-text">
                <select value={formData.type} name="type" onChange={handleChange}>
                  <option value="">Add ...</option>
                  <option value="domain">Add New Domain</option>
                  <option value="category">Add New Category</option>
                  <option value="subcategory">Add New Subcategory</option>
                  <option value="product">Add New Product</option>
                  <option value="subproduct">Add New Subproduct</option>
                </select>
              </div>
            )}

            {/* ---------- DOMAIN CREATION ---------- */}
            {formData.type === "domain" && (
              <>
                <div className="form-text">
                  <label>Domain Title*</label>
                  <input
                    required
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-text topMargArea">
                  <label>Description</label>
                  <textarea
                    className="formTextArea"
                    type="textarea"
                    value={formData.description}
                    name="description"
                    onChange={handleChange}
                  />
                </div>
              </>
            )}

            {/* ---------- CATEGORY CREATION ---------- */}
            {formData.type === "category" && (
              <>
                <div className="form-text">
                  <label>Domain*</label>
                  <select
                    required
                    name="domain_id"
                    onChange={handleChange}
                    value={formData.domain_id}
                  >
                    <option value="">Select a domain</option>
                    {domain.length > 0 ? (
                      domain.map((d) => (
                        <option key={d.id} value={d.id}>
                          {d.title}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>No domains available</option>
                    )}
                  </select>
                </div>
                <div className="form-text">
                  <label>Title*</label>
                  <input
                    required
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-text topMargArea">
                  <label>Description</label>
                  <textarea
                    className="formTextArea"
                    type="textarea"
                    value={formData.description}
                    name="description"
                    onChange={handleChange}
                  />
                </div>
                <div className="capability-input topMargArea">
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>Is Hidden</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        checked={formData.is_hidden ? true : false}
                        value={formData.is_hidden ? true : false}
                        onChange={handleHiddenFormSwitchChange}
                        inputProps={{ "aria-label": "status" }}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Stack>
                </div>
              </>
            )}

            {/* ---------- SUBCATEGORY CREATION (requires domain + parent) ---------- */}
            {formData.type === "subcategory" && (
              <>
                <div className="form-text">
                  <label>Domain*</label>
                  <select
                    required
                    name="domain_id"
                    onChange={handleChange}
                    value={formData.domain_id}
                  >
                    <option value="">Select a domain</option>
                    {domain.length > 0 ? (
                      domain.map((d) => (
                        <option key={d.id} value={d.id}>
                          {d.title}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>No domains available</option>
                    )}
                  </select>
                </div>

                <div className="form-text">
                  <label>Category*</label>
                  <select
                    required
                    name="parent_id"
                    onChange={handleChange}
                    value={formData.parent_id}
                    disabled={!formData.domain_id}
                  >
                    <option value="">Select a category</option>
                    {categories.length > 0 ? (
                      categories.map((c) => (
                        <option key={c.id} value={c.id}>{c.title}</option>
                      ))
                    ) : (
                      <option value="" disabled>No categories available</option>
                    )}
                  </select>
                </div>
                <div className="form-text">
                  <label>Title*</label>
                  <input
                    required
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-text topMargArea">
                  <label>Description</label>
                  <textarea
                    className="formTextArea"
                    type="textarea"
                    value={formData.description}
                    name="description"
                    onChange={handleChange}
                  />
                </div>
              </>
            )}

            {/* ---------- PRODUCT or SUBPRODUCT CREATION ---------- */}
            {(formData.type === "product" || formData.type === "subproduct") && (
              <>
                <div className="form-text">
                  <label>Domain*</label>
                  <select
                    required
                    name="domain_id"
                    onChange={handleChange}
                    value={formData.domain_id}
                  >
                    <option value="">Select a domain</option>
                    {domain.length > 0 ? (
                      domain.map((row) => (
                        <option key={row.id} value={row.id}>{row.title}</option>
                      ))
                    ) : (
                      <option value="" disabled>No domains available</option>
                    )}
                  </select>
                </div>
                <div className="form-text">
                  <label>Category*</label>
                  <select
                    required
                    name="parent_category_id"
                    onChange={handleChange}
                    value={formData.parent_category_id}
                  >
                    <option value="">Select a category</option>
                    {categories.length > 0 ? (
                      categories.map((row) => (
                        <option key={row.id} value={row.id}>{row.title}</option>
                      ))
                    ) : (
                      <option value="" disabled>No categories available</option>
                    )}
                  </select>
                </div>

                <div className="form-text">
                  <label>Subcategory</label>
                  <select
                    name="category_id"
                    onChange={handleChange}
                    value={formData.category_id}
                  >
                    <option value="">Select a Subcategory</option>
                    {subCategories.length > 0 ? (
                      subCategories.map((row) => (
                        <option key={row.id} value={row.id}>{row.title}</option>
                      ))
                    ) : (
                      <option value="" disabled>No Subcategories available</option>
                    )}
                  </select>
                </div>

                <div className="form-text">
                  <label>Product Name*</label>
                  <input
                    required
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>

                <div className="capability-input topMargArea">
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>Is Hidden</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        checked={formData.is_hidden ? true : false}
                        value={formData.is_hidden ? true : false}
                        onChange={handleHiddenFormSwitchChange}
                        inputProps={{ "aria-label": "status" }}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Stack>
                </div>

                <div className="form-text topMargArea">
                  <label>Description</label>
                  <textarea
                    className="formTextArea"
                    type="textarea"
                    value={formData.description}
                    name="description"
                    onChange={handleChange}
                  />
                </div>

                <div className="form-text">
                  <label>Duration *</label>
                  <input
                    required
                    type="number"
                    id="duration"
                    name="duration"
                    value={formData.duration}
                    onChange={handleChange}
                    min="0"
                    max="240"
                    step="1"
                    placeholder="Enter duration (in minutes)"
                  />
                </div>

                <div className="form-text">
                  <label>Price*</label>
                  <input
                    required
                    type="number"
                    name="price"
                    min="0"
                    max="9999"
                    value={formData.price}
                    onChange={handleChange}
                    step="0.01"
                    placeholder="Enter price (CHF)"
                  />
                </div>
              </>
            )}

            <label className="fileLabel topMargArea" htmlFor="file">
              Image to upload
            </label>
            <div className="fileField">
              <label htmlFor="file">
                <img className="imgFile" src={imageFile || ""} alt="File upload" />
                {fileName ? (
                  <div>Uploaded File: {fileName}</div>
                ) : (
                  <>
                    <div className="selectFileTo">Select file to upload</div>
                    <div className="orDragAnd">
                      or drag and drop, copy-paste files
                    </div>
                  </>
                )}
              </label>
              <input
                type="file"
                id="file"
                name="file"
                accept="image/*"
                onChange={handleChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>

            <div className="capability-input">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>Status</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Disabled</Typography>
                  <Switch
                    checked={formData.is_active ? true : false}
                    value={formData.is_active ? true : false}
                    onChange={handleFormSwitchChange}
                    inputProps={{ "aria-label": "status" }}
                  />
                  <Typography>Active</Typography>
                </Stack>
              </Stack>
            </div>

            <div className="actions">
              <button type="submit" onClick={closeModal} className="btn-submit">
                Cancel
              </button>
              <button type="submit" className="btn-submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={zoneModalIsOpen}
        onRequestClose={closeZoneModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Manage Zones"
      >
        <div className="bloc-manage">
          <div className="manage-zones-modal">
            <h2>Manage Zones for {selectedDomain?.title}</h2>

            {/* Assigned Zones Section */}
            <div className="currently-managed">
              <h3>Assigned Zones</h3>
              <div className="tableau-list no-scroll">
                <div className="container">
                  <table>
                    <thead className="no-background">
                      <tr>
                        <th>ID</th>
                        <th>Domain Title</th>
                        <th>Zone Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {assignedZones.map((zone) => (
                        <tr key={zone.id}>
                          <td>{zone.id}</td>
                          <td>{selectedDomain?.title}</td>
                          <td>{zone.name}</td>
                          <td>
                            <button
                              className="form-submit"
                              onClick={() => handleUnassignZone(zone)}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>

            {/* Available Zones Section */}
            <div className="available-zones">
              <h3>Available Zones</h3>
              <div className="bloc-search no-background">
                <div className="container search-center">
                  <div className="search">
                    <div className="text-search">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search by Name"
                        value={searchQuery}
                        onChange={(e) => {
                          const term = e.target.value.toLowerCase();
                          setSearchQuery(e.target.value);
                          if (!term) {
                            setAvailableZones(allZones);
                          } else {
                            const filtered = allZones.filter((zone) =>
                              zone.name.toLowerCase().includes(term)
                            );
                            setAvailableZones(filtered);
                          }
                        }}
                        className="form-text"
                      />
                      <input
                        type="submit"
                        onClick={handleSearch}
                        className="form-submit"
                        value="Search"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tableau-list no-scroll">
                <div className="container">
                  <table>
                    {availableZones.map((zone, index) => (
                      <thead key={index} className="no-background">
                        <tr>
                          <th>{zone.id}</th>
                          <th>{zone.name}</th>
                          <th>
                            <button
                              className="form-submit"
                              onClick={() => handleAssignZone(zone)}
                            >
                              Add
                            </button>
                          </th>
                        </tr>
                      </thead>
                    ))}
                  </table>
                </div>
              </div>
            </div>

            {/* Save Changes Button */}
            <button className="save-submit" onClick={handleSaveZones}>
              Save Changes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Product;
