import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { CiClock2, CiCalendar } from "react-icons/ci";
import BookingService from "../services/booking.service";
import ProductService from "../services/product.service";
import CategoryService from "../services/category.service";
import UserService from "../services/user.service";
import Select from 'react-select';
import cantons from "../shared/data/cantons.json";
import { useNotifications } from '../context/NotificationProvider';
import { formatTimeTo24Hour } from "../shared/utils/string";
import { Map } from "../components/google_map";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import Autocomplete from 'react-google-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import imageFile from "../assets/images/upload-file.svg";
import useAxiosMultiPart from "../hooks/useAxiosMultiPart";
import { PhoneInput } from 'react-international-phone';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
        height: '680px',
    },
};


const customSelectStyles = {
    control: (styles) => ({
        ...styles,
        width: '100%',
        height: '60px',
        padding: '0 15px',
        fontSize: '14px',
        lineHeight: '60px',
        color: '#393D44',
        border: '0',
        borderRadius: '20px',
        background: '#ffffff',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
};

const CreateTaskModal = ({ axiosPrivate }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const axiosMultiPart = useAxiosMultiPart();
    const [timePickerIsOpen, setTimePickerIsOpen] = useState(false);
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [value, setValue] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(new Date());
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [productList, setProductList] = useState([]);
    const [flypros, setFlypros] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [isAddressUpdate, setIsAddressUpdate] = useState(false);
    const [coordinates, setCoordinate] = useState({ lat: 46.356, lng: 6.5641 });
    const [selectedState, setSelectedState] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [selectedFlyproOption, setSelectedFlyproOption] = useState([]);
    const notify = useNotifications();
    const { auth } = useAuth();
    const [isZipCodeVisible, setIsZipCodeVisible] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);
    const [newCustomerId, setNewCustomerId] = useState(null);


    const [formData, setFormData] = useState({
        category_id: "",
        subcategory_id: "",
        products: [null],
        duration: "",
        date: "",
        date_only: "",
        timeBooking: "",
        description: "",
        message: "",
        postcode: "",
        therapist_id: [],
        client_id: "",
        address_id: "",
        status: "CREATED",
        zip_code: "",
        state: "",
        state_code: "",
        street: "",
        street_number: "",
        city: "",
        country: "Switzerland",
        country_code: "CH",
        latitude: null,
        longitude: null,
        hotel_id: auth.user.hotel[0]?.id,
        total_fare: 0,
        subtotal: 0,
    });

    useEffect(() => {
        if (formData.zip_code) {
            const filteredCategories = filterCategoriesByZipCode(categories, formData.zip_code);
            setCategories(filteredCategories);
        }
    }, [formData.zip_code]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk",
        libraries: ['places'],
    });

    const resetForm = () => {
        setFormData({
            category_id: "",
            subcategory_id: "",
            products: [null],
            duration: "",
            date: "",
            date_only: "",
            timeBooking: "",
            description: "",
            message: "",
            postcode: "",
            therapist_id: [],
            client_id: "",
            address_id: "",
            status: "CREATED",
            zip_code: "",
            state: "",
            state_code: "",
            street: "",
            street_number: "",
            city: "",
            country: "Switzerland",
            country_code: "CH",
            latitude: null,
            longitude: null,
            hotel_id: auth.user.hotel[0]?.id,
            total_fare: 0,
            subtotal: 0,
        });
        setSelectedFlyproOption([]);
        setSubCategories([]);
        setProductList([]);
        setCoordinate({ lat: 46.356, lng: 6.5641 });
        setIsAddressUpdate(false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (isAddressUpdate || (formData.zip_code && !formData.latitude)) {
            try {
                await fetchCoordinates();
                setIsAddressUpdate(false);
            } catch (error) {
                notify.error('Error validating address');
                return;
            }
        }

        try {
            //const state = cantons.cantons.find(c => c.name === formData.state);
            const time = formatTimeTo24Hour(formData.timeBooking);
            const combinedDateTime = `${formData.date_only} ${time}`;

            const payload = {
                ...formData,
                date: combinedDateTime,
                start_before: combinedDateTime,
                latitude: coordinates.lat,
                longitude: coordinates.lng,
            };
            console.log("payload", payload)

            const response = await BookingService.store(payload, axiosPrivate);
            if (response.data) {
                closeModal();
            }
        } catch (error) {

        }
    };

    const openModal = async () => {
        resetForm();
        try {
            const [categoriesRes, customersRes] = await Promise.all([
                CategoryService.index({ parent_id: null, status: 'ACTIVE', with: 'domain,domain.zones' }, axiosPrivate),
                UserService.indexByRole({ with: 'primary_address' }, 'ROLE_CLIENT', axiosPrivate)
            ]);
            setCategories(categoriesRes);
            setCustomers(customersRes);
            setIsOpen(true);
        } catch (error) {
            notify.error('Error initializing form');
        }
    };

    const closeModal = () => {
        resetForm();
        setIsOpen(false);
    };

    // Handle category change
    const handleCategorySelectChange = async (selected) => {
        const categoryId = selected ? selected.value : '';
        setFormData(prev => ({ ...prev, category_id: categoryId, subcategory_id: '', products: [null] }));

        if (categoryId !== '') {
            try {
                const response = await CategoryService.index({ parent_id: categoryId, status: 'ACTIVE' }, axiosPrivate);
                setSubCategories(response);
                const products = await ProductService.index({ category_id: categoryId, status: 'ACTIVE' }, axiosPrivate);
                setProductList(products);
            } catch (error) {

            }
        } else {
            setSubCategories([]);
        }
    };

    // Handle subcategory change
    const handleSubcategorySelectChange = async (selected) => {
        const subcatId = selected ? selected.value : '';
        setFormData(prev => ({ ...prev, subcategory_id: subcatId, products: [null] }));

        if (subcatId !== '') {
            try {
                const response = await ProductService.index({ category_id: subcatId }, axiosPrivate);
                setProductList(response);
            } catch (error) {

            }
        } else {
            setProductList([]);
        }
    };

    // Handle product change
    const handleProductSelectChange = (selected) => {
        let products = formData.products;
        products[0] = selected ? selected.value : null;
        setFormData(prev => ({
            ...prev,
            products: products,
            duration: selected ? selected.duration : 0,
            total_fare: selected ? selected.price : 0,
            subtotal: selected ? selected.price : 0,

        }));
    };

    // Handle flypro change
    const handleFlyproChange = (selected) => {
        console.log({ selected })
        if (selected.length > 2) {
            return;
        }
        if (selected?.length) {
            setSelectedFlyproOption(selected);
            setFormData((prev) => ({
                ...prev,
                therapist_id: selected.map((therapist) => therapist.value)
            }));
        } else {
            setSelectedFlyproOption([]);

            setFormData((prev) => ({
                ...prev,
                therapist_id: []
            }));
        }
    };

    // Handle Customer change
    const handleCustomerChange = async (selected) => {
        // let client = customers.find((customer) => customer.id === selected?.value);
        let client = await UserService.index(
            { id: selected?.value, with: 'primary_address' },
            axiosPrivate
        );
        console.log('customers', customers)
        console.log('client', client)
        console.log('client', client[0].primary_address)
        console.log('customer saved id', selected?.value)
        if (client && client[0].primary_address) {
            setFormData((prev) => ({
                ...prev,
                zip_code: client[0].primary_address?.zip_code,
                state: client[0].primary_address?.state,
                state_code: client[0].primary_address?.state_code,
                street: client[0].primary_address?.street,
                street_number: client[0].primary_address?.street_number,
                city: client[0].primary_address?.city,
                latitude: client[0].primary_address?.latitude,
                longitude: client[0].primary_address?.longitude,
                address_id: client[0].primary_address?.id,
                client_id: selected ? selected.value : ''
            }));
            setSelectedState({ value: client[0].primary_address.state, label: client[0].primary_address.state })
            setCoordinate({ lat: client[0].primary_address?.latitude, lng: client[0].primary_address?.longitude })
        } else {
            setFormData((prev) => ({
                ...prev,
                zip_code: "",
                state: "",
                state_code: "",
                street: "",
                street_number: "",
                city: "",
                latitude: "",
                address_id: null,
                longitude: "",
                client_id: selected ? selected.value : ''
            }));
        }
    };

    // Create options for Category, Subcategory, Product, Flypro
    const categoryOptions = categories?.length > 0 ? categories.map(row => ({ value: row.id, label: row.title })) : [];
    const subcategoryOptions = subCategories?.length > 0 ? subCategories.map(row => ({ value: row.id, label: row.title })) : [];
    const productOptions = productList?.length > 0 ? productList.map(row => ({ value: row.id, label: row.name, duration: row.duration, price: row.price })) : [];
    const flyproOptions = flypros?.length > 0 ? flypros.map(row => ({ value: row.id, label: row.extra?.full_name })) : [];
    const customerOptions = customers?.length > 0 ? customers.map(row => ({ value: row.id, label: row.extra?.full_name })) : [];

    // Determine currently selected options
    const selectedCategoryOption = categoryOptions.find(o => o.value === formData.category_id) || null;
    const selectedSubcategoryOption = subcategoryOptions.find(o => o.value === formData.subcategory_id) || null;
    const selectedProductOption = productOptions.find(o => o.value === formData.products[0]) || null;
    // const selectedFlyproOption = flyproOptions.find(option => formData.therapist_id.includes(option.value)) || null;
    // const selectedCustomerOption = customerOptions.find(option => option.value === formData.client_id) || null;
    const selectedCustomerOption = customerOptions.find(
        option => option.value === (newCustomerId || formData.client_id)
    ) || null;

    // Fonction pour mettre à jour les valeurs du formulaire

    const handleChange = async (e) => {

        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

    };

    const fetchCoordinates = async () => {
        const { street_number, street, city, state, zip_code } = formData;
        const address = `${street_number} ${street}, ${city}, ${state} ${zip_code}`;

        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json`,
                {
                    params: {
                        address,
                        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                    },
                }
            );

            if (response.data.results.length > 0) {
                const location = response.data.results[0].geometry.location;
                setFormData((prev) => ({
                    ...prev,
                    latitude: location.lat,
                    longitude: location.lng,
                }));
                setCoordinate({
                    lat: location.lat,
                    lng: location.lng,
                });
            } else {
                console.error('No results found for the given address.');
            }
        } catch (error) {
            console.error('Error fetching coordinates:', error);
        }
    };

    const handleShowMap = async () => {
        await fetchCoordinates();
        setShowMap(true);
    };

    const fetchAvailableTherapists = async () => {
        try {
            console.log({ date: formData.date, time: formData.timeBooking, latitude: formData.latitude, longitude: formData.longitude })
            const flypro = await UserService.fetchAvailableTherapists({ is_hotel: true, date: formData.date_only, time: formatTimeTo24Hour(formData.timeBooking), duration: Number(formData.duration), lat: formData.latitude, lng: formData.longitude, zip_code: formData.zip_code }, null, axiosPrivate);
            setFlypros(flypro);
        } catch (error) {

        }
    }

    const loadTherapist = async () => {
        if (formData.longitude && formData.latitude && formData.duration && formData.date_only && formData.timeBooking && formData.state) {
            await fetchAvailableTherapists();
        } else {
            notify.warn("Missing Data to get available therapists")
        }
    }

    const handleCoordinateChange = (coordinates) => {
        setFormData({
            ...formData,
            longitude: coordinates[0]?.lng,
            latitude: coordinates[0]?.lat,
        })
        setCoordinate({
            lat: coordinates[0]?.lng,
            lng: coordinates[0]?.lat,
        })
    }

    const handleStateChange = (data) => {
        let selected = cantons.cantons.find(
            (canton) => canton.name === data?.value
        );
        document.querySelector('input[name="zip_code"]').setAttribute('placeholder', selected?.zip_code_range || '');

        setFormData((prev) => ({
            ...prev,
            zip_code: '',
            state: data?.value,
        }));

        setSelectedState(data);
    };

    const handlePlaceSelect = (place) => {
        if (!place || !place.address_components) {
            console.error("Invalid place selected");
            return;
        }

        let updatedAddress = {
            street: '',
            street_number: '',
            city: '',
            state: '',
            state_code: '',
            zip_code: '',
            country: 'Switzerland',
            country_code: 'CH',
            latitude: place.geometry?.location?.lat() || null,
            longitude: place.geometry?.location?.lng() || null,
        };

        place.address_components.forEach((component) => {
            const type = component.types[0];
            switch (type) {
                case "street_number":
                    updatedAddress.street_number = component.long_name;
                    break;
                case "route":
                    updatedAddress.street = component.long_name;
                    break;
                case "locality":
                    updatedAddress.city = component.long_name;
                    break;
                case "postal_code":
                    updatedAddress.zip_code = component.long_name;
                    break;
                case "administrative_area_level_1":
                    updatedAddress.state = component.long_name;
                    updatedAddress.state_code = component.short_name;
                    break;
            }
        });

        setFormData((prev) => ({
            ...prev,
            ...updatedAddress,
        }));

        // 🔹 Force update coordinates
        setCoordinate({
            lat: updatedAddress.latitude,
            lng: updatedAddress.longitude,
        });

        console.log("Updated Coordinates:", updatedAddress.latitude, updatedAddress.longitude);
        setShowMap(true); // 🔹 Ensure the map shows up
    };
    const filterCategoriesByZipCode = (categories, selectedZipCode) => {
        return categories.filter(category => {
            const domainZones = category.domain?.zones || [];
            return domainZones.some(zone => {
                const [startZip, endZip] = zone.zip_code.split('-').map(Number);
                const zipCodeNum = Number(selectedZipCode);
                return zipCodeNum >= startZip && zipCodeNum <= endZip;
            });
        });
    };

    const handlePlaceSelectClient = (place) => {
        if (!place || !place.address_components) {
            console.error("Invalid place selected");
            return;
        }

        let updatedAddress = {
            street: '',
            street_number: '',
            city: '',
            state: '',
            state_code: '',
            zip_code: '',
            country: 'Switzerland',
            country_code: 'CH',
            latitude: place.geometry?.location?.lat() || null,
            longitude: place.geometry?.location?.lng() || null,
        };

        place.address_components.forEach((component) => {
            const type = component.types[0];
            switch (type) {
                case "street_number":
                    updatedAddress.street_number = component.long_name;
                    break;
                case "route":
                    updatedAddress.street = component.long_name;
                    break;
                case "locality":
                    updatedAddress.city = component.long_name;
                    break;
                case "postal_code":
                    updatedAddress.zip_code = component.long_name;
                    break;
                case "administrative_area_level_1":
                    updatedAddress.state = component.long_name;
                    updatedAddress.state_code = component.short_name;
                    break;
            }
        });


        setformDataAdd((prev) => ({
            ...prev,
            ...updatedAddress,
        }));

        // 🔹 Force update coordinates
        setCoordinate({
            lat: updatedAddress.latitude,
            lng: updatedAddress.longitude,
        });

        console.log("Updated Coordinates:", updatedAddress.latitude, updatedAddress.longitude);
        setShowMap(true); // 🔹 Ensure the map shows up
    };
    const [formDataAdd, setformDataAdd] = useState({
        id: null,
        civility: null,
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        birthdate: "",
        zip_code: "",
        state: "",
        state_code: "",
        street: "",
        street_number: "",
        city: "",
        country: "Switzerland",
        country_code: "CH",
        role: "ROLE_CLIENT",
        latitude: null,
        longitude: null
    });
    function openModalAdd(customer) {
        if (customer && customer?.id) {
            let phone = customer.phone;
            if (phone && phone.startsWith("0")) {
                phone = "+41" + phone.substring(1);
            }

            setFormData({
                ...formData,
                ...customer,
                ...{
                    primary_address_id: customer.primary_address?.id,
                    zip_code: customer.primary_address?.zip_code,
                    state: customer.primary_address?.state,
                    state_code: customer.primary_address?.state_code,
                    street: customer.primary_address?.street,
                    street_number: customer.primary_address?.street_number,
                    city: customer.primary_address?.city,
                    country: customer.primary_address?.country,
                    country_code: customer.primary_address?.country_code,
                    longitude: customer.primary_address?.longitude,
                    latitude: customer.primary_address?.latitude,
                    phone
                },
            });
            if (customer.primary_address?.state) {
                setSelectedState({
                    value: customer.primary_address?.state,
                    label: customer.primary_address?.state
                })
            }
            if (customer.primary_address?.longitude) {
                setCoordinate({
                    lat: customer.primary_address?.latitude,
                    lng: customer.primary_address?.longitude,
                })
            }
            setFile(null);
            setFileName("");

        }
        setShowMap(false);
        setModalAddOpen(true);
    }

    const handleChangeAdd = async (e) => {

        const updatedCivility = e.target.name === 'civility' ? (formDataAdd.civility === e.target.value ? "" : e.target.value) : formDataAdd.civility;

        const updatedformDataAdd = {
            ...formDataAdd,
            [e.target.name]: e.target.value,
            civility: updatedCivility,
        };

        if (formDataAdd.id && e.target.name === 'zip_code' || e.target.name === 'street' || e.target.name === 'street_number' || e.target.name === 'city') {
            setIsAddressUpdate(true);
        }

        // Handle file input
        if (e.target.name === "file") {
            console.log({ e });
            const file = e.target.files[0];
            if (file) {
                setFileName(file.name);
                setFile(file);
            }
        } else {
            setformDataAdd({
                ...formDataAdd,
                [e.target.name]: e.target.value,
            });
        }

        setformDataAdd({
            ...formDataAdd,
            [e.target.name]: e.target.value,
        });

        setformDataAdd(updatedformDataAdd);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        formDataAdd.birthdate = formDataAdd.birthdate === '' ? null : formDataAdd.birthdate;
        formDataAdd.role = 'ROLE_CLIENT';
        formDataAdd.country = "Switzerland";
        formDataAdd.country_code = "CH";

        const formDataAddToSend = new FormData();

        for (const key in formDataAdd) {
            const value = formDataAdd[key];
            if (value) {
                if (Array.isArray(value) || typeof value === 'object') {
                    formDataAddToSend.append(key, JSON.stringify(value));
                } else if (typeof value === 'boolean') {
                    formDataAddToSend.append(key, Number(value)); // Convert boolean to string
                } else {
                    formDataAddToSend.append(key, value);
                }
            }
        }

        if (file) {
            formDataAddToSend.append("imageFile", file);
        }

        try {
            const response = await (formDataAdd.id ? UserService.updateWithImage(formDataAdd.id, file ? formDataAddToSend : formDataAdd, file ? axiosMultiPart : axiosPrivate) : UserService.store(file ? formDataAddToSend : formDataAdd, file ? axiosMultiPart : axiosPrivate));
            if (response.data) {
                console.log("dd", response.data.id)
                setNewCustomerId(response.data.id)
                // const updatedCustomers = await UserService.indexByRole(
                //     { with: 'primary_address' },
                //     'ROLE_CLIENT',
                //     axiosPrivate
                // );
                // setCustomers(updatedCustomers);
                closeModalAdd();
                openModal();
                await handleCustomerChange({ value: response.data.id });
                setShowMap(false);
            }
        } catch (e) {
            // errors handled in interceptor
        }
    };
    function closeModalAdd() {
        setformDataAdd({
            id: null, //
            civility: null,
            firstname: "",
            lastname: "",
            phone: "",
            email: "",
            birthdate: "",
            zip_code: "",
            state: "",
            state_code: "",
            street: "",
            street_number: "",
            city: "",
            country: "Switzerland",
            country_code: "CH",
            role: "ROLE_CLIENT",
            latitude: null,
            longitude: null
        });
        setCoordinate({
            lat: 46.356,
            lng: 6.5641
        })
        setIsAddressUpdate(false);
        setModalAddOpen(false);
        setSelectedState(null);
    }
    const handlePhoneChange = (value) => {
        setformDataAdd((prevFormData) => ({
            ...prevFormData,
            phone: value,
        }));
    };

    return (
        <>
            <button onClick={openModal} className="btn-add-client">
                Create a task
            </button>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add a Booking"
            >
                <button className="close-button" onClick={closeModal}>
                    <span className="close-icon">✖</span>
                </button>

                <h2>Create a task</h2>
                <p>If you need more info, please check out</p>
                <div className="form-inscription">
                    <form onSubmit={handleFormSubmit}>
                        <div className="form-text">
                            <label>Customer</label>
                            <Select
                                value={selectedCustomerOption}
                                onChange={handleCustomerChange}
                                options={customerOptions}
                                styles={customSelectStyles}
                                required
                                isClearable
                                isSearchable
                                placeholder="Select a Customer"
                                // noOptionsMessage={() => "No Customers available"}
                                noOptionsMessage={() => (
                                    <div style={{ textAlign: "center" }}>
                                        <p>No Customers available</p>
                                        <button onClick={openModalAdd} style={{ padding: "5px 10px", cursor: "pointer" }}>
                                            Add Customer
                                        </button>
                                    </div>
                                )}
                            />

                        </div>
                        <div className="flex-row gap-10">
                            <div className="form-text">
                                <label htmlFor="street">Saisissez une adresse</label>
                                <Autocomplete
                                    apiKey="AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk"
                                    onPlaceSelected={handlePlaceSelect}
                                    options={{
                                        types: ["address"],
                                        componentRestrictions: { country: "CH" },
                                    }}
                                    style={{
                                        height: "60px",
                                        padding: "0 15px",
                                        border: "0",
                                        borderRadius: "20px",
                                        boxShadow: "0px 8px 50px 0px rgba(0, 0, 0, 0.06)",
                                    }}
                                    defaultValue={
                                        formData.street
                                            ? `${formData.street_number} ${formData.street}, ${formData.city}, ${formData.state} ${formData.zip_code}`
                                            : ""
                                    }
                                    className={`address-input ${isZipCodeVisible ? "compact" : ""}`}
                                />
                            </div>

                            {isZipCodeVisible && (
                                <div className="form-group zip-code-input">
                                    <label>ZIP Code</label>
                                    <input
                                        type="text"
                                        value={formData.zip_code}
                                        onChange={(e) => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                zip_code: e.target.value,
                                            }));
                                        }}
                                        required
                                    />
                                </div>
                            )}

                            <div className="form-group icon-top-margin">
                                {formData.zip_code && (
                                    <img
                                        src="assets/images/map-icon.svg"
                                        alt="Map Icon"
                                        className="clickable"
                                        onClick={handleShowMap}
                                    />
                                )}
                            </div>
                        </div>
                        <div>
                            {formData.latitude && showMap && (<Map
                                coordinates={[coordinates]}
                                mode="edit"
                                containerStyle={{ width: '100%', height: '400px' }}
                                mapOptions={{
                                    disableDefaultUI: true,
                                    zoomControl: true,
                                    mapTypeControl: false,
                                    fullscreenControl: true,
                                    streetViewControl: false,
                                }}
                                onCoordinateChange={handleCoordinateChange}
                            />)}
                        </div>
                        <div className="form-text">
                            <label>Category*</label>
                            <Select
                                value={selectedCategoryOption}
                                onChange={handleCategorySelectChange}
                                options={categoryOptions}
                                styles={customSelectStyles}
                                isClearable
                                required
                                isSearchable
                                placeholder="Select a category"
                                noOptionsMessage={() => "No categories available"}
                            />
                        </div>

                        <div className="form-text">
                            <label>Subcategory</label>
                            <Select
                                value={selectedSubcategoryOption}
                                onChange={handleSubcategorySelectChange}
                                options={subcategoryOptions}
                                styles={customSelectStyles}
                                isClearable
                                isSearchable
                                placeholder="Select a Subcategory"
                                noOptionsMessage={() => "No Subcategories available"}
                                isDisabled={!formData.category_id} // disable if no category selected
                            />
                        </div>

                        <div className="form-text">
                            <label>Product*</label>
                            <Select
                                value={selectedProductOption}
                                onChange={handleProductSelectChange}
                                options={productOptions}
                                styles={customSelectStyles}
                                required
                                isClearable
                                isSearchable
                                placeholder="Select a product"
                                noOptionsMessage={() => "No products available"}
                                isDisabled={!formData.category_id} // disable if no subcategory selected
                            />
                        </div>

                        <div className="form-text">
                            <label>Duration</label>
                            <input type="number" readOnly name="duration" value={formData.duration} onChange={(e) => setFormData({ ...formData, duration: e.target.value })} />
                        </div>

                        <div className="dates">
                            <div className="form-text dateOfTheBookingParent topMarg">
                                <label>Date of the booking</label>

                                <input
                                    type="date"
                                    name="date_only"
                                    value={formData.date_only}
                                    onChange={(e) => setFormData({ ...formData, date_only: e.target.value })}
                                />
                            </div>

                            <div className="form-text timeOfTheBookingParent topMarg">
                                <label>Time of the booking</label>
                                <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                                    <input
                                        type="time"
                                        value={formData.timeBooking}
                                        onChange={(e) => setFormData(prev => ({ ...prev, timeBooking: e.target.value }))}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-text topMargArea">
                            <label>Description</label>
                            <textarea className="formTextArea" name="description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                        </div>

                        <div className="form-text topMargArea">
                            <label>Comment</label>
                            <textarea className="formTextArea" name="message" value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
                        </div>

                        <div className="form-text" onClick={loadTherapist}>
                            <label>Flypro</label>
                            <Select
                                value={selectedFlyproOption}
                                onChange={handleFlyproChange}
                                options={flyproOptions}
                                styles={customSelectStyles}
                                isClearable
                                required
                                isSearchable
                                isMulti
                                placeholder="Select a Flypro"
                                noOptionsMessage={() => "No Flypros available"}
                            />
                        </div>

                        {/* <div className="form-text">
              <label>Promo CODE</label>
              <input type="text" name="promoCode" value={formData.promoCode} onChange={(e) => setFormData({ ...formData, promoCode: e.target.value })} />
            </div> */}
                        <button type="submit" className="btn-submit">
                            Create Task
                        </button>
                    </form>
                </div>
            </Modal>
            <Modal
                isOpen={modalAddOpen}
                onRequestClose={closeModalAdd}
                ariaHideApp={false}

                style={customStyles}
            >
                <button className="close-button" onClick={closeModalAdd}>
                    <span className="close-icon">✖</span>
                </button>

                <h2>Add a customer</h2>
                <div className="form-inscription">
                    <form onSubmit={handleSubmit}>
                        <div className="form-choix">
                            <div className="form-radio">
                                <input
                                    type="checkbox"
                                    name="civility"
                                    value="MS"
                                    id="madame"
                                    checked={formDataAdd.civility === "MS"}
                                    onChange={handleChangeAdd}
                                />
                                <label htmlFor="madame">Ms</label>
                            </div>
                            <div className="form-radio">
                                <input
                                    type="checkbox"
                                    name="civility"
                                    value="MR"
                                    id="monsieur"
                                    checked={formDataAdd.civility === "MR"}
                                    onChange={handleChangeAdd}
                                />
                                <label htmlFor="monsieur">Mr</label>
                            </div>
                        </div>
                        <div className='flex-row gap-10'>
                            <div className="form-text">
                                <label>Firstname</label>
                                <input required value={formDataAdd.firstname} type="text" name="firstname" onChange={handleChangeAdd} />
                            </div>

                            <div className="form-text">
                                <label>Lastname</label>
                                <input required value={formDataAdd.lastname} type="text" name="lastname" onChange={handleChangeAdd} />
                            </div>
                        </div>

                        <div className="form-text">
                            <label>Phone</label>
                            <PhoneInput
                                defaultCountry="ch"
                                type="tel"
                                name="phone"
                                value={formDataAdd.phone}
                                disableCountryGuess="true"
                                //hideDropdown="true"
                                forceDialCode="true"
                                // onChange={handlePhoneChange}
                                onChange={(phone) => handlePhoneChange(phone)}
                                required
                            />
                        </div>

                        <div className="form-text">
                            <label>Email Address</label>
                            <input required value={formDataAdd.email} type="email" name="email" onChange={handleChangeAdd} />
                        </div>

                        {/* <div className="form-text">
                                            <label>Birthdate</label>
                                            <input
                                                type="date"
                                                name="birthdate"
                                                value={formDataAdd.birthdate}
                                                onChange={handleChangeAdd}
                                                max={calculateMinDate()} // Date maximum permise (il y a 18 ans)
                                            />
                                        </div> */}
                        <div className="flex-row gap-10">
                            <div className="form-text">
                                <label htmlFor="street">Select address</label>
                                <Autocomplete
                                    apiKey="AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk"
                                    onPlaceSelected={handlePlaceSelectClient}
                                    options={{
                                        types: ["address"],
                                        componentRestrictions: { country: "CH" },
                                    }}
                                    style={{
                                        height: "60px",
                                        padding: "0 15px",
                                        border: "0",
                                        borderRadius: "20px",
                                        boxShadow: "0px 8px 50px 0px rgba(0, 0, 0, 0.06)",
                                    }}
                                    defaultValue={
                                        formDataAdd.street
                                            ? `${formDataAdd.street_number} ${formDataAdd.street}, ${formDataAdd.city}, ${formDataAdd.state} ${formDataAdd.zip_code}`
                                            : ""
                                    }
                                    className={`address-input ${isZipCodeVisible ? "compact" : ""}`}
                                />
                            </div>

                            {isZipCodeVisible && (
                                <div className="form-group zip-code-input">
                                    <label>ZIP Code</label>
                                    <input
                                        type="text"
                                        value={formDataAdd.zip_code}
                                        onChange={(e) =>
                                            setformDataAdd((prev) => ({
                                                ...prev,
                                                zip_code: e.target.value,
                                            }))
                                        }
                                        required
                                    />
                                </div>
                            )}

                            <div className="form-group icon-top-margin">
                                {formDataAdd.zip_code && (
                                    <img
                                        src="assets/images/map-icon.svg"
                                        alt="Map Icon"
                                        className="clickable"
                                        onClick={handleShowMap}
                                    />
                                )}
                            </div>
                        </div>

                        {formDataAdd.latitude && showMap && (
                            <Map
                                coordinates={[coordinates]}
                                mode="edit"
                                containerStyle={{ width: '100%', height: '400px' }}
                                mapOptions={{
                                    disableDefaultUI: true,
                                    zoomControl: true,
                                    mapTypeControl: false,
                                    fullscreenControl: true,
                                    streetViewControl: false,
                                }}
                                onCoordinateChange={handleCoordinateChange}
                            />
                        )}

                        {/* <label className="fileLabel" htmlFor="file">
                                            Image to upload
                                        </label>
                                        <div className="fileField">
                                            <label htmlFor="file">
                                                <img className="imgFile" src={imageFile || ""} alt="File upload" />
                                                {fileName ? (
                                                    <div>Uploaded File: {fileName}</div>
                                                ) : (
                                                    <>
                                                        <div className="selectFileTo">Select file to upload</div>
                                                        <div className="orDragAnd">
                                                            or drag and drop, copy-paste files
                                                        </div>
                                                    </>
                                                )}
                                            </label>
                                            <input
                                                type="file"
                                                id="file"
                                                name="file"
                                                accept="image/*"
                                                onChange={handleChangeAdd}
                                                ref={fileInputRef}
                                                style={{ display: "none" }}
                                            />
                                        </div> */}
                        <button type="submit" className="btn-submit">
                            {formDataAdd.id ? "Update" : "Create"}
                        </button>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default CreateTaskModal;