import React, { useState, useEffect, useRef } from "react";
import MenuHeader from "./MenuHeader";
import Modal from "react-modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Swal from "sweetalert2";
import { endpoints } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ZoneService from "../services/zone.service";
import CalendarService from "../services/calendar.service";
import UserService from "../services/user.service";
import UnavailableZoneService from "../services/unavailable_zones.service";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import GeneralSettingService from "../services/general_setting.service";
import { asTitle } from "../shared/utils/string";
import useAuth from "../hooks/useAuth";
import { useNotifications } from "../context/NotificationProvider";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "40rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden auto",
    scrollbarWidth: "none",
  },
};

const Management = () => {
  Modal.setAppElement("#root");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [zones, setZones] = useState([]);
  const [unavailablezones, setunavailablezones] = useState([]);
  const [generalSettings, setGeneralSettings] = useState([]);
  const openModal = () => setIsOpen(true);
  const axiosPrivate = useAxiosPrivate();
  const [newZone, setNewZone] = useState({
    id: null,
    name: "",
    zip_code: "",
    is_active: true,
  });
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  });
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [initialUserData, setInitialUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });

  const [generalSettingData, setGeneralSettingData] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [userId, setUserId] = useState(null);
  const [hotelId, sethotelId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(0);
  const [userRoles, setUserRoles] = useState([]);
  const [availabilityModalOpen, setAvailabilityModalOpen] = useState(false);
  const [availabilities, setAvailabilities] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const { auth } = useAuth();
  const notify = useNotifications();

  // Fetch the logged-in user's profile
  const fetchUserProfile = async () => {
    try {
      const response = await UserService.currentUser({ with: 'roles' }, axiosPrivate);
      const user = response.user;
      const user_role = user.roles;
      setUserRoles(user_role);
      setUserId(user.id)
      setInitialUserData({
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        email: user.email || "",
        phone: user.phone || "",
      });
      setUserData({
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        email: user.email || "",
        phone: user.phone || "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  // Check if form data is modified
  const isFormModified = () => {
    return (
      userData.firstname !== initialUserData.firstname ||
      userData.lastname !== initialUserData.lastname ||
      userData.email !== initialUserData.email ||
      userData.phone !== initialUserData.phone
    );
  };

  const fetchAvailabilities = async (day) => {
    try {
      const response = await CalendarService.index(
        { hotel_id: hotelId, day_of_week: day },
        axiosPrivate
      );

      if (response && response.length > 0) {
        const flattenedAvailabilities = response
          .map((item) => ({
            id: item.id,
            day_of_week: item.day_of_week,
            hotel_id: item.hotel_id,
            start_time: item.availability?.[0]?.start_time,
            end_time: item.availability?.[0]?.end_time,
          }))
          .filter((availability) => availability.start_time)
          .sort((a, b) => a.start_time.localeCompare(b.start_time));

        setAvailabilities((prev) => ({
          ...prev,
          [day]: flattenedAvailabilities,
        }));
      } else {
        setAvailabilities((prev) => ({
          ...prev,
          [day]: [],
        }));
      }
    } catch (error) {

    }
  };

  const openAvailabilityModal = async (day) => {
    setSelectedDay(day);
    setAvailabilityModalOpen(true);

    await fetchAvailabilities(day);
  };

  const closeAvailabilityModal = () => {
    setAvailabilityModalOpen(false);
    setStartTime("");
    setEndTime("");
    setEditIndex(null);
  };

  const addAvailability = async () => {
    if (!startTime || !endTime) {
      notify.warn("Please select a valid date range");
      return;
    }

    const newAvailability = { start_time: startTime, end_time: endTime };
    const payload = {
      day_of_week: selectedDay,
      hotel_id: auth?.user?.hotel?.[0]?.id,
      availability: [newAvailability],
    };

    try {
      if (editIndex !== null) {
        const updatedAvailability = availabilities[selectedDay][editIndex];
        await CalendarService.update(updatedAvailability.id, payload, axiosPrivate);
      } else {
        await CalendarService.store(payload, axiosPrivate);
      }

      await fetchAvailabilities(selectedDay);
      setStartTime("");
      setEndTime("");
      setEditIndex(null);
    } catch (error) {

    }
  };

  const deleteAvailability = async (index) => {
    const availabilityToDelete = availabilities[selectedDay][index];
    try {
      await CalendarService.delete(availabilityToDelete.id, axiosPrivate);

      setAvailabilities((prev) => ({
        ...prev,
        [selectedDay]: prev[selectedDay].filter((_, i) => i !== index),
      }));
    } catch (error) {

    }
  };

  const editAvailability = (index) => {
    const availabilityToEdit = availabilities[selectedDay][index];
    setStartTime(availabilityToEdit.start_time);
    setEndTime(availabilityToEdit.end_time);
    setEditIndex(index);
  };

  useEffect(() => {
    if (auth?.user?.hotel?.length > 0) {
      sethotelId(auth.user.hotel[0].id);
    }
  }, [auth]);

  const handleEditProfileClick = () => {
    setIsEditingProfile(true);
    setIsEditingPassword(false);
  };

  // Handle cancel action
  const handleCancelEdit = () => {
    setUserData(initialUserData);
    setIsEditingProfile(false);
    isFormModified(false);
  };

  // Update user's profile information
  const updateProfile = async (e) => {
    e.preventDefault();
    try {
      const updatedUser = { ...userData };
      await UserService.update(userId, updatedUser, axiosPrivate);
      setInitialUserData({ ...userData });
      setIsEditingProfile(false);
      isFormModified(false);
    } catch (error) {

    }
  };

  const handleEditPasswordClick = () => {
    setIsEditingPassword(true);
    setIsEditingProfile(false);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (userData.newPassword !== userData.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "New passwords do not match!",
      });
      return;
    }

    const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRules.test(userData.newPassword)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character.",
      });
      return;
    }

    try {
      const response = await axiosPrivate.post(endpoints.UpdateUserPassword, {
        currentPassword: userData.currentPassword,
        newPassword: userData.newPassword,
        confirmPassword: userData.confirmPassword,
      });

      if (response.data.status === false) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.response,
        });
        return;
      }

      Swal.fire({
        icon: "success",
        title: "Password updated successfully!",
        showConfirmButton: false,
        timer: 1500,
      });

      // Reset only the password-related fields, keeping profile data intact
      setUserData((prevData) => ({
        ...prevData,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      }));

      setIsEditingPassword(false);
    } catch (error) {
      console.error("Error updating password:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred!",
      });
    }
  };

  const handleTimeDecrement = (event) => {
    const id = event.target.id.split('decrement-')[1];
    setGeneralSettingData(prevSettings =>
      prevSettings.map(setting =>
        setting.id == id && setting.value > 0
          ? { 
              ...setting, 
              value: setting.setting === 'surcharge' ? setting.value - 1 : setting.value - 5, 
              updated: true 
            }
          : setting
      )
    );
  };

  const handleTimeIncrement = (event) => {
    const id = event.target.id.split('increment-')[1];
    setGeneralSettingData(prevSettings =>
      prevSettings.map(setting =>
        setting.id == id
          ? { 
              ...setting, 
              value: setting.setting === 'surcharge' ? setting.value + 1 : setting.value + 5, 
              updated: true 
            }
          : setting
      )
    );
  };

  const handleCancelSettingsEdit = () => {
    setGeneralSettingData(generalSettings.map(general_setting => {
      return {
        id: general_setting.id,
        setting: general_setting.setting,
        value: Number(general_setting.value),
        updated: false
      }
    }));
  }

  const updateSettings = (e) => {
    e.preventDefault();
    generalSettingData.forEach(setting => {
      if (setting.updated) {
        try {
          GeneralSettingService.update(setting.id, setting, axiosPrivate);
          setting.updated = false;
        } catch (e) {

        }
      }
    });
  }
  // Organize zones into hierarchy based on zip code ranges
  const organizeZonesHierarchy = (flatZones) => {
    const processedZones = flatZones.map(zone => {
      const zipRanges = parseZipRanges(zone.zip_code);
      return {
        ...zone,
        ranges: zipRanges,
        children: []
      };
    });

    return processedZones.filter(zone => {
      let isChild = false;
      processedZones.forEach(potentialParent => {
        if (zone.id !== potentialParent.id && isZipInRange(zone.ranges, potentialParent.ranges)) {
          potentialParent.children.push(zone);
          isChild = true;
        }
      });
      return !isChild;
    });
  };

  // Parse zip code string into ranges
  const parseZipRanges = (zipString) => {
    const ranges = [];
    const parts = zipString.split(',').map(p => p.trim());

    parts.forEach(part => {
      if (part.includes('-')) {
        const [start, end] = part.split('-').map(Number);
        ranges.push({ start, end });
      } else {
        const num = Number(part);
        ranges.push({ start: num, end: num });
      }
    });
    return ranges;
  };

  // Check if one range falls within another
  const isZipInRange = (childRanges, parentRanges) => {
    return childRanges.every(childRange =>
      parentRanges.some(parentRange =>
        childRange.start >= parentRange.start && childRange.end <= parentRange.end
      )
    );
  };

  const fetchZones = async () => {

    try {
      const response = await ZoneService.index(null, axiosPrivate);
      let zonesData = [];
      if (response) {
        zonesData = response;
      } else {
        console.log("Unexpected response structure:", response);
        zonesData = [];
      }

      const sortedZones = zonesData.sort((a, b) => a.name?.localeCompare(b?.name));
      const organizedZones = organizeZonesHierarchy(sortedZones);
      setZones(organizedZones);
    } catch (error) {
      console.error("Error fetching zones:", error);
      console.error("Full error object:", JSON.stringify(error, null, 2));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred!",
      });
    } finally {

    }
  };
  const fetchUnavailableZones = async () => {

    try {
      const response = await UnavailableZoneService.index(null, axiosPrivate);
      let unavailablezonesData = [];
      console.log("unvai data", response)
      if (response) {
        unavailablezonesData = response;
        setunavailablezones(unavailablezonesData);
      }
    } catch (error) {
      console.error("Error fetching unavailablezones:", error);
      console.error("Full error object:", JSON.stringify(error, null, 2));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred!",
      });
    } finally {

    }
  };
  const fetchGeneralSettings = async () => {

    try {
      const response = await GeneralSettingService.index('setting=in:buffer_before,buffer_after,surcharge', axiosPrivate);
      setGeneralSettings(response);
      setGeneralSettingData(response.map(general_setting => {
        return {
          id: general_setting.id,
          setting: general_setting.setting,
          value: Number(general_setting.value),
          updated: false
        }
      }));
    } catch (error) {

    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if (tab === "zone-management") {
      setTabIndex(1);
    } else if (tab === "unavailable-zones") {
      setTabIndex(2);
    } else if (tab === "hotel-availability") {
      setTabIndex(1);
    } else if (tab === "general-settings") {
      setTabIndex(3);
    } else {
      setTabIndex(0);
    }

    fetchGeneralSettings();
    fetchZones();
    fetchUnavailableZones();
    fetchUserProfile();
  }, [location]);

  const closeModal = () => {
    setIsOpen(false);
    setNewZone({
      id: null,
      name: "",
      zip_code: "",
      is_active: true,
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewZone((prevZone) => ({
      ...prevZone,
      [name]: type === "checkbox" ? checked : value,
    }));

    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  // Validate ZIP code format
  const validateZipFormat = (zipString) => {
    const cleaned = zipString.replace(/\s/g, '');
    const parts = cleaned.split(',');

    for (const part of parts) {
      if (part.includes('-')) {
        const [start, end] = part.split('-');
        if (!start || !end || isNaN(start) || isNaN(end) ||
          parseInt(start) >= parseInt(end)) {
          return false;
        }
      } else {
        if (isNaN(part)) {
          return false;
        }
      }
    }
    return true;
  };

  // Create zone with API integration
  const createZone = async (e) => {
    e.preventDefault();

    if (!validateZipFormat(newZone.zip_code)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid ZIP Code Format',
        text: 'Please use correct format: Use comma (,) for specific ZIP codes and hyphen (-) for ranges. Example: 1000,1019,1020-1025',
      });
      return;
    }

    try {
      if (newZone.id) {
        await ZoneService.update(newZone.id, { name: newZone.name, zip_code: newZone.zip_code, is_active: newZone.is_active }, axiosPrivate);
      } else {
        await ZoneService.store(newZone, axiosPrivate);
      }

      fetchZones();
      closeModal();
    } catch (error) {

    }
  };

  const RenderZone = ({ zone }) => {
    const [isOpen, setIsOpen] = useState(false);
    const isCommaSeparated = zone.zip_code.includes(',');
    const isSingleZip = !zone.zip_code.includes(',') && !zone.zip_code.includes('-');
    const zip_codeClass = isSingleZip ? "single" : isCommaSeparated ? "multi" : "range";
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const handleMouseUp = () => setIsDragging(false);
    const handleMouseLeave = () => setIsDragging(false);
    const timeoutRef = useRef(null);
    const [editZoneId, setEditZoneId] = useState(null);


    const renderZipCodes = () => {
      const zipCodes = zone.zip_code.split(',').map((zip) => zip.trim());
      return zipCodes.join(', '); // Concatenate with commas for inline display
    };

    const toggleChildZones = () => {
      setIsOpen((prevState) => !prevState);
    };

    const handleStatusChange = (id, currentStatus) => {
      toggleZoneStatus(id, currentStatus);
    };

    const handleMouseDown = (e) => {
      const container = e.currentTarget;
      setIsDragging(true);
      setStartX(e.pageX - container.offsetLeft); // Store initial mouse position
      setScrollLeft(container.scrollLeft); // Store initial scroll position
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return; // Exit if not dragging
      e.preventDefault();
      const container = e.currentTarget;
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX); // Distance moved
      container.scrollLeft = scrollLeft - walk; // Update scroll position
    };


    const handleEditMouseLeave = () => {
      timeoutRef.current = setTimeout(() => {
        setSelectedZone(null);
        setEditZoneId(null)
      }, 250);
    };

    const handleEditMouseEnter = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };

    // Toggles the edit dropdown for a specific zone
    const toggleEditDropdown = (e, zoneId) => {
      setEditZoneId((prevId) => (prevId === zoneId ? null : zoneId));
    };

    const handleDelete = async (zoneId) => {
      try {
        const response = await ZoneService.delete(zoneId, axiosPrivate);
        fetchZones();
      } catch (error) {

      }
    }

    return (
      <>
        <div className="list-details">
          <div className="lists">
            <div className="header-list">
              <div className="name">{zone.name}</div>

              <div
                className={`zip-code ${isOpen ? "open" : ""} ${zip_codeClass}`}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave} // Cancel drag if mouse leaves
                onClick={toggleChildZones}
                data-tooltip-id={`zip-tooltip-${zone.id}`}
                data-tooltip-content={renderZipCodes()}
              >
                {zone.zip_code.length > 100
                  ? `${zone.zip_code.substring(0, 100)}`
                  : zone.zip_code}
              </div>
              <Tooltip id={`zip-tooltip-${zone.id}`} place="top" className="custom-tooltip" />

              <div className="status">
                <div className="action">
                  <input
                    type="checkbox"
                    checked={zone.is_active}
                    onChange={() => handleStatusChange(zone.id, zone.is_active)}
                    id={`zone-status-${zone.id}`}
                  />
                  <label htmlFor={`zone-status-${zone.id}`}></label>
                </div>
              </div>

              <div className="action">
                <div
                  className="edit"
                  style={{ position: "absolute" }}
                  onClick={() => setSelectedZone((prevSelected) => (prevSelected === zone.id ? null : zone.id))}
                  onMouseLeave={handleEditMouseLeave}
                  onMouseEnter={handleEditMouseEnter}
                >
                  &#8942;
                  {selectedZone === zone.id && (
                    <div className="dropdown-menu">
                      <ul>
                        <li onClick={() => openZoneModal(zone)}>Edit Zone</li>
                        <li className="danger"
                          onClick={() => handleDelete(zone.id)}
                        >
                          Delete
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {isOpen && zone.children && (
              <div className="list-zone show">
                {zone.children.map((child) => (
                  <div key={child.id} className="list">
                    <div className="name"></div>
                    <div className="zip-code">{child.zip_code} {child.name}</div>
                    <div className="status">
                      <div className="action">
                        <input
                          type="checkbox"
                          checked={child.is_active}
                          onChange={() => handleStatusChange(child.id, child.is_active)}
                          id={`zone-status-${child.id}`}
                        />
                        <label htmlFor={`zone-status-${child.id}`}></label>
                      </div>
                    </div>
                    <div className="action">
                      <div
                        className="edit"
                        style={{ position: "absolute" }}
                        onClick={(e) => toggleEditDropdown(e, child.id)}
                        onMouseLeave={handleEditMouseLeave}
                        onMouseEnter={handleEditMouseEnter}
                      >
                        &#8942;
                        {editZoneId === child.id && (
                          <div className="dropdown-menu">
                            <ul>
                              <li onClick={() => openZoneModal(child)}>Edit Zone</li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  const RenderUnavailableZone = ({ zone }) => {
    const [isOpen, setIsOpen] = useState(false);
    const isCommaSeparated = zone.zip_code.includes(',');
    const isSingleZip = !zone.zip_code.includes(',') && !zone.zip_code.includes('-');
    const zip_codeClass = isSingleZip ? "single" : isCommaSeparated ? "multi" : "range";
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const handleMouseUp = () => setIsDragging(false);
    const handleMouseLeave = () => setIsDragging(false);
    const timeoutRef = useRef(null);
    const [editZoneId, setEditZoneId] = useState(null);


    const renderZipCodes = () => {
      const zipCodes = zone.zip_code.split(',').map((zip) => zip.trim());
      return zipCodes.join(', '); // Concatenate with commas for inline display
    };

    const toggleChildZones = () => {
      setIsOpen((prevState) => !prevState);
    };

    const handleStatusChange = (id, currentStatus) => {
      toggleZoneStatus(id, currentStatus);
    };

    const handleMouseDown = (e) => {
      const container = e.currentTarget;
      setIsDragging(true);
      setStartX(e.pageX - container.offsetLeft); // Store initial mouse position
      setScrollLeft(container.scrollLeft); // Store initial scroll position
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return; // Exit if not dragging
      e.preventDefault();
      const container = e.currentTarget;
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX); // Distance moved
      container.scrollLeft = scrollLeft - walk; // Update scroll position
    };


    const handleEditMouseLeave = () => {
      timeoutRef.current = setTimeout(() => {
        setSelectedZone(null);
        setEditZoneId(null)
      }, 250);
    };

    const handleEditMouseEnter = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };

    // Toggles the edit dropdown for a specific zone
    const toggleEditDropdown = (e, zoneId) => {
      setEditZoneId((prevId) => (prevId === zoneId ? null : zoneId));
    };

    const handleDelete = async (zoneId) => {
      try {
        const response = await UnavailableZoneService.delete(zoneId, axiosPrivate);
        fetchUnavailableZones();
      } catch (error) {

      }
    }

    return (
      <>
        <div className="list-details">
          <div className="lists">
            <div className="header-list">
              <div className="name">{zone.email}</div>
              <div
                className={`zip-code ${isOpen ? "open" : ""} ${zip_codeClass}`}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave} // Cancel drag if mouse leaves
                onClick={toggleChildZones}
                data-tooltip-id={`zip-tooltip-${zone.id}`}
                data-tooltip-content={renderZipCodes()}
              >
                {zone.zip_code.length > 100
                  ? `${zone.zip_code.substring(0, 100)}`
                  : zone.zip_code}
              </div>
              <Tooltip id={`zip-tooltip-${zone.id}`} place="top" className="custom-tooltip" />

              {/* <div className="status">
                <div className="action">
                  <input
                    type="checkbox"
                    checked={zone.is_active}
                    onChange={() => handleStatusChange(zone.id, zone.is_active)}
                    id={`zone-status-${zone.id}`}
                  />
                  <label htmlFor={`zone-status-${zone.id}`}></label>
                </div>
              </div> */}

              <div className="action">
                <div
                  className="edit"
                  style={{ position: "absolute" }}
                  onClick={() => setSelectedZone((prevSelected) => (prevSelected === zone.id ? null : zone.id))}
                  onMouseLeave={handleEditMouseLeave}
                  onMouseEnter={handleEditMouseEnter}
                >
                  &#8942;
                  {selectedZone === zone.id && (
                    <div className="dropdown-menu">
                      <ul>
                        {/* <li onClick={() => openZoneModal(zone)}>Edit Zone</li> */}
                        <li className="danger"
                          onClick={() => handleDelete(zone.id)}
                        >
                          Delete
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {isOpen && zone.children && (
              <div className="list-zone show">
                {zone.children.map((child) => (
                  <div key={child.id} className="list">
                    <div className="name"></div>
                    <div className="zip-code">{child.zip_code} {child.name}</div>
                    <div className="status">
                      <div className="action">
                        <input
                          type="checkbox"
                          checked={child.is_active}
                          onChange={() => handleStatusChange(child.id, child.is_active)}
                          id={`zone-status-${child.id}`}
                        />
                        <label htmlFor={`zone-status-${child.id}`}></label>
                      </div>
                    </div>
                    <div className="action">
                      <div
                        className="edit"
                        style={{ position: "absolute" }}
                        onClick={(e) => toggleEditDropdown(e, child.id)}
                        onMouseLeave={handleEditMouseLeave}
                        onMouseEnter={handleEditMouseEnter}
                      >
                        &#8942;
                        {editZoneId === child.id && (
                          <div className="dropdown-menu">
                            <ul>
                              <li onClick={() => openZoneModal(child)}>Edit Zone</li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  const toggleZoneStatus = async (id, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      await ZoneService.update(id, { is_active: newStatus }, axiosPrivate);
      fetchZones(); // Refetch to get the updated zone list
    } catch (error) {
    }
  };

  const openZoneModal = (zone) => {
    setNewZone({
      id: zone.id,
      name: zone.name,
      zip_code: zone.zip_code,
      is_active: zone.is_active,
    });
    setIsOpen(true);
  };

  function redirectToTab(tabName) {
    navigate(`/management?tab=${tabName}`);

    if (tabName === "profile") setTabIndex(0);
    else if (tabName === "zone-management") setTabIndex(1);
    else if (tabName === "hotel-availability") setTabIndex(1);
    else if (tabName === "general-settings") setTabIndex(2);
    else setTabIndex(0);

  }

  return (
    <>
      <MenuHeader />
      <div className="bloc-management">
        <div className="container">
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab onClick={() => redirectToTab("profile")}>My Profile</Tab>

              {auth?.roles?.some(role => role.name === 'ROLE_ADMIN') && (
                <Tab onClick={() => redirectToTab("zone-management")}>Zone Management</Tab>
              )}
              {auth?.roles?.some(role => role.name === 'ROLE_ADMIN') && (
                <Tab onClick={() => redirectToTab("unavailable-zones")}>Unavailable Zones</Tab>
              )}
              {auth?.roles?.some(role => role.name === 'ROLE_HOTEL_MANAGER') && (
                <Tab onClick={() => redirectToTab("hotel-availability")}>Hotel Availability</Tab>
              )}
              {auth?.roles?.some(role => role.name === 'ROLE_ADMIN') && (
                <Tab onClick={() => redirectToTab("general-settings")}>General Settings</Tab>
              )}

            </TabList>

            <TabPanel>
              <div className="bloc-search">
                <div className="container">
                  <div className="search">
                    <h2>My profile</h2>
                  </div>
                </div>
                <div className="profile">
                  <div className="account-name">
                    {userData.firstname} {userData.lastname}
                  </div>
                  <div className="account-avatar">
                    <span className="name">
                      {userData.firstname ? userData.firstname[0] : ''}
                      {userData.lastname ? userData.lastname[0] : ''}
                    </span>
                  </div>
                </div>
              </div>
              <form onSubmit={updateProfile}>
                <h2 className="left-align">Personal Informations</h2>
                <div className="profile-form-inscription">
                  <div className="form-row">
                    <div className="form-left">
                      <label>First name</label>
                      <div className="form-text">
                        <input
                          type="text"
                          name="firstname"
                          value={userData.firstname || ""}
                          onChange={handleInputChange}
                          disabled={!isEditingProfile}
                          required
                        />
                      </div>
                      <label>Email</label>
                      <div className="form-text">
                        <input
                          type="email"
                          name="email"
                          value={userData.email || ""}
                          onChange={handleInputChange}
                          disabled={!isEditingProfile}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-right">
                      <label>Last name</label>
                      <div className="form-text">
                        <input
                          type="text"
                          name="lastname"
                          value={userData.lastname || ""}
                          onChange={handleInputChange}
                          disabled={!isEditingProfile}
                          required
                        />
                      </div>
                      <label>Phone number</label>
                      <div className="form-text">
                        <input
                          type="text"
                          name="phone"
                          value={userData.phone || ""}
                          onChange={handleInputChange}
                          disabled={!isEditingProfile}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-actions" style={{ display: "flex", gap: "10px" }}>
                    {isEditingProfile ? (
                      <>
                        <button type="button" className="btn btn-danger" onClick={handleCancelEdit}>
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={!isFormModified()}
                        >
                          Confirm
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleEditProfileClick}
                      >
                        Edit Profile
                      </button>
                    )}
                  </div>
                </div>
              </form>
              <form onSubmit={handlePasswordSubmit}>
                <h2 className="left-align">Password</h2>
                <div className="profile-form-inscription">
                  {!isEditingPassword ? (
                    <>
                      <label className="margeTop20">Enter your current password</label>
                      <div className="form-text pass-input-size">
                        <input
                          type="password"
                          name="currentPassword"
                          value={userData.currentPassword}
                          onChange={handleInputChange}
                          placeholder="******************"
                          required
                        />
                      </div>
                      <button type="button" onClick={handleEditPasswordClick} className="btn btn-primary">
                        Edit password
                      </button>
                    </>
                  ) : (
                    <>
                      <label className="margeTop20">Enter your current password</label>
                      <div className="form-text pass-input-size">
                        <input
                          type="password"
                          name="currentPassword"
                          value={userData.currentPassword}
                          onChange={handleInputChange}
                          placeholder="******************"
                          required
                        />
                      </div>
                      <label className="margeTop20">Enter your new password</label>
                      <div className="form-text pass-input-size">
                        <input
                          type="password"
                          name="newPassword"
                          value={userData.newPassword}
                          onChange={handleInputChange}
                          placeholder="Please enter your new password"
                          required
                        />
                      </div>
                      <label className="margeTop20">Confirm your new password</label>
                      <div className="form-text pass-input-size">
                        <input
                          type="password"
                          name="confirmPassword"
                          value={userData.confirmPassword}
                          onChange={handleInputChange}
                          placeholder="Please confirm your new password"
                          required
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Confirm
                      </button>
                    </>
                  )}
                </div>
              </form>
            </TabPanel>

            {auth?.roles?.some(role => role.name === 'ROLE_ADMIN') && (
              <TabPanel>
                <div className="bloc-search">
                  <div className="container">
                    <div className="search">
                      <h2>Zone Management</h2>
                    </div>
                    <button onClick={openModal} className="btn-add-client">
                      Add
                    </button>
                  </div>
                </div>
                <div className="list-management">
                  <div className="header-list">
                    <div className="name">Name of the zone</div>
                    <div className="zip-code">ZIP code (Area)</div>
                    <div className="status">Status</div>
                    <div className="action"></div>
                  </div>
                  <div className="list-details">
                    {zones.length > 0 ? (
                      zones.map((zone) => (
                        <RenderZone key={zone.id} zone={zone} />
                      ))
                    ) : (
                      <div className="text-center">No zones available</div>
                    )}
                  </div>
                </div>
              </TabPanel>
            )}
            {auth?.roles?.some(role => role.name === 'ROLE_ADMIN') && (
              <TabPanel>
                <div className="bloc-search">
                  <div className="container">
                    <div className="search">
                      <h2>Unavailable Zones</h2>
                    </div>
                  </div>
                </div>
                <div className="list-management">
                  <div className="header-list">
                    <div className="name">User email</div>
                    <div className="zip-code">ZIP code</div>
                    {/* <div className="status">Status</div> */}
                    <div className="action"></div>
                  </div>
                  <div className="list-details">
                    {unavailablezones.length > 0 ? (
                      unavailablezones.map((zone) => (
                        <RenderUnavailableZone key={zone.id} zone={zone} />
                      ))
                    ) : (
                      <div className="text-center">No zones available</div>
                    )}
                  </div>
                </div>
              </TabPanel>
            )}

            {auth?.roles?.some(role => role.name === 'ROLE_HOTEL_MANAGER') && (
              <TabPanel>
                <div className="bloc-search">
                  <div className="container">
                    <div className="search">
                      <h2>Hotel Availability</h2>
                    </div>
                  </div>
                </div>

                <div className="week-buttons">
                  {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((day, index) => (
                    <button key={index} onClick={() => openAvailabilityModal(index)} className="btn-submit">
                      {day}
                    </button>
                  ))}
                </div>

                <Modal isOpen={availabilityModalOpen} onRequestClose={closeAvailabilityModal} style={customStyles}>
                  <h2>Availability for {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][selectedDay]}</h2>
                  <div className="tableau-list no-scroll">
                    <div className="container">
                      <table style={{ width: "100%", textAlign: "center" }}>
                        <thead className="no-background">
                          <tr>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th className="text-align">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {availabilities[selectedDay]?.length > 0 ? (
                            availabilities[selectedDay].map((availability, index) => (
                              <tr key={index}>
                                <td>{availability.start_time}</td>
                                <td>{availability.end_time}</td>
                                <td>
                                  <div className="actions-availibility">
                                    <button
                                      className="btn btn-warning btn-sm actions-availibility-button-size"
                                      onClick={() => editAvailability(index)}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className="btn btn-danger btn-sm actions-availibility-button-size"
                                      onClick={() => deleteAvailability(index)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3" className="text-center">No availability set.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* New Availability Inputs */}
                  <div className="form-inscription">
                    <div className="time-availiblity-container">
                      <div className="time-availiblity-input">
                        <label>Start Time:</label>
                        <div className="form-text">
                          <input
                            type="time"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="time-availiblity-input">
                        <label>End Time:</label>
                        <div className="form-text">
                          <input
                            type="time"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{ marginTop: "20px", textAlign: "center" }}>
                      <button
                        onClick={addAvailability}
                        className="btn btn-success"
                        style={{ padding: "10px 20px", fontSize: "16px" }}
                      >
                        {editIndex !== null ? "Update" : "Create"}
                      </button>
                    </div>
                  </div>
                </Modal>
              </TabPanel>
            )}
            {auth?.roles?.some(role => role.name === 'ROLE_ADMIN') && (
              <TabPanel>
                <div className="bloc-search">
                  <div className="container">
                    <div className="search">
                      <h2>General Settings</h2>
                    </div>
                  </div>
                </div>
                <form onSubmit={updateSettings}>
                  <div className="form-inscription form-general-settings">
                    {generalSettingData.length && generalSettingData.map(setting => {
                      return (<div className="form-text" key={setting.id}>
                        <h2 className="left-align">{asTitle(setting.setting)}</h2>
                        <div className="time-editor">
                          <button type="button" id={`decrement-${setting.id}`} onClick={handleTimeDecrement} disabled={setting.value === 0}>-</button>
                          <span className="time">{setting.value} {setting.setting === 'surcharge' ? 'CHF' : 'min'}</span>
                          <button type="button" id={`increment-${setting.id}`} onClick={handleTimeIncrement}>+</button>
                        </div>
                      </div>)
                    }
                    )}

                    <div className="form-actions" style={{ display: "flex", gap: "10px" }}>
                      {generalSettingData.find(setting => setting.updated) ? (
                        <>
                          <button type="button" className="btn btn-danger" onClick={handleCancelSettingsEdit}>
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary"
                          >
                            Confirm
                          </button>
                        </>
                      ) : (<></>)}
                    </div>
                  </div>
                </form>
              </TabPanel>
            )}
          </Tabs>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Create Zone Modal"
      >
        <h2>{newZone.id ? "Edit Zone" : "Create a New Zone"}</h2>
        <form onSubmit={createZone}>
          <div className="form-inscription">
            <div className="form-text">
              <label>Name of the zone</label>
              <input
                type="text"
                name="name"
                value={newZone.name}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-text">
              <label>ZIP code (Area)</label>
              <input
                type="text"
                name="zip_code"
                value={newZone.zip_code}
                onChange={handleInputChange}
                required
                placeholder="e.g., 1000,1019,1020-1025"
              />
              <br /><br />
              <label className="help-text">
                Use comma (,) for specific ZIP codes and hyphen (-) for ranges<br />
                Example: 2000 for single codes or 1000,1019 for specific codes and 1000-1019 for a range
              </label>
            </div>
            <div className="actions">
              <button type="button" onClick={closeModal} className="btn btn-secondary ml-2">
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                {newZone.id ? "Update" : "Create"}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Management;
