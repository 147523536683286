import React, { useEffect, useState, useRef } from "react";
import MenuHeader from "./MenuHeader";
import Modal from 'react-modal';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import UserService from "../services/user.service";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useNotifications } from '../context/NotificationProvider';
import { asTitle, castDate } from '../shared/utils/string';
import useAxiosMultiPart from "../hooks/useAxiosMultiPart";
import imageFile from "../assets/images/upload-file.svg";
import { Menu, MenuList, MenuItem, IconButton, ListItemText, ListItemIcon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: "hidden auto",
    scrollbarWidth: "none",
    height: "650px",
    maxHeight: "95vh",
    scrollbarWidth: "none",
  },
};

const User = () => {

  Modal.setAppElement("#root");
  const axiosMultiPart = useAxiosMultiPart();

  const [modalIsOpen, setIsOpen] = useState(false);
  const notify = useNotifications();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [formData, setFormData] = useState({
    id: null,
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    type: "admin",
    civility: null,
    birthdate: "",
  });
  const timeoutRef = useRef(null);
  const [sortDirection, setSortDirection] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [customerBookings, setCustomerBookings] = useState([]);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);


  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value,
    }));
  };

  const [filterData, setFilterData] = useState({
    search: "",
    search_by: "name",
    operator: "contains",
    status: "",
  });

  const [filters, setFilters] = useState({
    with: 'primary_address,roles,media',
    page: currentPage,
    per_page: perPage
  });

  // const fetchFilterUsers = async () => {
  //   try {
  //     const response1 = await UserService.indexByRole(filters, 'ROLE_ADMIN,ROLE_MANAGER', axiosPrivate);
  //     if (filters.firstname) {
  //       delete filters.firstname;
  //       filters['lastname'] = `${filterData.operator}:${filterData.search}`;
  //     } else if (filters.lastname) {
  //       delete filters.lastname;
  //       filters['firstname'] = `${filterData.operator}:${filterData.search}`;
  //     }
  //     const response2 = await UserService.indexByRole(filters, 'ROLE_ADMIN,ROLE_MANAGER', axiosPrivate);
  //     setUsers([...response1, ...response2]);
  //   } catch (error) {
  //     console.error("Erreur lors du chargement des clients:", error);
  //   }
  // };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    if (filterData.search != '') {
      filters[filterData.search_by] = `${filterData.operator}:${filterData.search}`;
    }

    if (filterData.status != '') {
      filters.status = filterData.status
    }
    await fetchUsers();
  }

  const handleFilterChange = async (e) => {
    const target = e.target;

    if (target.name === 'search_by') {
      const search = document.querySelector('input[name="search"]');

      search.setAttribute('placeholder', `Search By ${target.options[target.selectedIndex].innerHTML}`);
      filterData.search = '';
      delete filters.name;
      delete filters.phone;
      delete filters.email;

      if (filterData.status != '') {
        filters.status = filterData.status
      }
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      await fetchUsers();
    } else if (target.name === 'search' && target.value === '') {
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      delete filters.name;
      delete filters.phone;
      delete filters.email;
      if (filterData.status != '') {
        filters.status = filterData.status
      }
      await fetchUsers();
    } else if (target.name === 'status') {
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      if (target.value != '') {
        filters.status = target.value
      } else {
        delete filters.status;
      }
      await fetchUsers();
    }

    setFilterData({
      ...filterData,
      [target.name]: target.value
    });
  }


  const axiosPrivate = useAxiosPrivate();

  const fetchUsers = async () => {
    try {
      const response = await UserService.indexByRole(filters, 'ROLE_ADMIN,ROLE_MANAGER', axiosPrivate);
      console.log('the results of resps', response);
      setUsers(response.data);
      setTotal(response.total);
      setLastPage(response.last_page);
      if (response.last_page < currentPage) {
        setFilters({ ...filters, page: response.last_page })
        setCurrentPage(response.last_page);
      }
    } catch (error) {
    }
  };


  useEffect(() => {
    fetchUsers();
  }, [currentPage, perPage]);

  const handleChange = (e) => {
    const updatedCivility = e.target.name === 'civility' ? (formData.civility === e.target.value ? "" : e.target.value) : formData.civility;

    const updatedFormData = {
      ...formData,
      [e.target.name]: e.target.value,
      civility: updatedCivility,
    };

    // Handle file input
    if (e.target.name === "file") {
      console.log({ e });
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
        setFile(file);
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.birthdate = formData.birthdate === '' ? null : formData.birthdate;
    formData.role = formData.type === 'admin' ? 'ROLE_ADMIN' : 'ROLE_MANAGER';
    const formDataToSend = new FormData();

    for (const key in formData) {
      const value = formData[key];
      if (value) {
        if (Array.isArray(value) || typeof value === 'object') {
          formDataToSend.append(key, JSON.stringify(value));
        } else if (typeof value === 'boolean') {
          formDataToSend.append(key, Number(value)); // Convert boolean to string
        } else {
          formDataToSend.append(key, value);
        }
      }
    }

    if (file) {
      formDataToSend.append("imageFile", file);
      formData.file = file;
    }

    try {
      const response = await (formData.id ? UserService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate) : UserService.store(file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate));
      if (response.data) {
        closeModal();
        fetchUsers();
      }
    } catch (e) {
      // errors handled in interceptor
    }
  };

  const openModal = (user) => {
    if (user && user.id) {
      let phone = user.phone;
      if (phone && phone.startsWith("0")) {
        phone = "+41" + phone.substring(1);
      }

      setFormData({
        ...formData,
        ...user,
        phone,
        role: user.roles[0]?.name,
      });
      setFile(null);
      setFileName("");
    } else {
      setFormData({
        id: null,
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        type: "",
        civility: null,
        birthdate: "",
      });
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    setFormData({
      civility: null,
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      birthdate: "",
      type: ""
    });
    setIsOpen(false);
  };

  const handleEditMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredRow(null);
    }, 250);
  };

  const handleEditMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleSelectedSort = async (event) => {
    const selected = document.querySelector('.sort-header.active');
    let direction = true;
    if (selected?.id != event.target.id) {
      selected?.classList.remove('active');
      event.target.classList.add('active');
      setSortDirection(true);
    } else {
      setSortDirection(!sortDirection);
      direction = !sortDirection;
    }
    if (event.target.id != 'name') {
      setFilters({ ...filters, sort: `${direction ? '' : '-'}${event.target.id}` })
      filters.sort = `${direction ? '' : '-'}${event.target.id}`;
    } else {
      setFilters({ ...filters, sort: `${direction ? '' : '-'}firstname,${direction ? '' : '-'}lastname` })
      filters.sort = `${direction ? '' : '-'}firstname,${direction ? '' : '-'}lastname`;
    }
    await fetchUsers();
  }

  const handleNextPage = () => {
    setFilters({ ...filters, page: currentPage + 1 });
    setCurrentPage(currentPage + 1);
  }

  const handlePrevPage = () => {
    setFilters({ ...filters, page: currentPage - 1 });
    setCurrentPage(currentPage - 1);
  }

  const handleLastPage = () => {
    setFilters({ ...filters, page: lastPage });
    setCurrentPage(lastPage);
  }

  const handleFirstPage = () => {
    setFilters({ ...filters, page: 1 });
    setCurrentPage(1);
  }

  const handlePerPageChange = (e) => {
    setFilters({ ...filters, per_page: e.target.value, page: 1 });
    setPerPage(e.target.value);
    setCurrentPage(1);

  }

  const blockUser = async (user) => {
    await UserService.update(user.id, { status: 'BLOCKED' }, axiosPrivate);
    fetchUsers();
  }

  const unblockUser = async (user) => {
    await UserService.update(user.id, { status: 'ACTIVE' }, axiosPrivate);
    fetchUsers();
  }

  const handleClick = (event, userId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedUser(userId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <form onSubmit={handleFilterSubmit} className="search">
            <div className="text-search">
              <input
                type="text"
                name="search"
                value={filterData.search}
                onChange={handleFilterChange}
                placeholder="Search by Name"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <select
              name="search_by"
              value={filterData.search_by}
              onChange={handleFilterChange}
            >
              <option value="name">Name</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>
            <select
              name="operator"
              value={filterData.operator}
              onChange={handleFilterChange}
            >
              <option value="contains">Contains</option>
              <option value="starts">Starts With</option>
              <option value="ends">Ends With</option>
              <option value="eq">Equal</option>
              <option value="!eq">Not Equal</option>
              <option value="in">In</option>
            </select>
            <select
              name="status"
              value={filterData.status}
              onChange={handleFilterChange}
            >
              <option value="">All Statuses</option>
              <option value="ACTIVE">Active</option>
              <option value="BLOCKED">Blocked</option>
              <option value="PENDING_EMAIL_VERIFICATION">Pending Email Verification	</option>
              <option value="PENDING_PHONE_VERIFICATION">Pending Phone Verification	</option>
            </select>
          </form>
          <button onClick={openModal} className="btn-add-client">
            Add
          </button>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead>
              <tr>
                <th className="sort-header" id="id" onClick={handleSelectedSort}>ID</th>
                <th>Image</th>
                <th className="sort-header" id="firstname" onClick={handleSelectedSort}>Firstname</th>
                <th className="sort-header" id="lastname" onClick={handleSelectedSort}>Lastname</th>
                <th className="sort-header" id="phone" onClick={handleSelectedSort}>Phone</th>
                <th className="sort-header" id="email" onClick={handleSelectedSort}>Email</th>
                <th className="sort-header" id="created_at" onClick={handleSelectedSort}>Registration Date</th>
                <th>Team</th>
                <th className="sort-header" id="status" onClick={handleSelectedSort}>Status</th>
                <th>Role</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users?.length > 0 ? (
                users.map((user, index) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>
                      <img
                        src={`${user.media[0]?.original_url}`}
                        alt="Manager image"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.phone}</td>
                    <td>{user.email}</td>
                    <td>{castDate(user.created_at)}</td>
                    <td>{user.therapistScore}</td>
                    <td className={user?.status?.toLowerCase()}>
                      {asTitle(user.status)}
                    </td>
                    <td>{asTitle(user.roles[0].name.split('_')[1])}</td>

                    <td>
                      <IconButton onClick={(event) => handleClick(event, user.id)}>
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedUser === user.id}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                      >
                        <MenuList style={{ minWidth: 150 }}>
                          {user.status !== 'BLOCKED' && (
                            <MenuItem style={{ color: "red" }} onClick={() => { handleClose(); blockUser(user); }}>
                              <ListItemIcon>
                                <BlockIcon style={{ color: "red" }} />
                              </ListItemIcon>
                              <ListItemText>Block Account</ListItemText>
                            </MenuItem>
                          )}

                          {user.status !== 'ACTIVE' && (
                            <MenuItem style={{ color: "green" }} onClick={() => { handleClose(); unblockUser(user); }}>
                              <ListItemIcon>
                                <CheckCircleIcon style={{ color: "green" }} />
                              </ListItemIcon>
                              <ListItemText>Activate Account</ListItemText>
                            </MenuItem>
                          )}

                          <MenuItem onClick={() => { handleClose(); openModal(user); }}>
                            <ListItemIcon>
                              <EditIcon style={{ color: "#1976d2" }} />
                            </ListItemIcon>
                            <ListItemText>Edit User</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">No user found.</td>
                </tr>
              )}
            </tbody>
            <tfoot >
              <tr>
                <td colSpan={11}>
                  <div className="paginator">
                    <select value={perPage} onChange={handlePerPageChange} className="per-page">
                      <option value={1}>1</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={250}>250</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                    </select>
                    <div className="total">Total Records: {total}</div>
                    <div className="pagination">
                      <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                      <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                      <span>{currentPage} / {lastPage}</span>
                      <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                      <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add a Manager"
      >
        <button className="close-button" onClick={closeModal}>
          <span className="close-icon">✖</span>
        </button>

        <h2>Add a Manager</h2>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-choix">
              <div className="form-radio">
                <input
                  type="checkbox"
                  name="civility"
                  value="MS"
                  id="madame"
                  checked={formData.civility === "MS"}
                  onChange={handleChange}
                />
                <label htmlFor="madame">Ms</label>
              </div>
              <div className="form-radio">
                <input
                  type="checkbox"
                  name="civility"
                  value="MR"
                  id="monsieur"
                  checked={formData.civility === "MR"}
                  onChange={handleChange}
                />
                <label htmlFor="monsieur">Mr</label>
              </div>
            </div>
            <div className="form-text">
              <label>First Name</label>
              <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Last Name</label>
              <input type="text" name="lastname" value={formData.lastname} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Date of Birth (optional)</label>
              <input type="date" name="birthdate" value={formData.birthdate} onChange={handleChange} />
            </div>
            <div className="form-text">
              <label>Phone Number</label>
              <PhoneInput
                defaultCountry="ch"
                type="tel"
                name="phone"
                value={formData.phone}
                disableCountryGuess="true"
                ariaHideApp="false"
                //hideDropdown="true"
                forceDialCode="true"
                //onChange={handlePhoneChange}
                onChange={(phone) => handlePhoneChange(phone)}
                required
              />            </div>
            <div className="form-text">
              <label>Email Address</label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="form-select">
              <label>Role</label>
              <select name="role" value={formData.role} onChange={handleChange} required>
                <option value="">Select a role</option>
                <option value="ROLE_ADMIN">Admin</option>
                <option value="ROLE_MANAGER">Manager</option>
              </select>
            </div>
            <label className="fileLabel" htmlFor="file">
              Image to upload
            </label>
            <div className="fileField">
              <label htmlFor="file">
                <img className="imgFile" src={imageFile || ""} alt="File upload" />
                {fileName ? (
                  <div>Uploaded File: {fileName}</div>
                ) : (
                  <>
                    <div className="selectFileTo">Select file to upload</div>
                    <div className="orDragAnd">
                      or drag and drop, copy-paste files
                    </div>
                  </>
                )}
              </label>
              <input
                type="file"
                id="file"
                name="file"
                accept="image/*"
                onChange={handleChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>
            <button type="submit" className="btn-submit">
              {formData.id ? "Edit" : "Create"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default User;

