import React, { useEffect, useState, useRef } from "react";
import MenuHeader from "./MenuHeader";
import Modal from "react-modal";
import { exportClientBookingsUrl, exportDirectHotelBookingsUrl } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import cantons from "../shared/data/cantons.json";
import { useNotifications } from '../context/NotificationProvider';
import HotelService from "../services/hotel.service";
import UserService from "../services/user.service";
import ProductService from "../services/product.service";
import BookingService from "../services/booking.service";
import AddressService from "../services/address.service";
import { addressFormat } from '../shared/utils/string';
import { Map } from "./google_map";
import axios from "../api/axios";
import Select from 'react-select';
import useAxiosMultiPart from "../hooks/useAxiosMultiPart";
import imageFile from "../assets/images/upload-file.svg";
import Autocomplete from 'react-google-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import { styled } from "@mui/material/styles";
import { Menu, MenuList, MenuItem, IconButton, ListItemText, ListItemIcon } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import EditIcon from '@mui/icons-material/Edit';

export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/api';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden auto",
    scrollbarWidth: "none",
    height: "650px",
    maxWidth: '46rem'
  },
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#8fa9b9",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const Hotels = () => {
  Modal.setAppElement("#root");
  const libraries = ['places'];
  const axiosMultiPart = useAxiosMultiPart();

  const handleExportClientBookings = async (hotelId) => {
    try {
      const response = await BookingService.exportHotelClientBookings(
        hotelId,
        { responseType: "blob" },
        axiosPrivate
      );

      // Create a Blob
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Force a download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "client_hotel_bookings.xlsx";
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.error('Error exporting client bookings:', error);
    }
  };

  const handleExportDirectHotelBookings = async (hotelId) => {
    try {
      const url = exportDirectHotelBookingsUrl(hotelId);

      // Fetch the file as blob
      const response = await axiosPrivate.get(url, {
        responseType: 'blob',
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a temporary link to trigger the download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'direct_hotel_bookings.xlsx';

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error exporting direct hotel bookings:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response?.data?.message || 'An error occurred during export.',
      });
    }
  };

  const notify = useNotifications();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [modalIsOpenManage, setIsOpenManage] = React.useState(false);
  const [modalIsOpenAddressManage, setIsOpenAddressManage] = React.useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [allBookings, setAllBookings] = useState([]);
  const [directBookings, setDirectBookings] = useState([]);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [accountFormData, setAccountFormData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    role: '',
    hotel_id: ''
  });

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    phone: "",
    email: "",
    contact_first_name: "",
    contact_last_name: "",
    zip_code: "",
    state: "",
    state_code: "",
    street: "",
    street_number: "",
    city: "",
    country: "Switzerland",
    country_code: "CH",
    flypro_instructions: "",
    duo_capability: false,
    latitude: null,
    longitude: null,
    is_only_internal: false
  });

  const axiosPrivate = useAxiosPrivate();

  const [filterData, setFilterData] = useState({
    search: "",
    search_by: "name",
    operator: "contains",
  });

  const [allHotels, setAllHotels] = useState([]);
  const [isAddressUpdate, setIsAddressUpdate] = useState(false);
  const [coordinates, setCoordinate] = useState({
    lat: 46.356,
    lng: 6.5641
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [filters, setFilters] = useState({
    with: 'address,addresses,account,media',
    page: currentPage,
    per_page: perPage
  });
  const [autoCompleteValue, setAutoCompleteValue] = useState("");

  const [sortDirection, setSortDirection] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);


  const customSelectStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '60px',
      padding: '0 15px',
      fontSize: '14px',
      lineHeight: '60px',
      color: '#393D44',
      border: '0',
      borderRadius: '20px',
      background: '#ffffff',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    // placeholder: (base) => ({
    //   ...base,
    //   marginTop: '-10px',
    // }),
    // dropdownIndicator: (base) => ({
    //   ...base,
    //   marginTop: '-10px',
    // }),
  };

  const [selectedState, setSelectedState] = useState(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const timeoutRef = useRef(null);
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [availableAddresses, setAvailableAddresses] = useState([]);
  const [modalType, setModalType] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    zip_code: '',
    street_number: '',
    state: '',
    state_code: '',
    country: 'Switzerland',
    country_code: 'CH',
    lat: null,
    lng: null,
    hotel_id: ""
  });

  const [isZipCodeVisible, setIsZipCodeVisible] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [selectedHotelId, setSelectedHotelId] = useState(null);
  const [hotelProducts, setHotelProducts] = useState([]);
  const [isProductListVisible, setIsProductListVisible] = useState(true);
  const [productFormData, setProductFormData] = useState({
    id: null,
    name: "",
    description: "",
    duration: "",
    price: "",
    is_active: 1,
    hotel_id: "",
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk",
    libraries: ['places'],
  });

  /* ========================================== */

  const handleStateChange = (data) => {
    setIsAddressUpdate(true);
    let selected = cantons.cantons.find(
      (canton) => canton.name === data?.value
    );
    document.querySelector('input[name="zip_code"]').setAttribute('placeholder', selected?.zip_code_range || '');
    setFormData({
      ...formData, zip_code: ''
    });
    setFormData({
      ...formData,
      state: data?.value,
    });
    setSelectedState(data);
  };

  // Fetch Hotels
  const fetchHotels = async () => {
    try {
      const response = await HotelService.index(filters, axiosPrivate);
      console.log("API Response:", response);
      setAllHotels(response.data);
      setTotal(response.total);
      setLastPage(response.last_page);
      if (response.last_page < currentPage) {
        setFilters({ ...filters, page: response.last_page })
        setCurrentPage(response.last_page);
      }
      setHotels(response.data);
    } catch (error) {

    }
  };

  const fetchBookings = async (id) => {
    try {
      const response = await BookingService.index({ with: 'creator,product,client' }, axiosPrivate);
      console.log('Fetched bookings:', response);
      setAllBookings(response.account.bookings);
      setDirectBookings(response.directBookings);
      setHotels(response);
    } catch (error) {

    }
  };

  const fetchHotelBookings = async (id) => {
    try {
      const response = await BookingService.index(
        { hotel_id: id, with: "creator.roles,product,client" },
        axiosPrivate
      );
      const hotelBooking = response.filter((booking) => {
        return (
          booking.creator &&
          booking.creator.roles &&
          booking.creator.roles.some((role) => !role.name === "ROLE_HOTEL_MANAGER")
        );
      });
      setAllBookings(hotelBooking);
    } catch (error) {

    }
  };

  const fetchHotelDirectBookings = async (id) => {
    try {
      const all = await BookingService.index(
        {
          hotel_id: id,
          with: "creator.roles,product,client"
        },
        axiosPrivate
      );
      const onlyManager = all.filter((booking) => {
        return (
          booking.creator &&
          booking.creator.roles &&
          booking.creator.roles.some((role) => role.name === "ROLE_HOTEL_MANAGER")
        );
      });
      setDirectBookings(onlyManager);
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchHotels();
  }, [currentPage, perPage]);

  const closeModalManage = () => {
    setIsOpenManage(false);
    setSelectedHotel(null);
  };

  const openModalManage = (hotel) => {
    setSelectedHotel(hotel);
    fetchBookings(hotel.id);
    fetchHotelBookings(hotel.id);
    fetchHotelDirectBookings(hotel.id);
    setModalType("addresses");
    setIsOpenManage(true);
  };

  const handleSwitchChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      duo_capability: event.target.checked ? 1 : 0,
    }));
  };

  const handleSwitchInternalChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      is_only_internal: event.target.checked ? 1 : 0,
    }));
  };

  const handleAccountFormChange = (e) => {
    const { name, value } = e.target;
    setAccountFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    if (formData.id && e.target.name === 'zip_code' || e.target.name === 'street' || e.target.name === 'street_number' || e.target.name === 'city') {
      setIsAddressUpdate(true);
    }
    if (e.target.name === "file") {
      console.log({ e });
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
        setFile(file);
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    formData.birthdate = formData.birthdate === '' ? null : formData.birthdate;
    formData.role = 'ROLE_CLIENT';
    formData.country = "Switzerland";
    formData.country_code = "CH";

    const formDataToSend = new FormData();

    for (const key in formData) {
      const value = formData[key];
      if (value) {
        if (Array.isArray(value) || typeof value === 'object') {
          formDataToSend.append(key, JSON.stringify(value));
        } else if (typeof value === 'boolean') {
          formDataToSend.append(key, Number(value)); // Convert boolean to string
        } else {
          formDataToSend.append(key, value);
        }
      }
    }

    if (file) {
      formDataToSend.append("imageFile", file);
    }
    try {
      const response = await (formData.id ? HotelService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate) : HotelService.store(formDataToSend, file ? axiosMultiPart : axiosPrivate));
      if (response.data) {
        closeModal();
        fetchHotels();
      }
    } catch (e) {
      // errors handled in interceptor
    }
  };

  const handleAccountFormSubmit = async (e, hotelId) => {
    e.preventDefault();

    if (!accountFormData.email || !accountFormData.firstname || !accountFormData.lastname) {
      notify.warn(
        "Please fill out all required fields!"
      );
      return;
    }

    try {
      accountFormData.role = 'ROLE_HOTEL_MANAGER';
      accountFormData.hotel_id = selectedHotel.id;
      const response = await UserService.store(accountFormData, axiosPrivate);
      setFormData(response)
      setIsAccountModalOpen(false);

    } catch (error) {


    }
  };

  const resetFormData = () => {
    setFormData({
      id: null,
      name: "",
      address: "",
      phone: "",
      email: "",
      contact_first_name: "",
      contact_last_name: "",
      zip_code: "",
      state: "",
      state_code: "",
      street: "",
      street_number: "",
      city: "",
      country: "Switzerland",
      country_code: "CH",
      flypro_instructions: "",
      duo_capability: false,
      latitude: null,
      longitude: null,
      is_only_internal: false
    });
    setCoordinate({ lat: 46.356, lng: 6.5641 });
    setSelectedState(null);
  };
  const openModal = (hotel) => {
    if (hotel && hotel.id) {
      setFormData({
        ...formData,
        ...hotel,
        address: hotel.address?.id,
        zip_code: hotel.address?.zip_code,
        state: hotel.address?.state,
        street: hotel.address?.street,
        street_number: hotel.address?.street_number,
        city: hotel.address?.city,
        latitude: hotel.address?.latitude,
        longitude: hotel.address?.longitude,
      });

      if (hotel.address?.state) {
        setSelectedState({
          value: hotel.address?.state,
          label: hotel.address?.state,
        });
      }

      setCoordinate({
        lat: hotel.address?.latitude,
        lng: hotel.address?.longitude,
      });

      // Fixed address string
      const addressString =
        `${hotel.address?.street_number ?? ""} ${hotel.address?.street ?? ""}, ` +
        `${hotel.address?.city ?? ""}, ${hotel.address?.state ?? ""} ` +
        `${hotel.address?.zip_code ?? ""}`.trim();

      setAutoCompleteValue(addressString);
      setFile(null);
      setFileName("");
    } else {
      resetFormData();
      setAutoCompleteValue("");
    }
    setIsOpen(true);
    setShowMap(false);
  };

  const fetchCoordinates = async () => {
    const { street_number, street, city, state, zip_code } = formData;
    const address = `${street_number} ${street}, ${city}, ${state} ${zip_code}`;

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address,
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          },
        }
      );

      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        setFormData({
          ...formData,
          latitude: location.lat,
          longitude: location.lng,
        });
        setCoordinate({
          lat: location.lat,
          lng: location.lng
        });
      } else {
        console.error('No results found for the given address.');
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const handleCoordinateChange = (coordinates) => {
    setFormData({
      ...formData,
      longitude: coordinates[0]?.lng,
      latitude: coordinates[0]?.lat,
    })
    setCoordinate({
      lat: coordinates[0]?.lng,
      lng: coordinates[0]?.lat,
    })
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  // Open account modal
  const handleCreateAccountClick = () => {
    setIsAccountModalOpen(true);
  };

  // Close account modal
  const handleModalClose = () => {
    setIsAccountModalOpen(false);
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();

    if (filterData.search !== '' && filterData.search_by !== 'city') {
      filters[filterData.search_by] = `${filterData.operator}:${filterData.search}`;
    } else if (filterData.search_by === 'city') {
      const addresses = await AddressService.index({ columns: 'id,city', city: `${filterData.operator}:${filterData.search}` }, axiosPrivate)
      if (addresses.length) {
        const ids = addresses.map((address) => address.id).join(',');
        console.log({ ids })
        filters['address_id'] = `in:${ids}`;
      } else {
        filters['address_id'] = `-1`;
      }
    }

    await fetchHotels();
  };

  const handleFilterChange = async (e) => {
    const target = e.target;

    if (target.name === 'search_by') {
      const search = document.querySelector('input[name="search"]');

      search.setAttribute('placeholder', `Search By ${target.options[target.selectedIndex].innerHTML}`);
      filterData.search = '';
      delete filters.name;
      delete filters.address_id;
      delete filters.contact_first_name;
      delete filters.contact_last_name;


      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      await fetchHotels();
    } else if (target.name === 'search' && target.value === '') {
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      delete filters.name;
      delete filters.address_id;
      delete filters.contact_first_name;
      delete filters.contact_last_name;
      await fetchHotels();
    }
    setFilterData({
      ...filterData,
      [target.name]: target.value
    });
  }

  const handleEditMouseEnter = (index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setHoveredRow(index);
  };

  const handleEditMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredRow(null);
    }, 250);
  };

  const handleNextPage = () => {
    setFilters({ ...filters, page: currentPage + 1 });
    setCurrentPage(currentPage + 1);
  }

  const handlePrevPage = () => {
    setFilters({ ...filters, page: currentPage - 1 });
    setCurrentPage(currentPage - 1);
  }

  const handleLastPage = () => {
    setFilters({ ...filters, page: lastPage });
    setCurrentPage(lastPage);
  }

  const handleFirstPage = () => {
    setFilters({ ...filters, page: 1 });
    setCurrentPage(1);
  }

  const handlePerPageChange = (e) => {
    setFilters({ ...filters, per_page: e.target.value, page: 1 });
    setPerPage(e.target.value);
    setCurrentPage(1);
  }

  const handleSelectedSort = async (event) => {
    const selected = document.querySelector('.sort-header.active');
    let direction = true;
    if (selected?.id !== event.target.id) {
      selected?.classList.remove('active');
      event.target.classList.add('active');
      setSortDirection(true);
    } else {
      setSortDirection(!sortDirection);
      direction = !sortDirection;
    }

    const sortField = event.target.id === 'name' ? `${direction ? '' : '-'}name` : `${direction ? '' : '-'}${event.target.id}`;
    setFilters({ ...filters, sort: sortField });
    filters.sort = sortField;

    await fetchHotels(); // Refresh data with new sort order
  };

  const handleDelete = async (id) => {
    try {
      const response = await HotelService.delete(id, axiosPrivate);
      fetchHotels();
    } catch (error) {

    }
  };

  const handleRemoveAddress = async (addressToRemove) => {
    try {
      await AddressService.delete(addressToRemove.id, axiosPrivate);

      // Update the selected and available addresses state
      const updatedAddresses = selectedAddresses.filter(
        (address) => address.id !== addressToRemove.id
      );

      const updatedAvailableAddresses = [...availableAddresses, addressToRemove];

      setSelectedAddresses(updatedAddresses);
      setAvailableAddresses(updatedAvailableAddresses);

      const updatedSelectedRow = {
        ...selectedRow,
        addresses: selectedRow.addresses.filter(
          (address) => address.id !== addressToRemove.id
        ),
      };

      setSelectedRow(updatedSelectedRow);
    } catch (error) {

    }
  };

  function afterOpenManageModal() {
  }

  const openManageModal = async (type, row) => {
    setModalType(type);
    setSelectedRow(row);

    if (type === "addresses") {
      console.log('selectedRow: ', selectedRow)
      const hotelAddresses = addresses.filter((address) =>
        row.addresses.some((userAddress) => userAddress.id === address.id)
      );
      const availableAddresses = addresses.filter(
        (address) => !hotelAddresses.some((userAddress) => userAddress.id === address.id)
      );
      setSelectedAddresses(hotelAddresses);
      setAvailableAddresses(availableAddresses);
    }

    setIsOpenAddressManage(true);
  };

  const closeModalAddressManage = () => {
    setIsOpenAddressManage(false);
  };

  const handleAddAddress = async (e) => {
    e.preventDefault();

    const hotelId = selectedRow?.id;

    try {
      const addressData = {
        ...newAddress,
        hotel_id: hotelId,
      };

      console.log('addressData: ', addressData);

      const response = await AddressService.store(addressData, axiosPrivate);

      // Update the selected row with the new address
      setSelectedRow(prev => ({
        ...prev,
        addresses: [...prev.addresses, response.data],
      }));

      setNewAddress({
        zip_code: "",
        state: "",
        state_code: "",
        street: "",
        street_number: "",
        city: "",
        country: "Switzerland",
        country_code: "CH",
      });

      setIsZipCodeVisible(false);
    } catch (error) {

    }
  };

  const handlePlaceSelect = (place) => {
    const zipCode = place.address_components.find((component) =>
      component.types.includes("postal_code")
    )?.long_name;
    const addressComponents = place.address_components;
    const updatedAddress = {
      street: '',
      city: '',
      zip_code: '',
      street_number: '',
      state: '',
      state_code: '',
      country: 'Switzerland',
      country_code: 'CH',
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };

    if (zipCode) {
      setFormData((prev) => ({ ...prev, zip_code: zipCode }));
      setIsZipCodeVisible(false);
    } else {
      setIsZipCodeVisible(true);
    }

    addressComponents.forEach(component => {
      const type = component.types[0];
      switch (type) {
        case 'street_number':
          updatedAddress.street_number = component.long_name;
          break;
        case 'route':
          updatedAddress.street = component.long_name;
          break;
        case 'locality':
          updatedAddress.city = component.long_name;
          break;
        case 'postal_code':
          updatedAddress.zip_code = component.long_name;
          break;
        case 'administrative_area_level_1':
          updatedAddress.state = component.long_name;
          updatedAddress.state_code = component.short_name;
          break;
      }
    });

    setFormData((prev) => ({
      ...prev,
      ...updatedAddress,
    }));
    setCoordinate({ lat: updatedAddress.latitude, lng: updatedAddress.longitude });
    setShowMap(false);
    setNewAddress(updatedAddress);
    setIsZipCodeVisible(!updatedAddress.zip_code);
  };

  const handleFormSwitchChange = () => {
    setProductFormData((prevData) => ({
      ...prevData,
      is_active: prevData.is_active ? 0 : 1,
    }));
  };

  const handleProductChange = async (e) => {
    const { name, value, files } = e.target;

    if (name === "file") {
      const fileSelected = files[0];
      if (fileSelected) {
        setFileName(fileSelected.name);
        setFile(fileSelected);
      }
      return;
    }

    setProductFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

  };

  const resetProductForm = () => {
    setProductFormData({
      id: null,
      name: "",
      description: "",
      duration: "",
      price: "",
      is_active: 1,
      hotel_id: selectedHotelId,
    });
    setFile(null);
    setFileName("");
  };

  const handleProductSubmit = async (e) => {
    e.preventDefault();

    // Build FormData for multi-part (file uploads)
    const formDataToSend = new FormData();

    for (const key in productFormData) {
      let value = productFormData[key];

      if (key === "is_active") {
        value = value ? 1 : 0;
      }
      if (value) {
        if (Array.isArray(value) || typeof value === "object") {
          formDataToSend.append(key, JSON.stringify(value));
        } else {
          formDataToSend.append(key, value);
        }
      }
    }

    if (file) {
      formDataToSend.append("imageFile", file);
    }

    try {
      if (productFormData.id) {
        if (file) {
          await ProductService.updateWithImage(productFormData.id, formDataToSend, axiosMultiPart);
        } else {
          await ProductService.update(productFormData.id, productFormData, axiosPrivate);
        }
      } else {
        await ProductService.store(formDataToSend, axiosMultiPart);
      }

      fetchHotelProducts(selectedHotelId);
      resetProductForm();
      setIsProductListVisible(true);

    } catch (error) {

    }
  };

  const fetchHotelProducts = async (hotelId) => {
    try {
      const response = await ProductService.index({ hotel_id: hotelId }, axiosPrivate);
      setHotelProducts(response);
    } catch (error) {

    }
  };

  const handleManageProduct = (hotel) => {
    setSelectedHotelId(hotel.id);
    fetchHotelProducts(hotel.id);
    setIsProductEditModalOpen(true);
    setIsProductListVisible(true);
  };

  const handleAddNewProduct = () => {
    setProductFormData({
      id: null,
      name: "",
      description: "",
      duration: "",
      price: "",
      is_active: 1,
      hotel_id: selectedHotelId,
    });
    setIsProductListVisible(false);
  };

  const handleProductEdit = (product) => {
    setProductFormData({
      id: product.id,
      name: product.name,
      description: product.description,
      duration: product.duration,
      price: product.price,
      is_active: product.is_active ? 1 : 0,
      hotel_id: selectedHotelId,
    });
    setIsProductListVisible(false);
  };

  const handleProductStatusToggle = async (product) => {
    try {
      await ProductService.update(product.id, { is_active: !product.is_active }, axiosPrivate);

      fetchHotelProducts(selectedHotelId);
    } catch (error) {

    }
  };

  const handleShowMap = async () => {
    await fetchCoordinates();
    setShowMap(true);
  }

  const handleClick = (event, index) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };


  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };


  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <form onSubmit={handleFilterSubmit} className="search">
            <div className="text-search">
              <input
                type="text"
                name="search"
                value={filterData.search}
                onChange={handleFilterChange}
                placeholder="Search by Hotel Name..."
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <select
              name="search_by"
              value={filterData.search_by}
              onChange={handleFilterChange}
            >
              <option value="name">Hotel Name</option>
              <option value="city">City</option>
              <option value="contact_first_name">Contact First Name</option>
              <option value="contact_last_name">Contact Last Name</option>
            </select>
            <select
              name="operator"
              value={filterData.operator}
              onChange={handleFilterChange}
            >
              <option value="contains">Contains</option>
              <option value="starts">Starts With</option>
              <option value="ends">Ends With</option>
              <option value="eq">Equal</option>
              <option value="!eq">Not Equal</option>
            </select>
          </form>

          <a onClick={openModal} className="btn-add-client">
            Add
          </a>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead className="no-background">
              <tr>
                <th className="sort-header active" id="name" onClick={handleSelectedSort}>Hotel Name</th>
                <th>Hotel Image</th>
                <th className="sort-header" id="address" onClick={handleSelectedSort}>Hotel Address</th>
                <th className="sort-header" id="contact_first_name" onClick={handleSelectedSort}>Contact Name</th>
                <th className="sort-header" id="phone" onClick={handleSelectedSort}>Phone Number</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {hotels?.length > 0 ? (
                hotels.map((hotel, index) => (
                  <tr key={hotel.id}>
                    <td>{hotel.name}</td>
                    <td>
                      <img
                        src={`${hotel.media[0]?.original_url}`}
                        alt="Client image"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>
                      {addressFormat(hotel.address)}
                    </td>
                    <td>{hotel.contact_first_name + " " + hotel.contact_last_name}</td>
                    <td>{hotel.phone}</td>
                    <td>
                      <a href="#" onClick={() => openModalManage(hotel)} className="see-more edit">
                        See more
                      </a>
                    </td>
                    <td>
                      <IconButton onClick={(event) => handleClick(event, index)}>
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedIndex === index}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                      >
                        <MenuList style={{ minWidth: 150 }}>
                          {/* Edit Hotel */}
                          <MenuItem onClick={() => { handleClose(); openModal(hotel); }}>
                            <ListItemIcon>
                              <EditIcon style={{ color: "#1976d2" }} />
                            </ListItemIcon>
                            <ListItemText>Edit Hotel</ListItemText>
                          </MenuItem>

                          {/* Manage Addresses */}
                          <MenuItem onClick={() => { handleClose(); openManageModal("addresses", hotel); }}>
                            <ListItemIcon>
                              <LocationOnIcon style={{ color: "#1976d2" }} />
                            </ListItemIcon>
                            <ListItemText>Manage Addresses</ListItemText>
                          </MenuItem>

                          {/* Manage Products */}
                          <MenuItem onClick={() => { handleClose(); handleManageProduct(hotel); }}>
                            <ListItemIcon>
                              <Inventory2Icon style={{ color: "#1976d2" }} />
                            </ListItemIcon>
                            <ListItemText>Manage Product</ListItemText>
                          </MenuItem>

                          {/* Delete */}
                          <MenuItem style={{ color: 'red' }} onClick={() => { handleClose(); handleDelete(hotel.id); }}>
                            <ListItemIcon>
                              <DeleteIcon style={{ color: "red" }} />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Menu>

                    </td>

                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No hotels found.</td>
                </tr>
              )}
            </tbody>
            <tfoot >
              <tr>
                <td colSpan={8}>
                  <div className="paginator">
                    <select value={perPage} onChange={handlePerPageChange} className="per-page">
                      <option value={1}>1</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={250}>250</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                    </select>
                    <div className="total">Total Records: {total}</div>
                    <div className="pagination">
                      <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                      <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                      <span>{currentPage} / {lastPage}</span>
                      <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                      <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add a Hotel"
      >
        <button className="close-button" onClick={closeModal}>
          <span className="close-icon">✖</span>
        </button>

        <h2>Add a Hotel</h2>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-text">
              <label>Hotel Name*</label>
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="flex-row gap-10">
              <div className="form-text">
                <label htmlFor="street">Select address</label>
                <Autocomplete
                  apiKey="AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk"
                  onPlaceSelected={handlePlaceSelect}
                  options={{
                    types: ["address"],
                    componentRestrictions: { country: "CH" },
                  }}
                  style={{
                    width: "100%",
                    padding: "0 15px",
                    height: "60px",
                    border: "0",
                    borderRadius: "20px",
                    boxShadow: "0px 8px 50px 0px rgba(0, 0, 0, 0.06)",
                  }}
                  defaultValue={autoCompleteValue}
                />
              </div>

              {isZipCodeVisible && (
                <div className="form-group">
                  <label>ZIP Code</label>
                  <input
                    type="text"
                    value={formData.zip_code}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        zip_code: e.target.value,
                      }))
                    }
                    required
                  />
                </div>
              )}

              <div className="form-group icon-top-margin">
                {formData.zip_code && (
                  <img
                    src="assets/images/map-icon.svg"
                    alt="Map Icon"
                    className="clickable"
                    onClick={handleShowMap}
                  />
                )}
              </div>
            </div>

            <div>
              {formData.latitude && showMap && (<Map
                coordinates={[coordinates]}
                mode="edit"
                containerStyle={{ width: '100%', height: '400px' }}
                mapOptions={{
                  disableDefaultUI: true,
                  zoomControl: true,
                  mapTypeControl: false,
                  fullscreenControl: true,
                  streetViewControl: false,
                }}
                onCoordinateChange={handleCoordinateChange}
              />)}
            </div>

            <div className="form-text">
              <label>Contact First Name*</label>
              <input type="text" name="contact_first_name" value={formData.contact_first_name} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Contact Last Name*</label>
              <input type="text" name="contact_last_name" value={formData.contact_last_name} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Hotel Phone Number*</label>
              <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Hotel Email</label>
              <input type="text" name="email" value={formData.email} onChange={handleChange} />
            </div>
            <div className="form-text topMargArea">
              <label>Flypro Instructions</label>
              <textarea
                className="formTextArea"
                value={formData.flypro_instructions}
                name="flypro_instructions"
                placeholder="Enter Flypro Instructions..."
                onChange={handleChange}
              />
            </div>
            <div className="capability-input topMargArea">
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Typography>DUO Capability</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    checked={formData.duo_capability}
                    onChange={handleSwitchChange}
                    inputProps={{ 'aria-label': 'DUO Capability' }}
                  />
                  <Typography>Yes</Typography>
                </Stack>
              </Stack>
            </div>
            <div className="capability-input topMargArea">
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Typography>Only internal Client</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    checked={formData.is_only_internal}
                    onChange={handleSwitchInternalChange}
                    inputProps={{ 'aria-label': 'Only internal client' }}
                  />
                  <Typography>Yes</Typography>
                </Stack>
              </Stack>
            </div>
            <label className="fileLabel" htmlFor="file">
              Image to upload*
            </label>
            <div className="fileField">
              <label htmlFor="file">
                <img className="imgFile" src={imageFile || ""} alt="File upload" />
                {fileName ? (
                  <div>Uploaded File: {fileName}</div>
                ) : (
                  <>
                    <div className="selectFileTo">Select file to upload</div>
                    <div className="orDragAnd">
                      or drag and drop, copy-paste files
                    </div>
                  </>
                )}
              </label>
              <input
                type="file"
                id="file"
                name="file"
                accept="image/*"
                onChange={handleChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>
            <button type="submit" className="btn-submit">{formData.id ? "Update a Hotel" : "Create a Hotel"}</button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenManage && selectedHotel !== null}
        onRequestClose={closeModalManage}
        style={customStyles}
        contentLabel="Manage Hotel"
      >
        <div className="form-inscription mrgBottom">
          <h2>{selectedHotel?.name}</h2>
          <form>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Address</label>
              <input type="text" name="address" value={addressFormat(selectedHotel?.address)} readOnly />
            </div>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Contact Name:</label>
              <input type="text" name="contact_first_name" value={`${selectedHotel?.contact_first_name || ''} ${selectedHotel?.contact_last_name || ''}`.trim()} readOnly />
            </div>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Phone Number*</label>
              <input type="text" name="phone" value={selectedHotel?.phone || ''} readOnly />
            </div>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Email</label>
              <input type="text" name="email" value={selectedHotel?.email || ''} readOnly />
            </div>
            <div className="account-hotel-management">
              <div className="input-hotel-container">
                <label htmlFor="accountManagement" className="input-hotel-label">Account Management</label>
                <input
                  type="text"
                  id="accountManagement"
                  name="accountManagement"
                  readOnly
                  value=" "
                />
                {selectedHotel?.account ? (
                  <span className="hotel-account">{selectedHotel.account.extra.full_name}</span>
                ) : (
                  <a href="#" onClick={(e) => handleCreateAccountClick(e, selectedHotel.id)} className="create-hotel-account">
                    Create Account
                  </a>
                )}
              </div>
            </div>
            <div className="capability-input topMargArea">
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{ marginRight: '220px' }}>
                <Typography>DUO Capability</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    checked={selectedHotel?.duo_capability}
                    onChange={handleSwitchChange}
                    inputProps={{ 'aria-label': 'DUO Capability' }}
                  />
                  <Typography>Yes</Typography>
                </Stack>
              </Stack>
            </div>
            <div className="capability-input topMargArea">
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{ marginRight: '220px' }}>
                <Typography>Only internal client</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    checked={selectedHotel?.is_only_internal}
                    onChange={handleSwitchInternalChange}
                    inputProps={{ 'aria-label': 'Only internal client' }}
                  />
                  <Typography>Yes</Typography>
                </Stack>
              </Stack>
            </div>
            <div className="form-text booking-zone topMargArea">
              <span className="list-all-bookings">Hotel Bookings:</span>
              <span className="list-excel-all-bookings">EXTRACT Booking List in EXCEL</span>
              <table className="table-bookings">
                <thead>
                  <tr>
                    <th>Booking ID</th>
                    <th>Client</th>
                    <th>Date</th>
                    <th>Treatment Type</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allBookings?.length > 0 ? (
                    allBookings?.map((booking) => (
                      <tr key={booking.id}>
                        <td>{booking.id}</td>
                        <td>{booking.client?.extra?.full_name}</td>
                        <td>{booking.date ? new Date(booking.date).toLocaleDateString('en-CA').split('T')[0] : 'N/A'}</td>
                        <td>{booking.product.name}</td>
                        <td>{booking.status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No bookings found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="extract-button" onClick={() => handleExportClientBookings(selectedHotel.id)}>
                Extract Booking
              </div>
            </div>
            <div className="form-text booking-zone extractMrg">
              <span className="list-all-bookings">Hotel Direct Bookings:</span>
              <span className="list-excel-all-bookings">EXTRACT Booking List in EXCEL</span>
              <table className="table-bookings">
                <thead>
                  <tr>
                    <th>Booking ID</th>
                    <th>Client</th>
                    <th>Date</th>
                    <th>Treatment Type</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {directBookings?.length > 0 ? (
                    directBookings?.map((booking) => (
                      <tr key={booking.id}>
                        <td>{booking.id}</td>
                        <td>{booking.client?.extra?.full_name}</td>
                        <td>{booking.date ? new Date(booking.date).toLocaleDateString('en-CA').split('T')[0] : 'N/A'}</td>
                        <td>{booking.product.name}</td>
                        <td>{booking.status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No bookings found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="extract-button" onClick={() => handleExportDirectHotelBookings(selectedHotel.id)}>
                Extract Booking
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={handleModalClose}
        style={customStyles}
        contentLabel="Create Hotel Account"
      >
        <button onClick={handleModalClose}>Close</button>
        <h2>Create Hotel Account</h2>
        <form onSubmit={(e) => handleAccountFormSubmit(e, selectedHotel.id)}>
          <div className="form-inscription mrgBottom">
            <div className="form-text">
              <label>Email*</label>
              <input
                type="email"
                name="email"
                value={accountFormData.email}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <div className="form-text">
              <label>First Name*</label>
              <input
                type="text"
                name="firstname"
                value={accountFormData.firstname}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <div className="form-text">
              <label>Last Name*</label>
              <input
                type="text"
                name="lastname"
                value={accountFormData.lastname}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <div className="form-text">
              <label>Phone</label>
              <input
                type="text"
                name="phone"
                value={accountFormData.phone}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <button type="submit" className="btn-submit">
              Create Account
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={modalIsOpenAddressManage}
        onAfterOpen={afterOpenManageModal}
        onRequestClose={closeModalAddressManage}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Manage Modal"
      >
        <div className="bloc-manage">
          {modalType === "addresses" && (
            <div className="manage-managers-modal">
              <h2>Manage Addresses for {selectedRow?.name}</h2>
              {/* Assigned Addresses Section */}
              <div className="currently-managed">
                <div className="assigned-addresses">
                  <h3>Assigned Hotel Addresses</h3>
                  <div className="tableau-list no-scroll no-padding-bottom">
                    <div className="container">
                      <table>
                        {selectedRow?.addresses
                          .filter((address) => {
                            return address.hotel_id === selectedRow.id;
                          })
                          .map((address) => (
                            <thead key={address.id} className="no-background">
                              <tr>
                                <th>{address.street_number} {address.street}, {address.zip_code} {address.city}</th>
                                <th>
                                  <button
                                    className="form-submit"
                                    onClick={() => handleRemoveAddress(address)}
                                  >
                                    Remove
                                  </button>
                                </th>
                              </tr>
                            </thead>
                          ))
                        }
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add Addresses Section */}
              <div className="form-text topMargArea">
                <h3>Add New Address</h3>
                <form onSubmit={handleAddAddress}>
                  <div className="form-group">
                    <label htmlFor="street">Saisissez une adresse</label>
                    <Autocomplete
                      apiKey="AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk"
                      onPlaceSelected={handlePlaceSelect}
                      options={{
                        types: ['address'],
                        componentRestrictions: { country: 'CH' },
                      }}
                      style={{ width: '100%', padding: '0 15px', height: '60px', border: '0', borderRadius: '20px', boxShadow: '0px 8px 50px 0px rgba(0, 0, 0, 0.06)' }}
                    />
                  </div>

                  {isZipCodeVisible && (
                    <div className="form-group">
                      <label>ZIP Code</label>
                      <input
                        type="text"
                        value={newAddress.zip_code}
                        onChange={(e) => setNewAddress(prev => ({ ...prev, zip_code: e.target.value }))}
                        required
                      />
                    </div>
                  )}

                  <button type="submit" className="btn-submit">
                    Add Address
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={isProductEditModalOpen}
        onRequestClose={() => setIsProductEditModalOpen(false)}
        style={customStyles}
        contentLabel="Manage Product"
      >
        <button className="close-button" onClick={() => setIsProductEditModalOpen(false)}>
          <span className="close-icon">✖</span>
        </button>

        <h2>Manage Products</h2>

        {isProductListVisible ? (
          <>
            <div className="tableau-list no-scroll">
              <div className="container">
                <table>
                  <thead className="no-background">
                    <tr>
                      <th>Product Name</th>
                      <th>Duration (Min)</th>
                      <th>Price (CHF)</th>
                      <th>Status</th>
                      <th>Enable/Disable</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotelProducts?.map((product) => (
                      <tr key={product.id} className={
                        !product?.is_active
                          ? "disabled-item"
                          : ""
                      }>
                        <td>{product.name}</td>
                        <td>{product.duration}</td>
                        <td>{product.price}</td>
                        <td className={product.is_active ? "active" : "disabled"}>
                          {product.is_active ? "Active" : "Disabled"}
                        </td>
                        <td>
                          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                            <AntSwitch
                              checked={!!product.is_active}
                              onChange={() => handleProductStatusToggle(product)}
                              inputProps={{ "aria-label": "toggle product status" }}
                            />
                          </Stack>
                        </td>
                        <td>
                          <button className="form-submit" onClick={() => handleProductEdit(product)}>Edit</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="table-header">
              <h2></h2>
              <button onClick={handleAddNewProduct} className="btn-add-product">
                Add Product
              </button>
            </div>
          </>
        ) : (
          <div className="form-inscription">
            <form onSubmit={handleProductSubmit}>
              <div className="form-text">
                <label>Product Name*</label>
                <input
                  required
                  type="text"
                  name="name"
                  value={productFormData.name}
                  onChange={handleProductChange}
                />
              </div>

              <div className="form-text">
                <label>Description*</label>
                <textarea
                  required
                  className="formTextArea"
                  name="description"
                  value={productFormData.description}
                  onChange={handleProductChange}
                />
              </div>

              <div className="form-text">
                <label>Duration *</label>
                <input
                  required
                  type="number"
                  name="duration"
                  value={productFormData.duration}
                  onChange={handleProductChange}
                  min="0"
                  max="240"
                />
              </div>

              <div className="form-text">
                <label>Price*</label>
                <input
                  required
                  type="number"
                  name="price"
                  value={productFormData.price}
                  onChange={handleProductChange}
                  min="0"
                  max="9999"
                />
              </div>

              <label className="fileLabel" htmlFor="file">
                Image to upload*
              </label>
              <div className="fileField">
                <label htmlFor="file">
                  <img className="imgFile" src={imageFile} alt="File upload" />
                  {fileName ? (
                    <div>Uploaded File: {fileName}</div>
                  ) : (
                    <>
                      <div className="selectFileTo">Select file to upload</div>
                      <div className="orDragAnd">
                        or drag and drop, copy-paste files
                      </div>
                    </>
                  )}
                </label>
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept="image/*"
                  onChange={handleProductChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
              </div>

              <div className="capability-input">
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                  <Typography>Status</Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Disabled</Typography>
                    <Switch
                      checked={!!productFormData.is_active}
                      onChange={handleFormSwitchChange}
                      inputProps={{ "aria-label": "status" }}
                    />
                    <Typography>Active</Typography>
                  </Stack>
                </Stack>
              </div>

              <div className="actions">
                <button type="button" onClick={() => setIsProductListVisible(true)} className="btn-submit">
                  Cancel
                </button>
                <button type="submit" className="btn-submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Hotels;
