import React, { Fragment, useState } from 'react';
import MenuHeader from './MenuHeader';
import Breadcrumb from './Breadcumb/Breadcrumb';
import HotelBreadcrumb from './HotelManager/Breadcrumb';
import { Map } from './google_map';
import ListItem from './List_item/ListItem';
import ListTable from './TaskList/ListTable';
import useAxiosPrivate2 from '../hooks/useAxiosPrivate2';
import useAuth from "../hooks/useAuth";

const Dashboard = () => {
  const [selectedView, setSelectedView] = useState('map');
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate2();

  // Function to update selected view
  const handleViewChange = (view) => {
    setSelectedView(view);
  };

  const isHotelManager = auth?.roles?.some(role => role.name === 'ROLE_HOTEL_MANAGER');

  return (
    <Fragment>
      <MenuHeader />

      {isHotelManager ? (
        <HotelBreadcrumb onViewChange={handleViewChange} selected={selectedView} axiosPrivate={axiosPrivate} />
      ) : (
        <Breadcrumb onViewChange={handleViewChange} selected={selectedView} axiosPrivate={axiosPrivate} />
      )}

      {selectedView === 'map' && (
        <>
          <Map />
          <ListItem />
        </>
      )}
      {selectedView === 'list' && <ListTable showHeader={false} />}
    </Fragment>
  );
};

export default Dashboard;
