import React, { useEffect, useState } from "react";
import MenuHeader from "./MenuHeader";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { formatDistanceToNow } from 'date-fns';
import useAuth from "../hooks/useAuth";
import NotificationService from "../services/notification.service";
import { useNavigate } from "react-router-dom";



const NotificationAdmin = () => {


  const axiosPrivate = useAxiosPrivate();
  const limit = 100; // Nombre de clients par page
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const [totalPages, setTotalPages] = useState(1);   // Total des pages
  const [hasMore, setHasMore] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const { auth } = useAuth()
  const navigation = useNavigate();
  const goBack = () => navigation(-1);

  const loadMoreOnClick = () => {

    setCurrentPage((prev) => prev + 1);
  };


  const handleDelete = async (id) => {

    try {

      await NotificationService.delete(id, axiosPrivate)
      setCurrentPage(1)
      fetchNotifications(true);

    } catch (error) {
      console.error("Erreur lors du chargement des notification:", error);
    }

    console.log(`Delete ${id}`);


  };

  const openUrl = async (url) => {

    navigation(url);


  };


  const TimeAgo = ({ date }) => {
    return (
      <span>
        {formatDistanceToNow(new Date(date), { addSuffix: true })}
      </span>
    );
  };

  const fetchNotifications = async (refresh = null) => {
    try {
      const response = await NotificationService.index({
        with: `user_id`,
        'user_id': `${auth?.user?.id}`, 'page': `${refresh ? 1 : currentPage}`, 'per_page': 5, 'sort': '-id'
      }, axiosPrivate)
      setHasMore(response?.last_page > response?.current_page)
      refresh ?
        setNotifications(response.data)
        :
        setNotifications((prev) => {
          const newNotifications = response.data.filter(
            (newNotif) => !prev.some((existingNotif) => existingNotif.id === newNotif.id)
          );

          return [...prev, ...newNotifications];
        });
      console.log(response, response?.last_page, response?.currentPage)

    } catch (error) {
      console.error("Erreur lors du chargement des notification:", error);

    }
  };

  useEffect(() => {


    fetchNotifications();
  }, [currentPage]);


  return (
    <>
      <MenuHeader />
      <div className="notification-breadcumb">
        <div className="turn-back">
          <div className="frame">
            <div className="div" onClick={goBack} >{"<"} Retour</div>
          </div>
        </div>
        <div className="container">

        </div>
      </div>
      <div className="container-notification container">
        {notifications?.map((notification, index) => (
          <div className="notification" key={index} onClick={() => openUrl(notification.url)} >
            <div onClick={() => handleDelete(notification.id)} className="notification-close-btn">×</div>
            {notification?.user?.image &&
              <div className="profile-picture">
                <img src={notification?.user?.image} alt="FlySpa" className="avatar-img" />
              </div>
            }
            <div className="notification-card-column">
              <div className="notification-card-header">
                <div className="notification-card-title">
                  <div className="notification-card-title-text">{notification.titre}</div>
                </div>
                <div className="notification-card-time">
                  <div className="notification-card-title-time-text"><TimeAgo date={notification.date} /></div>
                </div>
              </div>
              <div className="notification-card-content-wrapper">
                <div className="notification-card-content">
                  <div className="notification-card-content-text">{notification.message}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {hasMore && (
          <div className="btn-bottom">
            <button className="loading-more-btn btn btn-primary" onClick={loadMoreOnClick}>
              Load more
            </button>
          </div>
        )}

      </div>
    </>
  );
};

export default NotificationAdmin;