import React, { useState, useRef, useEffect } from 'react';
import { Box, Chip, TextField, IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy'; // Import IconButton and FileCopyIcon
import { useDraggable, useDroppable } from '@dnd-kit/core';

const STATUS_MAPPINGS = {
    'B': 'Bank Holiday',
    'S': 'Sick Leave',
    'H': 'Holiday',
    'O': 'Day Off'
};

const CustomTimeInputField = ({ id, onChange, canCopyLeft = false, canCopyRight = false, canCopyUp = false, onCopy, data }) => {
    const [inputs, setInputs] = useState(['', '', '', '']);
    const [chip, setChip] = useState(null);
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];
    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
        id: `input-${id}`,
        data: { value: data.therapist?.value }
    });

    const { setNodeRef: droppableRef, isOver } = useDroppable({
        id: `drop-${id}`,
    });

    const handleDrop = () => {
        // if (draggedValue) {
        //     // Copy the dragged value into this cell (overwriting the existing one)
        //     // Here, you would set the value to the current cell
        //     // For example: data.therapist.value = draggedValue;
        //     onChange(data.therapist, data.rowIndex); // Call the onChange to propagate the change
        // }
    };

    useEffect(() => {
        if (data && data.rowIndex && data.therapist) {
            const therapistCalendar = data.therapist?.value;
            if (therapistCalendar && therapistCalendar.length > 1 && therapistCalendar !== 'N/A') {
                for (const [key, value] of Object.entries(STATUS_MAPPINGS)) {
                    if (value.toUpperCase() === therapistCalendar.toUpperCase()) {
                        handleInputChange(0, key);
                        return;
                    }
                }
                if (therapistCalendar.includes(' - ')) {
                    let times = therapistCalendar.split(' - ');
                    times = [times[0].split(':'), times[1].split(':')];
                    const timeString = `${times[0][0]}:${times[0][1]} - ${times[1][0]}:${times[1][1]}`;
                    setChip(timeString);
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if (chip === null) {
            inputRefs[0].current.focus();
        }
    }, [chip]);

    const validateTime = (hour, minute, isStart, start = null, startM = null) => {
        const h = parseInt(hour);
        const m = parseInt(minute);

        if (isStart) {
            return h >= 8 && h <= 21 && m >= 0 && m <= 59 && (h !== 21 || m <= 45);
        } else {
            const startHour = parseInt(start ?? inputs[0]);
            const startMin = parseInt(startM ?? inputs[1]);
            return h >= 8 && h <= 22 && m >= 0 && m <= 59 &&
                (h > startHour || (h === startHour && m >= startMin + 15)) &&
                (h !== 22 || m === 0);
        }
    };

    const formatTimeValue = (value) => {
        return value.padStart(2, '0');
    };

    const handlePaste = (index, event) => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text').trim();

        // Check for status mappings
        const upperPastedText = pastedText.toUpperCase();
        if (upperPastedText.length > 0) {
            for (const [key, value] of Object.entries(STATUS_MAPPINGS)) {
                if (upperPastedText === key || upperPastedText[0] === key) {
                    setChip(STATUS_MAPPINGS[key]);
                    data.therapist.value = { value: STATUS_MAPPINGS[key], availability: { start: '08:00', end: '18:00' } };
                    onChange(data.therapist, data.rowIndex);
                    return;
                }
            }
        }

        // Match time patterns
        const fullTimePattern = /(\d{1,2}):(\d{2})\s*-\s*(\d{1,2}):(\d{2})/;
        const singleTimePattern = /(\d{1,2}):(\d{1,2})/;
        const singleNumberPattern = /^\d{1,2}$/;
        const fullTimeMatch = pastedText.match(fullTimePattern);
        if (fullTimeMatch) {
            console.log({ pastedText })
            const [_, startHour, startMin, endHour, endMin] = fullTimeMatch;
            const newInputs = [startHour, startMin, endHour, endMin].map(num =>
                num.length === 1 ? `0${num}` : num
            );

            // Validate and set the times
            const [h1, m1, h2, m2] = newInputs.map(Number);
            if (validateTime(h1, m1, true) && validateTime(h2, m2, false, h1, m1)) {
                setInputs(newInputs);
                const timeString = `${newInputs[0]}:${newInputs[1]} - ${newInputs[2]}:${newInputs[3]}`;
                setChip(timeString);
                data.therapist.value = { value: timeString, availability: { start: `${newInputs[0]}:${newInputs[1]}`, end: `${newInputs[2]}:${newInputs[3]}` } };
                onChange(data.therapist, data.rowIndex);
            }
            return;
        }

        const singleTimeMatch = pastedText.match(singleTimePattern);
        if (singleTimeMatch) {
            const [_, hour, minute] = singleTimeMatch;
            const formattedHour = hour.length === 1 ? `0${hour}` : hour;
            const formattedMinute = minute.length === 1 ? `0${minute}` : minute;

            if (index <= 1) { // Pasting in start time fields
                const newInputs = [...inputs];
                newInputs[0] = formattedHour;
                newInputs[1] = formattedMinute;
                if (validateTime(parseInt(formattedHour), parseInt(formattedMinute), true)) {
                    setInputs(newInputs);
                    inputRefs[2].current.focus();
                }
            } else { // Pasting in end time fields
                const newInputs = [...inputs];
                newInputs[2] = formattedHour;
                newInputs[3] = formattedMinute;
                if (validateTime(parseInt(formattedHour), parseInt(formattedMinute), false)) {
                    setInputs(newInputs);
                    // Check if all inputs are valid
                    if (newInputs.every(input => input.length === 2)) {
                        const timeString = `${newInputs[0]}:${newInputs[1]} - ${newInputs[2]}:${newInputs[3]}`;
                        setChip(timeString);
                        data.therapist.value = { value: timeString, availability: { start: `${newInputs[0]}:${newInputs[1]}`, end: `${newInputs[2]}:${newInputs[3]}` } };
                        onChange(data.therapist, data.rowIndex);
                    }
                }
            }
            return;
        }

        const singleNumberMatch = pastedText.match(singleNumberPattern);
        if (singleNumberMatch) {
            handleInputChange(index, pastedText);
        }
    };

    const handleInputChange = (index, value) => {
        const newInputs = [...inputs];

        if (index === 0) {
            // Handle special characters in first input
            if (value.toUpperCase() in STATUS_MAPPINGS && !/^\d/.test(inputs[0])) {
                setChip(STATUS_MAPPINGS[value.toUpperCase()]);
                data.therapist.value = { value: STATUS_MAPPINGS[value.toUpperCase()], availability: { start: '08:00', end: '18:00' } };
                onChange(data.therapist, data.rowIndex);
                return;
            }

            // Only allow numbers 08-21 for start hour
            if (!/^\d{0,2}$/.test(value)) {
                newInputs[index] = '';
                setInputs(newInputs);
                return;
            }

            // Auto-prefix with 0 if single digit > 2
            if (value.length === 1 && parseInt(value) > 2) {
                if (parseInt(`0${value}`) < 8 || parseInt(`0${value}`) > 21) {
                    newInputs[index] = '';
                    setInputs(newInputs);
                    return;
                }
                newInputs[index] = `0${value}`;
                setInputs(newInputs);
                inputRefs[index + 1].current.focus();
                return;
            }

            if (value.length === 2 && (parseInt(value) < 8 || parseInt(value) > 21)) {
                newInputs[index] = '';
                setInputs(newInputs);
                return;
            }
        }

        if (index === 1 || index === 3) {
            // Minutes validation
            if (!/^\d{0,2}$/.test(value)) {
                newInputs[index] = '';
                setInputs(newInputs);
                return;
            }

            if (value.length === 2) {
                if (parseInt(value) < 0 || parseInt(value) > 59) {
                    newInputs[index] = '';
                    setInputs(newInputs);
                    return;
                }
                if (index === 1 && inputs[0] === '21' && parseInt(value) > 45) {
                    newInputs[index] = '';
                    setInputs(newInputs);
                    return;
                }
                if (index === 3 && inputs[2] === '22' && parseInt(value) !== 0) {
                    newInputs[index] = '';
                    setInputs(newInputs);
                    return;
                }
            }
        }

        if (index === 2) {
            // End hour validation
            if (!/^\d{0,2}$/.test(value)) {
                newInputs[index] = '';
                setInputs(newInputs);
                return;
            }

            // Auto-prefix with 0 if single digit > 2
            if (value.length === 1 && parseInt(value) > 2) {
                if (parseInt(`0${value}`) < 8 || parseInt(`0${value}`) > 22) {
                    newInputs[index] = '';
                    setInputs(newInputs);
                    return;
                }
                newInputs[index] = `0${value}`;
                setInputs(newInputs);
                inputRefs[index + 1].current.focus();
                return;
            }

            if (value.length === 2) {
                const startHour = parseInt(inputs[0]);
                const startMin = parseInt(inputs[1]);
                const endHour = parseInt(value);
                if (endHour < startHour || endHour > 22) {
                    newInputs[index] = '';
                    setInputs(newInputs);
                    return;
                }
                if (endHour === startHour && startMin >= 45) {
                    newInputs[index] = '';
                    setInputs(newInputs);
                    return;
                }
                // If end hour is 22, automatically set minutes to 00
                if (endHour === 22) {
                    newInputs[3] = '00';
                }
            }
        }

        newInputs[index] = value;
        setInputs(newInputs);

        // Auto-focus next input when valid
        if (value.length === 2 && index < 3) {
            // If we just set end hour to 22, skip the last input since we auto-set it to 00
            if (index === 2 && value === '22') {
                // Check if all inputs are filled and valid
                const [startHour, startMin, endHour] = newInputs.slice(0, 3).map(Number);
                if (validateTime(startHour, startMin, true) && validateTime(endHour, 0, false)) {
                    const timeString = `${formatTimeValue(startHour.toString())}:${formatTimeValue(startMin.toString())} - ${formatTimeValue(endHour.toString())}:00`;
                    setChip(timeString);
                    data.therapist.value = { value: timeString, availability: { start: `${formatTimeValue(startHour.toString())}:${formatTimeValue(startMin.toString())}`, end: `${formatTimeValue(endHour.toString())}:00` } };
                    onChange(data.therapist, data.rowIndex);
                }
            } else {
                inputRefs[index + 1].current.focus();
            }
        }

        // Check if all inputs are filled and valid
        if (newInputs.every(input => input.length === 2)) {
            const [startHour, startMin, endHour, endMin] = newInputs.map(Number);
            if (validateTime(startHour, startMin, true) && validateTime(endHour, endMin, false)) {
                const timeString = `${formatTimeValue(startHour.toString())}:${formatTimeValue(startMin.toString())} - ${formatTimeValue(endHour.toString())}:${formatTimeValue(endMin.toString())}`;
                setChip(timeString);
                data.therapist.value = { value: timeString, availability: { start: `${formatTimeValue(startHour.toString())}:${formatTimeValue(startMin.toString())}`, end: `${formatTimeValue(endHour.toString())}:${formatTimeValue(endMin.toString())}` } };
                onChange(data.therapist, data.rowIndex);
            }
        }
    };

    const handleDelete = () => {
        setChip(null);
        setInputs(['', '', '', '']);
        data.therapist.value = null;
        onChange(data.therapist, data.rowIndex);
    };

    const copyToClipboard = () => {
        if (chip) {
            navigator.clipboard.writeText(chip)
                .then(() => {
                })
                .catch(err => {
                    console.error('Error copying to clipboard', err);
                });
        }
    };

    if (chip) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', border: isOver ? '2px dashed green' : 'none', cursor: isDragging ? 'grabbing' : 'grab' }} >
                <Chip
                    label={chip}
                    onDelete={handleDelete}
                    color="primary"
                />
                <IconButton onClick={copyToClipboard} size="small">
                    <FileCopyIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} {...listeners}
            {...attributes} >
            <TextField
                inputRef={inputRefs[0]}
                value={inputs[0]}
                onChange={(e) => handleInputChange(0, e.target.value)}
                onPaste={(e) => handlePaste(0, e)}
                size="small"
                sx={{ width: '50px' }}
                inputProps={{ maxLength: 2 }}
            />
            :
            <TextField
                inputRef={inputRefs[1]}
                value={inputs[1]}
                onChange={(e) => handleInputChange(1, e.target.value)}
                onPaste={(e) => handlePaste(1, e)}
                size="small"
                sx={{ width: '50px' }}
                inputProps={{ maxLength: 2 }}
            />
            -
            <TextField
                inputRef={inputRefs[2]}
                value={inputs[2]}
                onChange={(e) => handleInputChange(2, e.target.value)}
                onPaste={(e) => handlePaste(2, e)}
                size="small"
                sx={{ width: '50px' }}
                inputProps={{ maxLength: 2 }}
            />
            :
            <TextField
                inputRef={inputRefs[3]}
                value={inputs[3]}
                onChange={(e) => handleInputChange(3, e.target.value)}
                onPaste={(e) => handlePaste(3, e)}
                size="small"
                sx={{ width: '50px' }}
                inputProps={{ maxLength: 2 }}
            />
        </Box>
    );
};

export default CustomTimeInputField;