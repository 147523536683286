import ApiService from "./api.service";
export default class BookingService extends ApiService {
    static base_url = `${super.base_url}/bookings`;

    static async exportHotelClientBookings(hotel, params, axiosInstance) {
        const queryString = this.buildParams(params);
        const response = await axiosInstance.get(`${this.base_url}/hotels/${hotel}/export?${queryString}`);
        return response.data;
    }
}
