import React, { useState, useEffect, useRef } from "react";
import MenuHeader from "./MenuHeader";
import Modal from 'react-modal';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { castDate, asTitle } from '../shared/utils/string';
import GiftConfigurationService from "../services/gift_configuration.service";
import UserService from "../services/user.service";
import ZoneService from "../services/zone.service";
import Select from 'react-select';
import ProductService from "../services/product.service";
import { Menu, MenuList, MenuItem, IconButton, ListItemText, ListItemIcon } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import imageFile from "../assets/images/upload-file.svg";
import useAxiosMultiPart from "../hooks/useAxiosMultiPart";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    scrollbarWidth: 'none',
    height: '680px',
  },
};

const customSelectStyles = {
  control: (styles) => ({
    ...styles,
    width: '100%',
    height: '60px',
    padding: '0 15px',
    fontSize: '14px',
    lineHeight: '60px',
    color: '#393D44',
    border: '0',
    borderRadius: '20px',
    background: '#ffffff',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
};

const Gift = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [gifts, setGifts] = useState([]);
  const [selectedGift, setSelectedGift] = useState(null); // For editing
  const axiosPrivate = useAxiosPrivate();
  const axiosMultiPart = useAxiosMultiPart();
  const [alertMessage, setAlertMessage] = useState('');
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const [giftFormData, setGiftFormData] = useState({
    id: '',
    name: '',
    description: '',
    configuration: [],
    is_active: 1,
    valid_until: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [filters, setFilters] = useState({
    with: 'media',
    page: currentPage,
    per_page: perPage
  });

  const [sortDirection, setSortDirection] = useState(true);
  const [filterData, setFilterData] = useState({
    search: "",
  });

  const timeoutRef = useRef(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [productList, setProductList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});

  const [showNewConfigFields, setShowNewConfigFields] = useState(false);

  const handleNewConfigClick = () => {
    setShowNewConfigFields(true);
  };
  const handleAddConfiguration = () => {
    const { type, price, product_id, value, original_price, usage } = giftFormData;

    let productName = '';
    if (product_id) {

      const selectedProduct = productList.find((product) => product.id === product_id);
      productName = selectedProduct ? selectedProduct.name : 'Unknown Product';
    }
    let title = '';
    if (type === 'treatment') {
      title = `Traitement gratuit pour ${productName}`;
    } else if (type === 'voucher_percentage') {
      title = `Reduction -${value}% ${productName ? `sur ${productName}` : ''}`;
    } else if (type === 'voucher_fixe') {
      title = `Reduction -${value}CHF ${productName ? `sur ${productName}` : ''}`;
    } else if (type === 'voucher_credit') {
      title = `Crédit de ${value} à consommer ${productName ? `pour ${productName}` : ''}`;
    } else if (type === 'package') {
      title = `(Reduction package ${productName ? `pour ${productName}` : ''})${usage ? `x${usage}` : ''} `;
    }

    const newConfig = {
      title: title,
      price: price,
      type: type,
      product_id: product_id,
      original_price: original_price,
      value: value,
      usage: usage,
    };

    console.log("newConfig", newConfig)

    setGiftFormData((prev) => ({
      ...prev,
      configuration: [...prev.configuration, newConfig],
      product_id: null,
      original_price: '',
      price: '',
      value: '',
      usage: '',
    }));
    setShowNewConfigFields(false);
  };

  const toggleRow = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    fetchGifts();
  }, [currentPage, perPage]);

  useEffect(() => {
    const preventScroll = (e) => {
      if (e.target.type === 'number') {
        e.preventDefault();
      }
    };

    window.addEventListener('wheel', preventScroll, { passive: false });
    return () => {
      window.removeEventListener('wheel', preventScroll);
    };
  }, []);
  const fetchGifts = async (updatedFilters = filters) => {
    try {
      const response = await GiftConfigurationService.index(updatedFilters, axiosPrivate);
      // console.log(response.data)
      const sortedGifts = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setGifts(sortedGifts);
      setTotal(response.total);
      setLastPage(response.last_page);
      if (response.last_page < currentPage) {
        setFilters({ ...updatedFilters, page: response.last_page })
        setCurrentPage(response.last_page);
      }
    } catch (error) {
      console.error("Error fetching gifts", error);
    }
  };

  const fetchGiftsSort = async (updatedFilters = filters) => {
    try {
      const response = await GiftConfigurationService.index(updatedFilters, axiosPrivate);
      setGifts(response.data);
      setTotal(response.total);
      setLastPage(response.last_page);
      if (response.last_page < currentPage) {
        setFilters({ ...updatedFilters, page: response.last_page })
        setCurrentPage(response.last_page);
      }
    } catch (error) {
      console.error("Error fetching gifts", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, usage } = e.target;

    if (e.target.name === "file") {
      console.log({ e });
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
        setFile(file);
      }
    }

    setGiftFormData((prev) => {
      const updatedGiftFormData = {
        ...prev,
        [name]: value,
      };

      if (name === "value" && (giftFormData.type === "voucher_percentage" || giftFormData.type === "voucher_fixe")) {
        const originalPrice = giftFormData.original_price || 0;

        let calculatedPrice = originalPrice;

        if (giftFormData.type === "voucher_percentage") {
          calculatedPrice -= (originalPrice * value) / 100;
        } else if (giftFormData.type === "voucher_fixe") {
          calculatedPrice -= value;
        }

        if (calculatedPrice < 0) {
          calculatedPrice = 0;
        }

        updatedGiftFormData.price = calculatedPrice;
      }
      // if (name === "usage" && giftFormData.type === "package") {
      //   const originalPrice = giftFormData.original_price || 0;

      //   let original = originalPrice * usage;
      //   updatedGiftFormData.originalPrice = original;
      // }

      if (!updatedGiftFormData.product_id && updatedGiftFormData.type !== "package") {
        updatedGiftFormData.usage = 1;
        updatedGiftFormData.original_price = 0;
        updatedGiftFormData.price = 0;
      }
      return updatedGiftFormData;
    });

  };

  const handlePriceChange = (e) => {
    const { value } = e.target;
    setGiftFormData((prev) => ({
      ...prev,
      price: value,
    }));
  };


  const openModal = async (gift = null) => {
    if (gift) {
      setSelectedGift(gift);
      setGiftFormData({
        ...gift,
        configuration: gift.configuration || [],
        valid_until: gift.valid_until || '',
      });
    } else {
      setSelectedGift(null);
      setGiftFormData({
        name: '',
        description: '',
        configuration: [],
        is_active: 1,
        valid_until: '',
      });
      setFile(null);
      setFileName("");
    }

    const products = await ProductService.index({ with: 'category,hotel' }, axiosPrivate);
    setProductList(products);
    setIsOpen(true);
  };


  const closeModal = () => setIsOpen(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const cleanedGiftFormData = {
      name: giftFormData.name,
      description: giftFormData.description,
      is_active: giftFormData.is_active,
      valid_until: giftFormData.valid_until,
      configuration: giftFormData.configuration, // Only send the updated configuration array
    };

    const giftFormDataToSend = new FormData();

    for (const key in giftFormData) {
      const value = giftFormData[key];
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            // Instead of stringify, append each object key-value pair directly
            for (const itemKey in item) {
              if (item.hasOwnProperty(itemKey)) {
                let itemValue = item[itemKey];

                if (itemValue === undefined) {
                  itemValue = null;
                }

                giftFormDataToSend.append(`${key}[${index}][${itemKey}]`, itemValue);
              }
            }
          });
        } else if (typeof value === 'object') {
          giftFormDataToSend.append(key, JSON.stringify(value));
        } else if (typeof value === 'boolean') {
          giftFormDataToSend.append(key, Number(value)); // Convert boolean to string
        } else {
          giftFormDataToSend.append(key, value);
        }
      }
    }

    if (file) {
      giftFormDataToSend.append("imageFile", file);
    }

    try {
      const response = await (giftFormData.id ? GiftConfigurationService.updateWithImage(giftFormData.id, file ? giftFormDataToSend : giftFormData, file ? axiosMultiPart : axiosPrivate) : GiftConfigurationService.store(file ? giftFormDataToSend : giftFormData, file ? axiosMultiPart : axiosPrivate));
      if (response.data) {
        closeModal();
        fetchGifts();
      }
    } catch (e) {
      // errors handled in interceptor
    }
  };

  const handleNextPage = () => {
    setFilters({ ...filters, page: currentPage + 1 });
    setCurrentPage(currentPage + 1);
  }

  const handlePrevPage = () => {
    setFilters({ ...filters, page: currentPage - 1 });
    setCurrentPage(currentPage - 1);
  }

  const handleLastPage = () => {
    setFilters({ ...filters, page: lastPage });
    setCurrentPage(lastPage);
  }

  const handleFirstPage = () => {
    setFilters({ ...filters, page: 1 });
    setCurrentPage(1);
  }

  const handlePerPageChange = (e) => {
    setFilters({ ...filters, per_page: e.target.value, page: 1 });
    setPerPage(e.target.value);
    setCurrentPage(1);
  }

  const handleSelectedSort = async (event) => {
    const selected = document.querySelector('.sort-header.active');
    let direction = true;
    if (selected?.id !== event.target.id) {
      selected?.classList.remove('active');
      event.target.classList.add('active');
      setSortDirection(true);
    } else {
      setSortDirection(!sortDirection);
      direction = !sortDirection;
    }

    const sortField = event.target.id === 'name' ? `${direction ? '' : '-'}name` : `${direction ? '' : '-'}${event.target.id}`;
    setFilters({ ...filters, sort: sortField });
    filters.sort = sortField;

    await fetchGiftsSort();
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();

    const searchValue = filterData.search.trim().toLowerCase();
    const filteredGift = gifts.filter((gift) =>
      gift.name.toLowerCase().includes(searchValue)
    );

    setGifts(filteredGift);
  };

  const handleFilterChange = async (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });

    if (name === "search" && value === "") {
      await fetchGifts();
    }

    if (name === "type") {
      const updatedFilters = { ...filters, type: value };
      await fetchGifts(updatedFilters);
    }
  };

  const handleTypeChange = (e) => {
    const { value } = e.target;

    setGiftFormData((prev) => ({
      ...prev,
      type: value,
    }));
  };


  const productOptions = productList?.length > 0 ? productList.map(row => ({ value: row.id, label: `${row.category?.title || row.hotel?.name || ' '} - ${row.name}`, price: row.price })) : [];
  const selectedProductOption = productOptions.find(o => o.value === giftFormData.product_id) || null;

  const handleClick = (event, index) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const blockGift = async (gift) => {
    await GiftConfigurationService.update(gift.id, { is_active: false }, axiosPrivate);
    fetchGifts();
  };

  const unblockGift = async (gift) => {
    await GiftConfigurationService.update(gift.id, { is_active: true }, axiosPrivate);
    fetchGifts();
  };

  const toggleGiftStatus = async (gift) => {
    if (isExpired(gift.valid_until)) {
      setAlertMessage('Please change the date of validity <br /> to activate/deactivate the gift.');
      setTimeout(() => {
        setAlertMessage('');
      }, 3000);
      return;
    }
    if (gift.is_active) {
      await blockGift(gift);
    } else {
      await unblockGift(gift);
    }
    handleClose();
  };

  const isExpired = (date) => {
    const currentDate = new Date();
    const valid_until = new Date(date);
    return valid_until < currentDate;
  };

  const deleteGift = async (gift) => {
    await GiftConfigurationService.delete(gift.id, axiosPrivate);
    fetchGifts();
  };

  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="search margeTop20">
              <form onSubmit={handleFilterSubmit} className="text-search">
                <input
                  type="text"
                  name="search"
                  value={filterData.search}
                  onChange={handleFilterChange}
                  placeholder="Search Gift Name"
                  className="form-text"
                />
                <input type="submit" className="form-submit" value="Search" />
              </form>
            </div>
          </div>
          <button onClick={() => openModal()} className="btn-add-client">
            Add
          </button>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th className="sort-header active" id="name" onClick={handleSelectedSort}>Name</th>
                <th className="sort-header active" id="date_validity" onClick={handleSelectedSort}>Date of validity</th>
                <th className="sort-header active" id="description" onClick={handleSelectedSort}>Description</th>
                <th className="sort-header active" id="type" onClick={handleSelectedSort}>Configuration</th>
                <th className="sort-header active" id="is_active" onClick={handleSelectedSort}>Status</th>
                <th >Actions</th>
              </tr>
            </thead>
            <tbody>
              {gifts?.length > 0 ? (
                gifts.map((gift, index) => (
                  <tr key={gift.id}>
                    <td>
                      <img
                        src={`${gift.media[0]?.original_url}`}
                        alt="Gift image"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>{gift.name}</td>
                    <td>
                      {gift.valid_until.startsWith('9999') ? "Unlimited validation" : new Date(gift.valid_until).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })}
                    </td>
                    <td>{gift.description}</td>
                    <td>{gift.configuration.map((config, index) => (
                      <li key={index}>
                        {`Price: ${config.price}CHF | Title: ${config.title}`}
                      </li>
                    ))}</td>
                    <td className={gift.is_active ? "active" : "disabled"}>
                    {isExpired(gift.valid_until)
                        ? <span style={{ color: 'red' }}>Expired</span>
                        : gift.is_active
                          ? "Active"
                          : "Disabled"
                      }
                    </td>
                    <td>
                      <IconButton onClick={(event) => handleClick(event, index)}>
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedIndex === index}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                      >
                        <MenuList style={{ minWidth: 150 }}>
                          <MenuItem onClick={() => { handleClose(); openModal(gift); }}>
                            <ListItemIcon>
                              <EditIcon style={{ color: "#1976d2" }} />
                            </ListItemIcon>
                            <ListItemText>Edit Gift</ListItemText>
                          </MenuItem>
                          {gift.is_active && (
                            <MenuItem style={{ color: 'red' }} onClick={() => toggleGiftStatus(gift)}>
                              <ListItemIcon>
                                <BlockIcon style={{ color: "#FF5733" }} />
                              </ListItemIcon>
                              <ListItemText>Deactivate Gift</ListItemText>
                            </MenuItem>
                          )}
                          {!gift.is_active && (
                            <MenuItem style={{ color: 'green' }} onClick={() => toggleGiftStatus(gift)}>
                              <ListItemIcon>
                                <CheckCircleIcon style={{ color: "#28A745" }} />
                              </ListItemIcon>
                              <ListItemText>Activate Gift</ListItemText>
                            </MenuItem>
                          )}
                          {alertMessage && (
                            <MenuItem style={{ color: 'gray' }} disabled>
                              <ListItemText><span dangerouslySetInnerHTML={{ __html: alertMessage }} /></ListItemText>
                            </MenuItem>
                          )}
                          <MenuItem style={{ color: 'red' }} onClick={() => deleteGift(gift)}>
                            <ListItemIcon>
                              <BlockIcon style={{ color: "#FF5733" }} />
                            </ListItemIcon>
                            <ListItemText>Delete Gift</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </td>

                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No gifts found.</td>
                </tr>
              )}
            </tbody>
            <tfoot >
              <tr>
                <td colSpan={8}>
                  <div className="paginator">
                    <select value={perPage} onChange={handlePerPageChange} className="per-page">
                      <option value={1}>1</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={250}>250</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                    </select>
                    <div className="total">Total Records: {total}</div>
                    <div className="pagination">
                      <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                      <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                      <span>{currentPage} / {lastPage}</span>
                      <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                      <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={selectedGift ? "Edit Gift" : "Create Gift"}
      >
        <button className="close-button" onClick={closeModal}>
          <span className="close-icon">&times;</span>
        </button>

        <h2>{selectedGift ? "Edit Gift" : "Add Gift"}</h2>
        <div className="form-inscription">
          <form onSubmit={handleFormSubmit}>
            <div className="form-text">
              <label>Name*</label>
              <input
                type="text"
                name="name"
                value={giftFormData.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-text">
              <label>Description*</label>
              <input
                type="text"
                name="description"
                value={giftFormData.description}
                onChange={handleInputChange}
              />
            </div>
            <div className="champs-notif">
              <input
                type="checkbox"
                name="unlimited_validity"
                checked={giftFormData.valid_until.startsWith('9999')}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  handleInputChange({
                    target: { name: "valid_until", value: isChecked ? '9999-09-09T00:00:00.000000Z' : '' },
                  });
                }}
              />
              <label>
                Unlimited validation
              </label>
            </div>
            <br />

            {!giftFormData.valid_until.startsWith('9999') && (
              <div className="form-text">
                <label>Date of validity*</label>
                <input
                  type="date"
                  name="valid_until"
                  value={giftFormData.valid_until ? giftFormData.valid_until.split('T')[0] : ''}
                  onChange={handleInputChange}
                  required={!giftFormData.valid_until.startsWith('9999')}
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>

            )}
            <label className="fileLabel" htmlFor="file">
              Image to upload
            </label>
            <div className="fileField">
              <label htmlFor="file">
                <img className="imgFile" src={imageFile || ""} alt="File upload" />
                {fileName ? (
                  <div>Uploaded File: {fileName}</div>
                ) : (
                  <>
                    <div className="selectFileTo">Select file to upload</div>
                    <div className="orDragAnd">
                      or drag and drop, copy-paste files
                    </div>
                  </>
                )}
              </label>
              <input
                type="file"
                id="file"
                name="file"
                accept="image/*"
                onChange={handleInputChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>

            {giftFormData.configuration.length > 0 && (
              <div className="configuration-display">
                <h4>Configurations Added:</h4>
                <ul>
                  {giftFormData.configuration.map((config, index) => (
                    <li key={index}>
                      {`Price: ${config.price}CHF | Title: ${config.title}`}
                      <button
                        type="button"
                        onClick={() =>
                          setGiftFormData((prev) => ({
                            ...prev,
                            configuration: prev.configuration.filter((_, i) => i !== index),

                          }))
                        }
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {/* New Config Button */}
            <button type="button" className="btn-submit" onClick={handleNewConfigClick}>
              New Configuration
            </button>

            {/* Show New Config Fields if New Config Button Clicked */}
            {showNewConfigFields && (
              <>
                <div className="form-select">
                  <label>Type</label>
                  <select
                    name="type"
                    value={giftFormData.type}
                    onChange={handleTypeChange}
                    required
                  >
                    <option>Choose Gift Type</option>
                    <option value="voucher_percentage">Voucher Percentage / % Off</option>
                    <option value="voucher_fixe">Voucher Amount fixe</option>
                    <option value="voucher_credit">Voucher Credit</option>
                    <option value="treatment">Treatment</option>
                    <option value="package">Package</option>
                  </select>
                </div>

                <div className="form-text">
                  <label>Select Product</label>
                  <Select
                    isClearable
                    options={productOptions}
                    value={selectedProductOption}
                    onChange={(selectedOption) =>
                      setGiftFormData((prev) => ({
                        ...prev,
                        product_id: selectedOption ? selectedOption.value : "",
                        value: prev.type === "treatment" ? selectedOption.price : prev.value,
                        original_price: selectedOption ? selectedOption.price : "",
                        price: selectedOption ? selectedOption.price : "",
                        usage: prev.type !== "package" ? 1 : "",
                      }))
                    }
                    styles={customSelectStyles}
                    placeholder="Select a product"
                  />

                  {giftFormData.product_id && selectedProductOption && (
                    <>
                      <br />
                      <label>Original Price</label>
                      <input
                        type="number"
                        name="original_price"
                        value={giftFormData.original_price || selectedProductOption.price}
                        readOnly
                      />
                    </>
                  )}
                </div>

                <div className="form-text">
                  {giftFormData.type === "voucher_percentage" && (
                    <>
                      <label>Voucher Percentage</label>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="number"
                          name="value"
                          value={giftFormData.value}
                          onChange={handleInputChange}
                          required
                          min="1"
                          max="100"
                          style={{ flex: 1 }}
                        />
                        <span style={{ marginLeft: "10px" }}>%</span>
                      </div>
                    </>
                  )}

                  {(giftFormData.type === "voucher_fixe" || giftFormData.type === "voucher_credit") && (
                    <>
                      <label>Value</label>
                      <input
                        type="number"
                        name="value"
                        value={giftFormData.value}
                        onChange={handleInputChange}
                        required
                        min="0"
                      />
                    </>
                  )}

                  {giftFormData.type === "package" && (
                    <>
                      <label>Package Total Seances</label>
                      <div>
                        <input
                          type="number"
                          name="usage"
                          value={giftFormData.usage}
                          onChange={handleInputChange}
                          required
                          min="1"
                        />
                      </div>
                    </>
                  )}

                  {giftFormData.type === "voucher_credit" && (
                    <>
                      <label>Max Usage</label>
                      <div>
                        <input
                          type="number"
                          name="usage"
                          value={giftFormData.usage}
                          onChange={handleInputChange}
                          required
                          min="1"
                        />
                      </div>
                    </>
                  )}


                  {!giftFormData.product_id && giftFormData.type !== "package" && giftFormData.type !== "voucher_credit" && (
                    <>
                      <label>Usage</label>
                      <input
                        type="number"
                        name="usage"
                        value={giftFormData.usage || 1}
                        readOnly
                      />
                    </>
                  )}

                  {giftFormData.product_id && (giftFormData.type === "voucher_fixe" || giftFormData.type === "voucher_percentage" || giftFormData.type === "package" || giftFormData.type === "voucher_credit") && (
                    <>
                      <br />
                      <label>Price</label>
                      <input
                        type="number"
                        name="price"
                        value={giftFormData.price}
                        onChange={handlePriceChange}
                        required
                        min="0"
                      />
                    </>
                  )}

                  {!giftFormData.product_id && (
                    <>
                      <br />
                      <label>Price</label>
                      <input
                        type="number"
                        name="price"
                        value={giftFormData.price}
                        onChange={handlePriceChange}
                        required
                        min="0"
                      />
                    </>
                  )}
                </div>

                {/* Add Configuration as JSON Button */}
                <button
                  type="button"
                  className="btn-submit"
                  onClick={handleAddConfiguration}
                >
                  Add Configuration
                </button>
              </>
            )}

            <button type="submit" className="btn-submit">
              {selectedGift ? "Update Gift" : "Create Gift"}
            </button>
          </form>
        </div>
      </Modal>

    </>
  );
};

export default Gift;
